import { SearchModel } from '@/core/types/search.model'

export class GetAlphaIndexes {
  static readonly type =
    '[Search Alpha Index] Get alpha indexes that contain a string'
  constructor(
    public alphaIndex: string,
    public selectedLanguage: string | undefined,
  ) {}
}

export class GetAlphaIndexesViewModel {
  static readonly type = '[Search Alpha Index] Get alpha indexes view model'
  constructor(public selectedLanguage: string | undefined) {}
}

export class GetSearchThesaurus {
  static readonly type = '[Search Thesaurus] Get all thesaurus'
  constructor(public selectedLanguage: string | null) {}
}

export class GetDecision {
  static readonly type =
    '[Search Thesaurus] Get the decision linked to a thesaurus '
}

export class GetSearchConstitution {
  static readonly type =
    '[Search Thesaurus] Get the constitution likend to a thesaurus '
}

export class SearchInThesaurus {
  static readonly type =
    '[Search Thesaurus] Search for a thesaurus in the thesaurus list '
}

export class ThesaurusLanguage {
  static readonly type = '[Search Thesaurus] Change the thesaurus language '
  constructor(public selectedLanguage: string | null) {}
}

export class SearchAlphaIndex {
  static readonly type = '[Search] search a precis linked to an alpha index'
  constructor(
    public alphaIndexId: string,
    public taxonId: string,
    public page: number,
    public size: number,
  ) {}
}

export class SearchPrecisLinkedToArticle {
  static readonly type =
    '[Search] search a precis linked to an article of a constituion'
  constructor(
    public articleId: string,
    public page: number,
    public size: number,
  ) {}
}

export class AdvancedSearch {
  static readonly type =
    '[Search] search for a thesaurus from precis or use for advanced search'
  constructor(public searchModel: SearchModel) {}
}

export class SetSearch {
  static readonly type = '[Search] set search we have juste made'
  constructor(
    public searchModel: SearchModel,
    public alphaIndexId: string,
    public taxonId: string,
  ) {}
}
