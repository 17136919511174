import { DocType } from '@/core/types/doc-type.enum'
import { plainToClass } from 'class-transformer'
import { SearchModel } from '../../../../../libs/core/src/lib/types/search.model'
import { CodicesTree } from './codices-tree.model'
import { SearchBookmark } from './search-bookmark.model'
import { SearchTypes } from './search-types.enum'

export class CountryFilter {
  count: number
  code: string
  title: string
  isChecked = false
  static fromJson(obj: any): CountryFilter {
    const countryFilter = plainToClass(CountryFilter, obj)
    countryFilter.isChecked = false
    return countryFilter
  }
}

export class ThesaurusFilter {
  count: number
  indexNumber: string
  title: string
  isChecked = false
  static fromJson(obj: any): ThesaurusFilter {
    const thesaurusFilter = plainToClass(ThesaurusFilter, obj)
    thesaurusFilter.isChecked = false
    return thesaurusFilter
  }
}

export class SearchResult {
  breadcrumb: string
  id: string
  index: number
  isPrecisPublished: boolean
  level: number
  parentId: string
  rootId: string
  textExtract: string[]
  title: string
  type: DocType
}

export class Result {
  countryFilters: CountryFilter[]
  hasMoreChildren: boolean
  searchResult: SearchResult[]
  thesaurusFilters: ThesaurusFilter[]
  tree: CodicesTree
  id = ''
  isBookmarked = false
  resultName = ''

  static fromJson(obj: any): Result {
    const result = plainToClass(Result, obj)
    result.countryFilters = result.countryFilters
      .map((countryFilter) => CountryFilter.fromJson(countryFilter))
      .sort((a, b) => b.count - a.count)

    result.thesaurusFilters = result.thesaurusFilters
      .map((thesaurusFilter) => ThesaurusFilter.fromJson(thesaurusFilter))
      .sort((a, b) => b.count - a.count)
    return result
  }

  static createCopy(result: Result, isBookmarked: boolean): Result {
    const resultCopy: Result = Result.fromJson(
      Object.assign({}, Result.fromJson(result)),
    )
    resultCopy.isBookmarked = isBookmarked
    return resultCopy
  }

  public toBookmark(
    searchModel: SearchModel,
    alphaId: string,
    taxonId: string,
    articleId: string,
    searchType: SearchTypes,
  ): SearchBookmark {
    return new SearchBookmark(
      this.id,
      'SEARCH',
      this.resultName,
      0,
      searchModel,
      alphaId,
      taxonId,
      articleId,
      searchType,
      '',
    )
  }
}
