import { InjectionToken, Injector } from '@angular/core'

export let AppInjector: Injector

export const setAppInjector = (injector: Injector): void => {
  if (AppInjector) {
    console.error('Error: AppInjector was already set')
  } else {
    AppInjector = injector
  }
}

export const ENV_TOKEN = new InjectionToken<any>('env')
