import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { CoreSharedState } from '../state/core-shared.state'
import { CoreSharedFacade } from './core-shared.facade'

@NgModule({
  declarations: [],
  providers: [CoreSharedFacade],
  imports: [NgxsModule.forFeature([CoreSharedState])],
})
export class CoreSharedModule {}
