import { StorageEngine } from '@ngxs/storage-plugin'

export class StorageEngineService implements StorageEngine {
  get length(): number {
    return localStorage.length + sessionStorage.length
  }

  getItem(key: string): any {
    if (key === 'coreSharedState') {
      return sessionStorage.getItem(key)
    } else {
      return localStorage.getItem(key)
    }
  }

  setItem(key: string, val: any): void {
    if (key === 'coreSharedState') {
      sessionStorage.setItem(key, val)
    } else {
      localStorage.setItem(key, val)
    }
  }

  removeItem(key: string): void {
    if (key === 'coreSharedState') {
      sessionStorage.removeItem(key)
    } else {
      localStorage.removeItem(key)
    }
  }

  clear(): void {
    localStorage.clear()
    sessionStorage.clear()
  }
}
