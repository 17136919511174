import { Introduction } from '@/codices/app/data/models/introduction'
import { environment } from '@/codices/env/environment'
import { ApiService } from '@/core/features/shared/services/api.service'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'

const endpoint = 'introductions'
@Injectable()
export class IntroductionApiService {
  constructor(private apiService: ApiService, private http: HttpClient) {}
  env = environment

  getIntroduction(id: string | null): Observable<Introduction> {
    return this.apiService
      .get(`${endpoint}/${id}`)
      .pipe(map((value) => Introduction.fromJson(value)))
  }

  getIntroductionText(url: string | undefined): Observable<any> {
    const HTTPOptions: any = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ; charset=UTF-8',
      },
      responseType: 'text',
    }
    return this.http.get<string>(
      `${this.env.apiUrl}/Document/text?url=${url}`,
      HTTPOptions,
    )
  }
}
