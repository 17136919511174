import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { CoreSharedModule } from '../../core-shared/services/core-shared.module'
import { GeoModule } from '../../geo/geo.module'
import { GlobalModule } from '../../global/global.module'
import { LangModule } from '../../lang/lang.module'
import { ApiService } from './api.service'
import { ENV_TOKEN } from './app.injector'

@NgModule({
  imports: [
    HttpClientModule,
    CoreSharedModule,
    GeoModule,
    GlobalModule,
    LangModule,
  ],
})
export class CoreServicesModule {
  public static forRoot(environment: any): any {
    return {
      ngModule: CoreServicesModule,
      providers: [
        {
          provide: ENV_TOKEN,
          useValue: environment,
        },
        ApiService,
      ],
    }
  }
}
