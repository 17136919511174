import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { GeoApiService } from './services/geo-api.service'
import { GeoFacade } from './services/geo.facade'
import { GeoState } from './state/geo.state'

@NgModule({
  declarations: [],
  providers: [GeoApiService, GeoFacade],
  imports: [NgxsModule.forFeature([GeoState])],
})
export class GeoModule {}
