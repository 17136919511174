import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import { ApiService } from '@/core/features/shared/services/api.service'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

const TAXON_ENDPOINT = 'taxon'

@Injectable()
export class HighlightService {
  constructor(private apiService: ApiService) {}

  getTaxon(id: string | null): Observable<TaxonDto> {
    return this.apiService.get(`${TAXON_ENDPOINT}/${id}`).pipe(
      map((value) => {
        return TaxonDto.fromJson(value)
      }),
    )
  }

  highlightText(ids: string[], words: string[], text: string): string {
    text = text?.replace(this.regexBuilder(words), (match) => {
      const id = crypto.randomUUID()
      ids.push(id)
      //44px -> height of next word/doc header of a result page
      return `<span class="highlight-span" id="${id}">${match}</span>`
    })
    return text
  }

  regexBuilder(words: string[]): RegExp {
    const patterns = words.map((word) => {
      return '\\b' + word + '\\b'
    })
    const queryWordRegex = words.join('\\s+')
    patterns.unshift('\\b' + queryWordRegex + '\\b')
    const joined = patterns.join('|')
    const regex = new RegExp(joined, 'gi')
    return regex
  }
}
