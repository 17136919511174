export enum DocTypes {
  introduction = 'INTRODUCTION',
  precisInProgress = 'PRECIS_IN_PROGRESS',
  precis = 'PRECIS',
  fullText = 'FULLTEXT',
  courtDescription = 'COURTDESCRIPTION',
  constitution = 'CONSTITUTION', // <-- engueuler le back pour mettre au singulier
  law = 'LAW',
  report = 'REPORT',
  all = '',
}
