export const environment = {
  file: {
    maxSize: 28000000, // 28MB
  },
  entity: {
    defaultPageIndex: 0,
    defaultPageSize: 100,
  },
  precis: {
    defaultPageIndex: 0,
    defaultPageSize: 100,
    proofreadingDelay: 21, // days
  },
  navBar: {
    WCCJ_UR: 'https://www.venice.coe.int/WebForms/pages/?p=02_WCCJ',
    BULLETINS_URL:
      'https://www.venice.coe.int/WebForms/pages/?p=02_02_Bulletins',
    VENICE_URL: 'https://www.venice.coe.int',
  },
}
