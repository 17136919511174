import { EntityApiService } from '@/core/features/shared/services/entity-api.service'
import { Thesaurus } from '@/core/types/thesaurus.model'
import { Injectable, InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'

export const THESAURUS_API_SERVICE_TOKEN =
  new InjectionToken<ThesaurusApiService>('ThesaurusApiService')

@Injectable()
export class ThesaurusApiService extends EntityApiService<Thesaurus> {
  constructor() {
    super('thesaurus')
  }

  getAll(): Observable<Thesaurus[]> {
    return this.api.get<Thesaurus[]>('thesaurus')
  }
}
