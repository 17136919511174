import { environment } from '@/core/environment'
import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'shell-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent {
  @Output() logoClicked = new EventEmitter()
  vensiteUrl = environment.navBar.VENICE_URL
  logoClick(): void {
    this.logoClicked.emit()
  }
}
