import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'

import {
  OpenFilterDrawer,
  OpenLoader,
  OpenMenuForSmallScreens,
  OpenTreeForSmallScreens,
} from './global.action'

export class GlobalStateModel {
  isMenu: boolean
  isTree: boolean
  isFilterDrawerOpen: boolean
  isLoading: boolean
}

@State({
  name: 'globalState',
  defaults: {
    isMenu: false,
    isTree: false,
    isFilterDrawerOpen: false,
    isLoading: false
  },
})
@Injectable()
export class GlobalState {
  @Selector()
  static selectIsMenuOpen(state: GlobalStateModel): any {
    return state.isMenu
  }
  @Selector()
  static selectIsTreeOpen(state: GlobalStateModel): any {
    return state.isTree
  }
  @Selector()
  static selectisFilterDrawerOpen(state: GlobalStateModel): any {
    return state.isFilterDrawerOpen
  }
  @Selector()
  static selectIsLoading(state: GlobalStateModel): any {
    return state.isLoading
  }

  @Action(OpenMenuForSmallScreens)
  openMenu(
    ctx: StateContext<GlobalStateModel>,
    { isMenuOpen }: OpenMenuForSmallScreens,
  ): any {
    ctx.patchState({
      isMenu: isMenuOpen,
    })
  }

  @Action(OpenTreeForSmallScreens)
  openTree(
    ctx: StateContext<GlobalStateModel>,
    { isTreeOpen }: OpenTreeForSmallScreens,
  ): any {
    ctx.patchState({
      isTree: isTreeOpen,
    })
  }

  @Action(OpenFilterDrawer)
  openFilterDrawer(
    ctx: StateContext<GlobalStateModel>,
    { isFilterDrawerOpen }: OpenFilterDrawer,
  ): any {
    ctx.patchState({
      isFilterDrawerOpen,
    })
  }

  @Action(OpenLoader)
  openLoader(
    ctx: StateContext<GlobalStateModel>,
    { isLoading }: OpenLoader,
  ): any {
    ctx.patchState({
      isLoading,
    })
  }
}
