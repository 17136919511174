import { ShellModule } from '@/shell/shell.module'
import { VendorsModule } from '@/vendors/vendors.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { CodicesNgxsModule } from './codices-ngxs.module'
import { BookmarksStorageService } from './features/bookmarks/services/bookmarks-storage.service'
import { CodicesQuickSearchComponent } from './features/shared/codices-quick-search/codices-quick-search.component'
import { CodicesSnackBarComponent } from './features/shared/codices-snack-bar/codices-snack-bar.component'

@NgModule({
  declarations: [CodicesQuickSearchComponent, CodicesSnackBarComponent],
  providers: [BookmarksStorageService],
  imports: [
    CommonModule,
    ShellModule,
    VendorsModule,
    TranslateModule,
    RouterModule,
    CodicesNgxsModule,
  ],
  exports: [
    CommonModule,
    ShellModule,
    VendorsModule,
    TranslateModule,
    CodicesQuickSearchComponent,
    RouterModule,
    CodicesNgxsModule,
  ],
})
export class SharedModule {}
