export const klaroConfig = {
  elementID: 'klaro',
  storageMethod: 'localStorage',
  cookieName: 'klaro',
  lang: 'en',
  hideDeclineAll: true,
  hideLearnMore: true,
  htmlTexts: true,
  services: [],
}
