import { plainToClass } from 'class-transformer'

export class TaxonDto {
  alphaIndexId: string
  id: string
  word: string
  languageCode: string
  isAllowed: boolean
  isDisplayed: boolean
  hasLink: boolean
  order: number

  static fromJson(obj: any): TaxonDto {
    return plainToClass(TaxonDto, obj)
  }
}
