import { PrecisDto } from '@/codices/app/data/models/precis'
import { ApiService } from '@/core/features/shared/services/api.service'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'

const ENDPOINT = 'precis'

@Injectable()
export class PrecisApiService {
  constructor(private apiService: ApiService) {}

  getPrecis(id: string | null): Observable<PrecisDto> {
    return this.apiService
      .get(`${ENDPOINT}/${id}`)
      .pipe(map((value) => PrecisDto.fromJson(value)))
  }
}
