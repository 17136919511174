import { DocType } from '@/core/types/doc-type.enum'

export class NodeTypeHelper {
  static pathToNodeType(path: string): DocType {
    switch (path) {
      case 'precis':
        return DocType.precis
      case 'fulltext':
        return DocType.fullText
      case 'description':
        return DocType.courtDescription
      case 'constitution':
        return DocType.constitution
      case 'law':
        return DocType.law
      case 'report':
        return DocType.report
      case 'precis-in-progress':
        return DocType.precisInProgress
      default:
        return DocType.introduction
    }
  }

  static typeToPath(type: DocType): string {
    switch (type) {
      case DocType.precis:
        return 'precis'
      case DocType.precisInProgress:
        return 'precis-in-progress'
      case DocType.fullText:
        return 'fulltext'
      case DocType.courtDescription:
        return 'description'
      case DocType.constitution:
        return 'constitution'
      case DocType.law:
        return 'law'
      case DocType.report:
        return 'report'
      default:
        return ''
    }
  }
}
