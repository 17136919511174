import { MeApiService } from '@/core/features/me/services/me-api.service'
import {
  PRECIS_API_SERVICE_TOKEN,
  PrecisApiService as SharedPrecisApiService,
} from '@/core/features/precis/services/precis-api.service'
import { PrecisState as SharedPrecisState } from '@/core/features/precis/state/precis.state'
import { AlertsFacade } from '@/core/features/shared/services/alert/alert.facade'
import { AlertsState } from '@/core/features/shared/services/alert/state/alert.state'
import {
  ThesaurusApiService as SharedThesaurusApiService,
  THESAURUS_API_SERVICE_TOKEN,
} from '@/core/features/thesaurus/services/thesaurus-api.service'
import { ThesaurusState as SharedThesaurus } from '@/core/features/thesaurus/state/thesaurus.state'
import { UsersApiService } from '@/core/features/users/services/users-api.service'
import { UsersState as SharedUsersState } from '@/core/features/users/state/users.state'
import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { BookmarksApiService } from './features/bookmarks/services/bookmarks-api.service'
import { BookmarksFacade } from './features/bookmarks/services/bookmarks.facade'
import { BookmarkState } from './features/bookmarks/state/bookmarks.state'
import { ConstitutionApiService } from './features/documents/constitution/services/constitution-api.service'
import { ConstitutionService } from './features/documents/constitution/services/constitution.service'
import { ConstitutionState } from './features/documents/constitution/state/constitution.state'
import { DescriptionApiService } from './features/documents/description/services/description-api.service'
import { DescriptionService } from './features/documents/description/services/description.service'
import { DescriptionState } from './features/documents/description/state/description.state'
import { FullTextApiService } from './features/documents/fulltext/services/fulltext-api.service'
import { FulltextService } from './features/documents/fulltext/services/fulltext.service'
import { FullTextState } from './features/documents/fulltext/state/fulltext.state'
import { IntroductionApiService } from './features/documents/intro/services/intro-api.service'
import { IntroductionFacade } from './features/documents/intro/services/intro.facade'
import { LawApiService } from './features/documents/law/services/law-api.service'
import { LawService } from './features/documents/law/services/law.service'
import { LawState } from './features/documents/law/state/law.state'
import { LiaisonAgentsApiService } from './features/documents/liaison-agents/services/liaison-agents-api.service'
import { LiaisonAgentsState } from './features/documents/liaison-agents/state/liaison-agents.state'
import { PrecisApiService } from './features/documents/precis/services/precis-api.service'
import { PrecisService } from './features/documents/precis/services/precis.service'
import { PrecisState } from './features/documents/precis/state/precis.state'
import { DocumentsFacade } from './features/documents/services/documents.facade'
import { DocumentsTreeState } from './features/documents/tree/state/documents-tree.state'
import { ResultListState } from './features/results/results-list/state/results-list.state'
import { ResultsTreeState } from './features/results/results-tree/state/results-tree.state'
import { HighlightFacade } from './features/results/services/highlight.facade'
import { HighlightService } from './features/results/services/highlight.service'
import { ResultsFacade } from './features/results/services/results.facade'
import { HighlightState } from './features/results/state/highlight.state'
import { ResultState } from './features/results/state/result.state'
import { AdvancedSearchTreeState } from './features/search/advanced-search/advanced-search-tree/state/advanced-search-tree.state'
import { HighlightAdvancedSearchService } from './features/search/services/highlight-advanced-search.service'
import { SearchApiService } from './features/search/services/search-api.service'
import { SearchFacade } from './features/search/services/search.facade'
import { SearchState } from './features/search/state/search.state'
import { TreesApiService } from './features/shared/tree/services/trees-api.service'
import { NodeClickHandler } from './features/shared/tree/state/node-click.handler'
import { MeState } from './me/state/me.state'

@NgModule({
  imports: [
    NgxsModule.forFeature([
      ResultState,
      ConstitutionState,
      DescriptionState,
      FullTextState,
      LawState,
      LiaisonAgentsState,
      PrecisState,
      HighlightState,
      BookmarkState,
      SearchState,
      ResultListState,
      DocumentsTreeState,
      ResultsTreeState,
      AdvancedSearchTreeState,
      AlertsState,
      SharedPrecisState,
      SharedUsersState,
      SharedThesaurus,
      MeState,
    ]),
  ],
  exports: [],
  providers: [
    SearchApiService,
    TreesApiService,
    BookmarksApiService,
    PrecisApiService,
    MeApiService,
    LiaisonAgentsApiService,
    LawApiService,
    FullTextApiService,
    DescriptionApiService,
    ConstitutionApiService,
    DocumentsFacade,
    NodeClickHandler,
    ResultsFacade,
    AlertsFacade,
    HighlightFacade,
    HighlightService,
    HighlightAdvancedSearchService,
    BookmarksFacade,
    SearchFacade,
    PrecisService,
    DescriptionService,
    LawService,
    ConstitutionService,
    FulltextService,
    IntroductionApiService,
    MeApiService,
    IntroductionFacade,
    {
      provide: PRECIS_API_SERVICE_TOKEN,
      useClass: SharedPrecisApiService,
    },
    {
      provide: THESAURUS_API_SERVICE_TOKEN,
      useClass: SharedThesaurusApiService,
    },
    UsersApiService,
  ],
})
export class CodicesNgxsModule {}
