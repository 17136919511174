import { Lang } from '@/core/types/lang'
import { Injectable, inject } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { LangChangeLang } from '../state/lang.action'
import { LangState } from '../state/lang.state'

/**
 * Facade for the lang feature
 * Can be used in other features to manage the current language
 */
@Injectable({
  providedIn: 'root',
})
export class LangFacade {
  /* DI */
  private store = inject(Store)

  /* Selectors */
  /**
   * Selects the current language from the state
   * @returns The current language
   */
  @Select(LangState.lang)
  lang$: Observable<Lang>

  /**
   * Returns the current language as a snapshot
   * @returns The current language
   */
  get lang(): Lang {
    return this.store.selectSnapshot(LangState.lang)
  }

  /* Actions */
  /**
   * Changes the current language
   * @param lang The new language
   */
  changeLang(lang: Lang): void {
    this.store.dispatch(new LangChangeLang(lang))
  }
}
