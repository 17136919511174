import { PrecisDto } from '@/codices/app/data/models/precis'
import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { ResultIntent } from '@/codices/app/features/results/state/result.state'
import { PrecisViewModel } from './precis.state'

export class GetPrecis {
  static readonly type = '[Precis] Get a precis with all his translations'
  constructor(public id: string | null) {}
}

export class GetPrecisViewModel {
  static readonly type = '[Precis] Get a precis with one translation'
  constructor(public selectedLanguage: string) {}
}

export class SetPrecisViewModel {
  static readonly type = '[Precis] Set a precis with one translation'
  constructor(public vm: PrecisViewModel) {}
}

export class BookmarkPrecis {
  static readonly type = '[Precis] bookmark precis'
  constructor(public precis: PrecisDto) {}
}

export class HighlightPrecis {
  static readonly type = '[Precis] highlight precis'
  constructor(
    public queryWords: string[],
    public resultIntent: ResultIntent,
    public highlightType: SearchTypes,
  ) {}
}
