<header id="navigation">
  <mat-toolbar class="mat-toolbar-header head-shadow" color="primary">
    <shell-header (logoClicked)="handleLogoCLicked()"></shell-header>
    <app-codices-nav-header></app-codices-nav-header>
    <span class="example-spacer"></span>
    <div class="codices-langage">
      <shell-header-account *ngIf="isUserConnected()"></shell-header-account>
      <shell-header-language></shell-header-language>
      <app-codices-header-menu></app-codices-header-menu>
    </div>
  </mat-toolbar>
</header>
<header id="navigation-small-screen">
  <mat-toolbar class="mat-toolbar-header head-shadow" color="primary">
    <div class="menu-button-container">
      <button mat-icon-button (click)="openMenuForSmallScreens()">
        <mat-icon class="search-loop">menu</mat-icon>
      </button>
    </div>
    <span class="example-spacer"></span>
    <shell-header (logoClicked)="handleLogoCLicked()"></shell-header>
    <span class="example-spacer"></span>
    <div class="codices-langage">
      <shell-header-language></shell-header-language>
      <app-codices-header-menu></app-codices-header-menu>
    </div>
  </mat-toolbar>
</header>
