import { Page } from '@/core/types/entities-state-model'

export class UsersFetchAll {
  static readonly type = '[Users] FetchAll'
}

export class UsersChangePage {
  static readonly type = '[Users] ChangePage'
  constructor(public page: Partial<Page>) {}
}

export class UsersGetAll {
  static readonly type = '[Users] GetAll'
  constructor(public revalidate = false) {}
}
