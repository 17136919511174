import { Bookmark } from '@/core/types/bookmark.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { BookmarksApiService } from '../services/bookmarks-api.service'
import {
  AddBookmark,
  DeleteBookmark,
  GetBookmarks,
  UpdateBookmark,
} from './bookmarks.action'

export class BookmarkStateModel {
  bookmarks: any
}

@State({
  name: 'bookmarkState',
  defaults: {
    bookmarks: [],
  },
})
@Injectable()
export class BookmarkState {
  constructor(private service: BookmarksApiService) {}

  @Selector()
  static selectBookmarkStateDatas(state: BookmarkStateModel): any {
    return state.bookmarks
  }

  @Action(GetBookmarks)
  getBookmarks(bookmarkStateContext: StateContext<BookmarkStateModel>): any {
    const state = bookmarkStateContext.getState()
    const bookmarkStates: Bookmark[] = this.service.getBookmarks()
    bookmarkStateContext.patchState({
      ...state,
      bookmarks: bookmarkStates,
    })
  }

  @Action(DeleteBookmark)
  deleteBookmark(
    bookmarkStateContext: StateContext<BookmarkStateModel>,
    { id }: DeleteBookmark,
  ): any {
    const state = bookmarkStateContext.getState()
    const bookmarksCopy: Bookmark[] = Object.assign([], state.bookmarks)
    const index = bookmarksCopy.findIndex((object) => {
      return object.id === id
    })

    //special case if we delete a search

    if (index !== -1) {
      bookmarksCopy.splice(index, 1)
      bookmarkStateContext.patchState({
        bookmarks: bookmarksCopy,
      })
    }
  }

  @Action(UpdateBookmark)
  updateBookmark(
    bookmarkStateContext: StateContext<BookmarkStateModel>,
    { id, title }: UpdateBookmark,
  ): any {
    const state = bookmarkStateContext.getState()
    const bookmarksCopy: Bookmark[] = Object.assign([], state.bookmarks)
    const index = bookmarksCopy.findIndex((object) => {
      return object.id === id
    })
    if (index !== -1) {
      const bookmarkCopy: Bookmark = Object.assign({}, bookmarksCopy[index])
      bookmarkCopy.title = title
      bookmarksCopy[index] = bookmarkCopy
      bookmarkStateContext.patchState({
        ...state,
        bookmarks: bookmarksCopy,
      })
    }
  }

  @Action(AddBookmark)
  addBookmark(
    bookmarkStateContext: StateContext<BookmarkStateModel>,
    { bookmark }: AddBookmark,
  ): void {
    const state = bookmarkStateContext.getState()
    const bookmarksCopy = Object.assign([], state.bookmarks)
    bookmarksCopy.push(bookmark)
    bookmarkStateContext.patchState({ ...state, bookmarks: bookmarksCopy })
  }
}
