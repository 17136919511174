import { ResultIntent } from '@/codices/app/features/results/state/result.state'
import { Constitution } from '@/core/types/constitution.model'
import { ConstitutionViewModel } from './constitution.state'

export class GetConstitution {
  static readonly type = '[Constitution] Get a constitution'
  constructor(public id: string | null, public date: Date) {}
}

export class GetConstitutionViewModel {
  static readonly type = '[Constitution] Get viewModel of a constitution'
  constructor(public language: string) {}
}

export class SetConstitutionViewModel {
  static readonly type = '[Constitution] Set viewModel of a constitution'
  constructor(public vm: ConstitutionViewModel) {}
}

export class BookmarkConstitution {
  static readonly type = '[Constitution] Bookmark a constitution'
  constructor(public constitution: Constitution, public language: string) {}
}

export class HighlightConstitution {
  static readonly type = '[Constitution] Highlight a constitution'
  constructor(public queryWords: string[], public resultIntent: ResultIntent) {}
}
