import { AlphaIndexDto } from '@/codices/app/data/models/alpha-index.model'
import {
  CountryFilter,
  SearchResult,
  ThesaurusFilter,
} from '@/codices/app/data/models/result.model'
import { ThesaurusTree } from '@/codices/app/data/models/thesaurus-tree.model'
import { SearchModel } from '@/core/types/search.model'
import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import {
  CheckResultCountry,
  CheckThesaurus,
} from '../../results/state/result.action'
import {
  AdvancedSearch,
  GetAlphaIndexes,
  GetAlphaIndexesViewModel,
  GetSearchThesaurus,
  SearchAlphaIndex,
  SearchPrecisLinkedToArticle,
  ThesaurusLanguage,
} from '../state/search.action'
import { AlphaIndexViewModel, SearchState } from '../state/search.state'

@Injectable()
export class SearchFacade {
  constructor(private store: Store) {}

  @Select(SearchState.selectAlphaIndexes)
  alphaIndexDto$: Observable<AlphaIndexDto[]>

  @Select(SearchState.selectAlphaIndexesViewModel)
  alphaIndexVM$: Observable<AlphaIndexViewModel>

  @Select(SearchState.selectSearchThesaurusStateDatas)
  thesaurus$: Observable<ThesaurusTree[]>

  @Select(SearchState.selectIsLoading)
  isLoading$: Observable<boolean>

  public getAlphaIndexes(
    alphaIndex: string,
    selectedLanguage: string | undefined,
  ): Observable<void> {
    return this.store.dispatch(
      new GetAlphaIndexes(alphaIndex, selectedLanguage),
    )
  }

  public getAlphaIndexesViewModel(
    selectedLanguage: string | undefined,
  ): Observable<void> {
    return this.store.dispatch(new GetAlphaIndexesViewModel(selectedLanguage))
  }

  public getThesaurus(selectedLanguage: string | null): Observable<void> {
    return this.store.dispatch(new GetSearchThesaurus(selectedLanguage))
  }

  public changeThesaurusLanguage(selectedLanguage: string | null): void {
    this.store.dispatch(new ThesaurusLanguage(selectedLanguage))
  }

  public checkCountryFilter(countryFilter: CountryFilter): void {
    this.store.dispatch(new CheckResultCountry(countryFilter))
  }

  public checkThesaurusFilter(thesaurusFilter: ThesaurusFilter): void {
    this.store.dispatch(new CheckThesaurus(thesaurusFilter))
  }

  public simpleSearch(searchTerm: string): Observable<SearchResult> {
    const searchModel = new SearchModel()
    searchModel.Text = searchTerm
    return this.store.dispatch(new AdvancedSearch(searchModel))
  }

  public searchAlphaIndex(
    alphaIndexId: string,
    taxonId: string,
    page: number,
    size: number,
  ): Observable<any> {
    return this.store.dispatch(
      new SearchAlphaIndex(alphaIndexId, taxonId, page, size),
    )
  }

  public advancedSearch(searchModel: SearchModel): Observable<any> {
    return this.store.dispatch(new AdvancedSearch(searchModel))
  }

  public searchPrecisLinkedToArticle(
    articleId: string,
    page: number,
    size: number,
  ): Observable<any> {
    return this.store.dispatch(
      new SearchPrecisLinkedToArticle(articleId, page, size),
    )
  }
}
