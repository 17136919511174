import { TreeNode } from '@/core/types/tree-node.model'

export class NodesObject {
  type: TreeNode
  continent: TreeNode
  country: TreeNode
  node: TreeNode
}

export class TreeHelper {
  /*
   * Depth first search
   */
  // static findNode(
  //   tree: TreeNode[],
  //   findFct: (arg: TreeNode) => boolean,
  // ): TreeNode | null {
  //   for (let i = 0; i < tree.length; i++) {
  //     if (findFct(tree[i])) {
  //       return tree[i]
  //     }
  //     if (tree[i].children.length > 0) {
  //       const node = this.findNode(tree[i].children, findFct)
  //       if (node) {
  //         return node
  //       }
  //     }
  //   }
  //   return null
  // }

  /*
   * Breadth first search
   */
  static findNode(
    tree: TreeNode[],
    findFct: (arg: TreeNode) => boolean,
  ): TreeNode | null {
    // Create a queue to store the nodes to be visited
    const queue: TreeNode[] = []
    // Add the root nodes to the queue
    queue.push(...tree)
    // Iterate over the nodes in the queue
    while (queue.length > 0) {
      // Take the first node from the queue
      const node = queue.shift()
      if (node) {
        // Check if the node matches the search criteria
        if (findFct(node)) {
          return node
        }
        // Add the node's children to the queue
        queue.push(...node.children)
      }
    }
    // If no matching node was found, return null
    return null
  }

  /**
   *
   * @param tree
   * @param findFct
   * @returns list of all parents of a node
   */
  static findNodeParentsFct(
    tree: TreeNode[],
    findFct: (arg: TreeNode) => boolean,
  ): TreeNode[] {
    const parents: TreeNode[] = []
    for (let i = 0; i < tree.length; i++) {
      if (findFct(tree[i])) {
        parents.push(tree[i])
      }
      if (tree[i].children.length > 0) {
        const nodes = TreeHelper.findNodeParents(tree[i].children, findFct)
        if (nodes.length > 0) {
          parents.push(tree[i])
          parents.push(...nodes)
        }
      }
    }
    return parents
  }
  static findNodeParents(
    tree: TreeNode[],
    findFct: (arg: TreeNode) => boolean,
  ): TreeNode[] {
    const parents: TreeNode[] = []
    for (let i = 0; i < tree.length; i++) {
      if (findFct(tree[i])) {
        parents.push(tree[i])
      }
      if (tree[i].children.length > 0) {
        const nodes = this.findNodeParents(tree[i].children, findFct)
        if (nodes.length > 0) {
          parents.push(tree[i])
          parents.push(...nodes)
        }
      }
    }
    return parents
  }

  static setNodesInactive(tree: TreeNode[]): void {
    for (let i = 0; i < tree.length; i++) {
      tree[i].isActive = false
      if (tree[i].children.length > 0) {
        this.setNodesInactive(tree[i].children)
      }
    }
  }

  static findNodeFct(nodeCopy: TreeNode): (arg: TreeNode) => boolean {
    return (n: TreeNode): boolean =>
      n.id === nodeCopy.parent.id &&
      n.type === nodeCopy.parent.type &&
      n.level === nodeCopy.parent.level &&
      n.countryCode === nodeCopy.parent.countryCode
  }
}
