export enum DocType {
  introduction = 'INTRODUCTION',
  precisInProgress = 'PRECIS_IN_PROGRESS',
  precis = 'PRECIS',
  fullText = 'FULLTEXT',
  courtDescription = 'COURTDESCRIPTION',
  constitution = 'CONSTITUTION',
  law = 'LAW',
  report = 'REPORT',
  more = 'MORE',
  loading = 'LOADING',
}
