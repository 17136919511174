import {
  AppInjector,
  ENV_TOKEN,
} from '@/core/features/shared/services/app.injector'
import { plainToClass } from 'class-transformer'

export class SearchModel {
  Text: string
  Type: string
  WithProximity: boolean
  ProximitySize: number
  ReferenceCode: string
  DecisionNumber: string
  Title: string
  Continent: string
  Country: string
  StartDate: string
  EndDate: string
  LanguageCode: string
  Group: string
  ThesaurusIndexNumber: string
  ThesaurusText: string
  AlphaIndexText: string
  WithThesaurusChildren: boolean
  Page: number
  Size: number
  TreePathList: string[]
  CountryFilterList: string[]
  ThesaurusFilterList: string[]

  constructor() {
    this.Text = ''
    this.Type = ''
    this.WithProximity = false
    this.ProximitySize = 0
    this.ReferenceCode = ''
    this.DecisionNumber = ''
    this.Title = ''
    this.Continent = ''
    this.Country = ''
    this.StartDate = ''
    this.EndDate = ''
    this.LanguageCode = ''
    this.Group = ''
    this.ThesaurusIndexNumber = ''
    this.ThesaurusText = ''
    this.AlphaIndexText = ''
    this.WithThesaurusChildren = false
    this.CountryFilterList = []
    this.ThesaurusFilterList = []
    this.TreePathList = []
    this.Page = 0
    this.Size = AppInjector?.get(ENV_TOKEN).MID_SIZE
  }

  static fromJson(obj: any): SearchModel {
    return plainToClass(SearchModel, obj)
  }

  static createCopy(searchModel: SearchModel): SearchModel {
    const searchModelCopy: SearchModel = SearchModel.fromJson(
      Object.assign({}, SearchModel.fromJson(searchModel)),
    )
    return searchModelCopy
  }

  static toSearchRequest(searchModel: SearchModel): SearchRequest {
    return { ...searchModel }
  }

  public toSearchString(): string {
    let searchModelString = ''
    const searchModelArray: string[] = []
    searchModelArray.push(this.Text)
    searchModelArray.push(this.Type)
    searchModelArray.push(this.WithProximity.toString())
    searchModelArray.push(this.ProximitySize.toString())
    searchModelArray.push(this.ReferenceCode)
    searchModelArray.push(this.DecisionNumber)
    searchModelArray.push(this.Title)
    searchModelArray.push(this.Continent)
    searchModelArray.push(this.Country)
    searchModelArray.push(this.StartDate)
    searchModelArray.push(this.EndDate)
    searchModelArray.push(this.LanguageCode)
    searchModelArray.push(this.Group)
    searchModelArray.push(this.ThesaurusIndexNumber)
    searchModelArray.push(this.ThesaurusText)
    searchModelArray.push(this.AlphaIndexText)
    searchModelArray.push(this.WithThesaurusChildren.toString())
    this.TreePathList = this.TreePathList.sort((a, b) => a.length - b.length)
    this.CountryFilterList = this.CountryFilterList.sort(
      (a, b) => a.length - b.length,
    )
    this.ThesaurusFilterList = this.ThesaurusFilterList.sort(
      (a, b) => a.length - b.length,
    )

    searchModelString += this.TreePathList.join('-')
    searchModelString += this.CountryFilterList.join('-')
    searchModelString += this.ThesaurusFilterList.join('-')
    searchModelString += searchModelArray.join('-')
    return searchModelString
  }
}

export type SearchRequest = {
  Text: string
  Type: string
  WithProximity: boolean
  ProximitySize: number
  ReferenceCode: string
  DecisionNumber: string
  Title: string
  Continent: string
  Country: string
  StartDate: string
  EndDate: string
  LanguageCode: string
  Group: string
  ThesaurusIndexNumber: string
  ThesaurusText: string
  AlphaIndexText: string
  WithThesaurusChildren: boolean
  Page: number
  Size: number
  TreePathList: string[]
  CountryFilterList: string[]
  ThesaurusFilterList: string[]
}
