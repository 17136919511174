/**
 * Fetch all thesauruses from API
 */
export class ThesaurusFetchAll {
  static readonly type = '[Thesaurus] FetchAll'
}

/**
 * Get all thesauruses from state (cache) or API (if cache is empty)
 * @param revalidate - Force API call
 */
export class ThesaurusGetAll {
  static readonly type = '[Thesaurus] GetAll'
  constructor(public revalidate = false) {}
}

/**
 * Generate children into a single array
 */
export class ThesaurusGenerateChildren {
  static readonly type = '[Thesaurus] GenerateChildren'
}
