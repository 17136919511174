import { CourtDescription } from '@/codices/app/data/models/court-description.model'
import { BookmarksFacade } from '@/codices/app/features/bookmarks/services/bookmarks.facade'
import { GeoFacade } from '@/core/features/geo/services/geo.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { TranslationModel } from '@/core/types/translation.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { EMPTY, Observable, map, withLatestFrom } from 'rxjs'
import { DescriptionApiService } from '../services/description-api.service'
import { DescriptionService } from '../services/description.service'
import {
  BookmarkDescription,
  GetDescription,
  GetDescriptionViewmodel,
  HighlightDescription,
  SetDescriptionViewmodel,
} from './description.action'

export class DescriptionViewmodel {
  description: TranslationModel
  children: TranslationModel[]
}

export class DescriptionStateModel {
  description: CourtDescription
  descriptionViewmodel: DescriptionViewmodel
  languages: LanguageTranslation[]
  isBookmarked: boolean
  selectedLanguage: string
}

@State({
  name: 'descriptionState',
  defaults: {
    description: null,
    descriptionViewmodel: null,
    languages: [],
    isBookmarked: false,
    selectedLanguage: '',
  },
})
@Injectable()
export class DescriptionState {
  constructor(
    private apiService: DescriptionApiService,
    private bookmarkFacade: BookmarksFacade,
    private geoFacade: GeoFacade,
    private service: DescriptionService,
  ) {}

  languageTranslations: LanguageTranslation[] = []
  @Selector()
  static selectDescriptionStateDatas(
    state: DescriptionStateModel,
  ): CourtDescription {
    return state.description
  }

  @Selector()
  static selectDesctiptionViewmodelDatas(
    state: DescriptionStateModel,
  ): DescriptionViewmodel {
    return state.descriptionViewmodel
  }

  @Selector()
  static selectIsDescriptionBookmarked(state: DescriptionStateModel): boolean {
    return state.isBookmarked
  }

  @Selector()
  static selectDescriptionLanguages(
    state: DescriptionStateModel,
  ): LanguageTranslation[] {
    return state.languages
  }

  @Selector()
  static selectSelectedLanguage(state: DescriptionStateModel): string {
    return state.selectedLanguage
  }

  @Action(GetDescription)
  getDescription(
    descriptionStateContext: StateContext<DescriptionStateModel>,
    { id }: GetDescription,
  ): Observable<CourtDescription> {
    if (id) {
      return this.apiService.getDescription(id).pipe(
        withLatestFrom(
          this.bookmarkFacade.bookmarks$,
          this.geoFacade.countries$,
        ),
        map(([description, bookmarks, countries]) => {
          const state = descriptionStateContext.getState()
          const courtDescriptionCopy = CourtDescription.createCopy(
            description,
            false,
          )
          if (bookmarks.length > 0) {
            bookmarks.forEach((courtDescription: any) => {
              if (
                id !== null &&
                courtDescription.id.toLocaleUpperCase() ===
                  id.toLocaleUpperCase()
              ) {
                courtDescriptionCopy.isBookmarked = true
              }
            })
          }
          const courtLanguages = this.service.getBaseLanguages()
          courtDescriptionCopy.createBreadCrumb(
            countries,
            courtDescriptionCopy.country,
          )
          descriptionStateContext.patchState({
            ...state,
            description: courtDescriptionCopy,
            languages: courtLanguages,
          })
          return courtDescriptionCopy
        }),
      )
    }
    return EMPTY
  }

  @Action(SetDescriptionViewmodel)
  setDescriptionViewmodel(
    descriptionStateContext: StateContext<DescriptionStateModel>,
    { vm }: SetDescriptionViewmodel,
  ): void {
    const state = descriptionStateContext.getState()
    descriptionStateContext.patchState({
      ...state,
      descriptionViewmodel: vm,
    })
  }

  @Action(BookmarkDescription)
  bookmarkDescription(
    descriptionStateContext: StateContext<DescriptionStateModel>,
    { courtDescription, language }: BookmarkDescription,
  ): void {
    const state = descriptionStateContext.getState()
    const courtDescriptionCopy: CourtDescription = CourtDescription.createCopy(
      courtDescription,
      !courtDescription.isBookmarked,
    )
    if (courtDescriptionCopy.isBookmarked) {
      this.bookmarkFacade.addBookmark(courtDescriptionCopy.toBookmark(language))
    } else {
      this.bookmarkFacade.deleteBookmark(courtDescriptionCopy.id)
    }
    descriptionStateContext.patchState({
      ...state,
      description: {
        ...state.description,
        isBookmarked: courtDescriptionCopy.isBookmarked,
      } as CourtDescription,
    })
  }

  @Action(GetDescriptionViewmodel)
  getDescriptionViewmodel(
    ctx: StateContext<DescriptionStateModel>,
    { selectedLanguage }: GetDescriptionViewmodel,
  ): void {
    const state = ctx.getState()
    if (state.description) {
      const descriptionCopy: CourtDescription = CourtDescription.createCopy(
        state.description,
        state.isBookmarked,
      )
      if (selectedLanguage !== undefined) {
        const isLanguageAvailable = descriptionCopy.languages.some(
          (l) => l === selectedLanguage,
        )

        if (!isLanguageAvailable) {
          selectedLanguage = descriptionCopy.languages[0]
        }

        const viewModel = this.service.getViewModel(selectedLanguage)

        const courtLanguages = this.service.getBaseLanguages()

        ctx.patchState({
          ...state,
          descriptionViewmodel: viewModel,
          languages: courtLanguages,
          selectedLanguage: selectedLanguage,
        })
      }
    }
  }

  @Action(HighlightDescription)
  HighlightDescription(
    ctx: StateContext<DescriptionStateModel>,
    { queryWords, resultIntent }: HighlightDescription,
  ): void {
    const state = ctx.getState()
    if (queryWords != null && resultIntent != null && queryWords.length > 0) {
      const vm: DescriptionViewmodel = this.service.highlight(queryWords)
      ctx.patchState({
        ...state,
        descriptionViewmodel: vm,
      })
    }
  }
}
