import { Bookmark } from '@/core/types/bookmark.model'
import { plainToClass } from 'class-transformer'
import { BaseDocumentClass } from '../../../../../libs/core/src/lib/types/base-document-class.model'
import { Bookmarkable } from '../../../../../libs/core/src/lib/types/bookmarkable.inteface'
import { DocType } from '../../../../../libs/core/src/lib/types/doc-type.enum'
import { AlphaIndexDto } from './alpha-index.model'
import { CourtDescription } from './court-description.model'
import { FullText } from './fulltext.model'
import { precisTranslationDto } from './precis-translation'
import { ThesaurusDto } from './thesaurus.model'

export class PrecisDto extends BaseDocumentClass implements Bookmarkable {
  id: string
  alphaIndex?: AlphaIndexDto[]
  thesaurus: ThesaurusDto[]
  currentStatus: string
  decisionDate: Date
  fullTexts: FullText[]
  decisionNumber: string
  isPublished: boolean
  isFinalized: boolean
  crossReference: string
  referenceCode: string
  reference: string
  eBulletin: string
  filePath?: string
  country: string
  isBookmarked: boolean
  docType: string
  languages: string[]
  precisTranslations: Map<string, precisTranslationDto>
  bookmarkOrder: number
  courtDescription: CourtDescription

  static createBookmarkTitle(
    precis: PrecisDto,
    selectedLanguage: string,
  ): string {
    const translations = new Map<string, precisTranslationDto>(
      Object.entries(precis.precisTranslations),
    )
    const precisTranslated = translations.get(selectedLanguage)
    let title = ''
    title +=
      precis.referenceCode +
      ' ' +
      precis.decisionDate +
      ' ' +
      precisTranslated?.decisionNumber +
      ' ' +
      precisTranslated?.title
    return title
  }

  static fromJson(obj: any): PrecisDto {
    return plainToClass(PrecisDto, obj)
  }

  public toBookmark(selectedLanguage: string): Bookmark {
    if (this.isFinalized) {
      return new Bookmark(
        this.id,
        DocType.precis,
        PrecisDto.createBookmarkTitle(this, selectedLanguage),
        this.bookmarkOrder,
        this.breadCrumb,
      )
    }
    return new Bookmark(
      this.id,
      DocType.precisInProgress,
      PrecisDto.createBookmarkTitle(this, selectedLanguage),
      this.bookmarkOrder,
      this.breadCrumb,
    )
  }

  static createCopy(precis: PrecisDto, isBookmarked: boolean): PrecisDto {
    const precisCopy: PrecisDto = PrecisDto.fromJson(
      Object.assign({}, PrecisDto.fromJson(precis)),
    )
    precisCopy.isBookmarked = isBookmarked
    return precisCopy
  }
}
