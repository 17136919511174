import { Bookmark } from '@/core/types/bookmark.model'
import { SearchModel } from '../../../../../libs/core/src/lib/types/search.model'
import { SearchTypes } from './search-types.enum'

export class SearchBookmark extends Bookmark {
  searchModel: SearchModel
  alphaId: string
  taxonId: string
  articleId: string
  searchType: SearchTypes
  constructor(
    id: string,
    type: string,
    title: string,
    order: number,
    searchModel: SearchModel,
    alphaId: string,
    taxonId: string,
    articleId: string,
    searchType: SearchTypes,
    breadCrumb: string,
  ) {
    super(id, type, title, order, breadCrumb)
    this.searchModel = searchModel
    this.alphaId = alphaId
    this.taxonId = taxonId
    this.articleId = articleId
    this.searchType = searchType
  }
}
