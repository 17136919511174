import { Page } from '@/core/types/entities-state-model'
import { PrecisFilters } from '@/core/types/precis-filters'
import { PrecisPublicationState } from '@/core/types/precis-publication-state.enum'

export class PrecisFetchAll {
  static readonly type = '[Precis] FetchAll'
}

export class PrecisFetchAllForUser {
  static readonly type = '[Precis] FetchAllForUser'
}

export class PrecisChangePage {
  static readonly type = '[Precis] ChangePage'
  constructor(public page: Partial<Page>) {}
}

export class PrecisChangePageForUser {
  static readonly type = '[Precis] ChangePageForUser'
  constructor(public page: Partial<Page>) {}
}

export class PrecisPatchFilters {
  static readonly type = '[Precis] PatchFilters'
  constructor(public filters: Partial<PrecisFilters>) {}
}

export class PrecisDelete {
  static readonly type = '[Precis] Delete'
  constructor(public id: string) {}
}

export class PrecisUpdatePublicationState {
  static readonly type = '[Precis] UpdatePublicationState'
  constructor(public id: string, public state: PrecisPublicationState) {}
}

export class PrecisGetStatutes {
  static readonly type = '[Precis] FetchStatutes'
}
