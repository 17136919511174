import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { JsonArray, JsonObject, PartialDeep } from 'type-fest'
import { ENV_TOKEN } from './app.injector'

export type Params = {
  [param: string]:
    | string
    | number
    | boolean
    | readonly (string | number | boolean)[]
    | undefined
}

export type ValidParams = {
  [param: string]:
    | string
    | number
    | boolean
    | readonly (string | number | boolean)[]
}

export type Headers = {
  [header: string]: string | string[]
}

type JsonData = PartialDeep<JsonObject> | JsonArray

@Injectable()
export class ApiService {
  /* DI */
  http = inject(HttpClient)
  private environment = inject(ENV_TOKEN)

  /* Public methods */
  get<TRes extends JsonData = JsonData>(
    endpoint: string,
    params?: Params,
    headers?: Headers,
  ): Observable<TRes> {
    return this.http
      .get<TRes>(`${this.environment.apiUrl}/${endpoint}`, {
        observe: 'body',
        responseType: 'json',
        params: this.getValidParams(params),
        headers,
      })
      .pipe(catchError(this.errorHandler))
  }

  download<TRes extends JsonData = JsonData>(
    endpoint: string,
    params?: Params,
    headers?: Headers,
  ): Observable<any> {
    const HTTPOptions: any = {
      observe: 'body',
      responseType: 'blob',
      params: this.getValidParams(params),
      headers,
    }
    return this.http
      .get<TRes>(`${this.environment.apiUrl}/${endpoint}`, HTTPOptions)
      .pipe(catchError(this.errorHandler))
  }

  post<
    TRes extends JsonData | void = JsonData,
    TBody extends JsonData = JsonData,
  >(
    endpoint: string,
    body?: TBody | FormData,
    params?: Params,
  ): Observable<TRes> {
    return this.http
      .post<TRes>(`${this.environment.apiUrl}/${endpoint}`, body, {
        observe: 'body',
        responseType: 'json',
        params: this.getValidParams(params),
      })
      .pipe(catchError(this.errorHandler))
  }

  put<
    TRes extends JsonData | void = JsonData,
    TBody extends JsonData = JsonData,
  >(
    endpoint: string,
    body?: TBody | FormData,
    params?: Params,
  ): Observable<TRes> {
    return this.http
      .put<TRes>(`${this.environment.apiUrl}/${endpoint}`, body, {
        observe: 'body',
        responseType: 'json',
        params: this.getValidParams(params),
      })
      .pipe(catchError(this.errorHandler))
  }

  patch<
    TRes extends JsonData | void = JsonData,
    TBody extends JsonData = JsonData,
  >(
    endpoint: string,
    body?: TBody | FormData,
    params?: Params,
  ): Observable<TRes> {
    return this.http
      .patch<TRes>(`${this.environment.apiUrl}/${endpoint}`, body, {
        observe: 'body',
        responseType: 'json',
        params: this.getValidParams(params),
      })
      .pipe(catchError(this.errorHandler))
  }

  delete<TRes extends JsonData | void = JsonData>(
    endpoint: string,
    params?: Params,
  ): Observable<TRes> {
    return this.http
      .delete<TRes>(`${this.environment.apiUrl}/${endpoint}`, {
        observe: 'body',
        responseType: 'json',
        params: this.getValidParams(params),
      })
      .pipe(catchError(this.errorHandler))
  }

  getValidParams(params: Params | undefined): ValidParams | undefined {
    if (params === undefined) return undefined
    const validParams = structuredClone(params)
    for (const param in validParams) {
      if (validParams[param] === undefined) {
        delete validParams[param]
      }
    }
    return validParams as ValidParams
  }

  /* Private methods */
  private errorHandler(response: HttpErrorResponse): Observable<never> {
    return throwError(
      () => response ?? new Error('Api request got a Server Error'),
    )
  }
}
