import {
  CountryFilter,
  ThesaurusFilter,
} from '@/codices/app/data/models/result.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  AddCountryFilter,
  AddThesaurusFilter,
  RemoveAddedCountryFilter,
  RemoveAddedThesaurusFilter,
} from './results-list.action'

export class ResultsStateModel {
  addedCountryFilters: CountryFilter[]
  addedThesaurusFilters: ThesaurusFilter[]
}

@State({
  name: 'resultListState',
  defaults: {
    addedCountryFilters: [],
    addedThesaurusFilters: [],
  },
})
@Injectable()
export class ResultListState {
  @Selector()
  static selectAddedCountryFilters(state: ResultsStateModel): any {
    return state.addedCountryFilters
  }
  @Selector()
  static selectAddedThesaurusFilters(state: ResultsStateModel): any {
    return state.addedThesaurusFilters
  }

  @Action(AddCountryFilter)
  addCountryFilters(
    resultsStateContext: StateContext<ResultsStateModel>,
    { countryFilter }: AddCountryFilter,
  ): void {
    const state = resultsStateContext.getState()
    const countryFiltersAdded = JSON.parse(
      JSON.stringify(state.addedCountryFilters),
    )
    countryFiltersAdded.push(countryFilter)
    resultsStateContext.patchState({
      ...state,
      addedCountryFilters: countryFiltersAdded,
    })
  }

  @Action(RemoveAddedCountryFilter)
  removeAddedCountryFilters(
    resultsStateContext: StateContext<ResultsStateModel>,
    { countryFilter }: RemoveAddedCountryFilter,
  ): void {
    const state = resultsStateContext.getState()
    const countryFiltersAdded = JSON.parse(
      JSON.stringify(state.addedCountryFilters),
    )
    const index = countryFiltersAdded.findIndex(
      (countryFilt: CountryFilter) => countryFilt.code === countryFilter.code,
    )
    if (index !== -1) {
      countryFiltersAdded.splice(index, 1)
    }
    resultsStateContext.patchState({
      ...state,
      addedCountryFilters: countryFiltersAdded,
    })
  }

  @Action(AddThesaurusFilter)
  addThesaurusFilters(
    resultsStateContext: StateContext<ResultsStateModel>,
    { thesaurusFilter }: AddThesaurusFilter,
  ): void {
    const state = resultsStateContext.getState()
    const thesaurusFiltersAdded = JSON.parse(
      JSON.stringify(state.addedThesaurusFilters),
    )
    thesaurusFiltersAdded.push(thesaurusFilter)
    resultsStateContext.patchState({
      ...state,
      addedThesaurusFilters: thesaurusFiltersAdded,
    })
  }

  @Action(RemoveAddedThesaurusFilter)
  removeAddedThesaurusFilters(
    resultsStateContext: StateContext<ResultsStateModel>,
    { thesaurusFilter }: RemoveAddedThesaurusFilter,
  ): void {
    const state = resultsStateContext.getState()
    const thesaurusFiltersAdded = JSON.parse(
      JSON.stringify(state.addedThesaurusFilters),
    )
    const index = thesaurusFiltersAdded.findIndex(
      (thesaurusFilt: ThesaurusFilter) =>
        thesaurusFilt.indexNumber === thesaurusFilter.indexNumber,
    )
    if (index !== -1) {
      thesaurusFiltersAdded.splice(index, 1)
    }
    resultsStateContext.patchState({
      ...state,
      addedThesaurusFilters: thesaurusFiltersAdded,
    })
  }
}
