import { TreeNode } from '@/core/types/tree-node.model'
import { plainToClass, Type } from 'class-transformer'
import 'reflect-metadata'

export class CodicesTree {
  @Type(() => TreeNode)
  introductionTree: TreeNode
  @Type(() => TreeNode)
  precisInProgressTree: TreeNode
  @Type(() => TreeNode)
  precisTree: TreeNode
  @Type(() => TreeNode)
  fullTextTree: TreeNode
  @Type(() => TreeNode)
  courtDescriptionTree: TreeNode
  @Type(() => TreeNode)
  constitutionTree: TreeNode
  @Type(() => TreeNode)
  lawTree: TreeNode
  @Type(() => TreeNode)
  reportTree: TreeNode

  static fromJson(obj: any): CodicesTree {
    const res = plainToClass(CodicesTree, obj)
    // Add more node to each tree and its children
    res?.toArray().forEach((tree) => {
      CodicesTree.addMoreNodeRecursive(tree)
    })
    return res
  }

  // Add more node to each leaf of the tree recursively
  static addMoreNodeRecursive(tree: TreeNode): TreeNode {
    if (tree?.hasMoreChildren) {
      tree.children.push(TreeNode.getMoreNode(tree))
    }
    tree?.children.forEach((child) => {
      CodicesTree.addMoreNodeRecursive(child)
    })
    return tree
  }

  public toArray(): TreeNode[] {
    return [
      this.introductionTree,
      this.precisInProgressTree,
      this.precisTree,
      this.fullTextTree,
      this.courtDescriptionTree,
      this.constitutionTree,
      this.lawTree,
      this.reportTree,
    ]
  }

  public toArrayNoIntro(): TreeNode[] {
    return [
      this.precisInProgressTree,
      this.precisTree,
      this.fullTextTree,
      this.courtDescriptionTree,
      this.constitutionTree,
      this.lawTree,
      this.reportTree,
    ]
  }
}
