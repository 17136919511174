import { MeFacade } from '@/codices/app/me/services/me.facade'
import { ENV_TOKEN } from '@/core/features/shared/services/app.injector'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, catchError } from 'rxjs'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private environment = inject(ENV_TOKEN)
  private meFacade = inject(MeFacade)

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = this.meFacade.isLoggedIn()
    const isApiUrl = request.url.startsWith(this.environment.apiUrl)
    if (isLoggedIn && isApiUrl) {
      const accessToken = this.meFacade.accessToken
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      })
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.meFacade.redirectWhenTokenExp()
        }
        throw err
      }),
    )
  }
}
