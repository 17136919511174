import { DocumentsFacade } from '@/codices/app/features/documents/services/documents.facade'
import { NodeClicked } from '@/codices/app/features/documents/tree/state/documents-tree.action'
import { ResultNodeClicked } from '@/codices/app/features/results/results-tree/state/results-tree.action'
import { ResultsFacade } from '@/codices/app/features/results/services/results.facade'
import { CoreSharedFacade } from '@/core/features/core-shared/services/core-shared.facade'
import { DocType } from '@/core/types/doc-type.enum'
import { IntroNodeType } from '@/core/types/intro-node-type.enum'
import { TreeNode } from '@/core/types/tree-node.model'
import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { Actions, ofActionDispatched } from '@ngxs/store'

@Injectable()
export class NodeClickHandler {
  constructor(
    private router: Router,
    private actions$: Actions,
    private coreSharedFacade: CoreSharedFacade,
    private documentsFacade: DocumentsFacade,
    private resultFacade: ResultsFacade,
  ) {
    this.actions$
      .pipe(ofActionDispatched(NodeClicked, ResultNodeClicked))
      .subscribe((clicked) => {
        const { node, isExpanded, countryCode } = clicked
        const isResultNode =
          clicked instanceof ResultNodeClicked ? clicked.isResultNode : false

        if (node.type !== DocType.more && node.type !== DocType.loading) {
          if ((!countryCode || isExpanded) && node.type !== DocType.report) {
            if (
              node.id === '00000000-0000-0000-0000-000000000000' &&
              node.countryCode &&
              node.children.length
            ) {
              this.navigateToDocument(node.children[0], isResultNode)
            } else {
              this.navigateToDocument(node, isResultNode)
            }
          } else if (
            (node.level !== 0 || isExpanded) &&
            node.type === DocType.report
          ) {
            if (
              node.id === '00000000-0000-0000-0000-000000000000' &&
              node.children.length
            ) {
              this.navigateToDocument(node.children[0], isResultNode)
            } else {
              this.navigateToDocument(node, isResultNode)
            }
          }
        }
      })
  }

  private navigateToDocument(node: TreeNode, isResultNode: boolean): void {
    const path = isResultNode ? '/results' : '/documents'
    if (isResultNode) {
      this.resultFacade.navigateToResultInTree(node.type)
    }
    if (
      node.type === DocType.precis &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/precis/${node.id.toUpperCase()}`)
    }
    if (
      node.type === DocType.precisInProgress &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/precis-in-progress/${node.id.toUpperCase()}`)
    }
    if (
      node.type === DocType.courtDescription &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/description/${node.treeId.toUpperCase()}`, {
        fragment: 'a' + node.id,
      })
    }
    if (
      node.type === DocType.constitution &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/constitution/${node.treeId.toUpperCase()}`, {
        fragment: 'a' + node.id,
      })
    }
    if (
      node.type === DocType.fullText &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/fulltext/${node.id.toUpperCase()}`)
    }

    if (
      node.type === DocType.law &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/law/${node.treeId.toUpperCase()}`, {
        fragment: 'a' + node.id,
      })
    }

    if (
      node.type === DocType.report &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.navigate(`${path}/report/${node.id.toUpperCase()}`)
    }

    if (
      node.type === DocType.introduction &&
      node.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      switch (node.introductionType) {
        case IntroNodeType.liaisonAgent:
          this.navigate(`${path}/liaison-agents`)
          this.documentsFacade.documentDisplayed(node.id)
          break
        case IntroNodeType.url:
          this.navigate(`${path}/intro/${node.id.toUpperCase()}`)
          this.documentsFacade.documentDisplayed(node.id)
          break
        default:
          break
      }
    }
  }

  navigate(path: string, fragment?: NavigationExtras): void {
    if (!path.includes('results')) {
      this.coreSharedFacade.setLastVisitedLink(path)
    }
    this.router.navigate([path], fragment)
  }
}
