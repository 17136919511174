import { FullText } from '@/codices/app/data/models/fulltext.model'
import { ResultIntent } from '@/codices/app/features/results/state/result.state'

export class GetFullText {
  static readonly type = '[FullText] Get fulltext'
  constructor(public id: string | null, public language: string) {}
}

export class GetHtmlText {
  static readonly type = '[FullText] Get html from url'
  constructor(public url: string) {}
}

export class SetHtmlText {
  static readonly type = '[FullText] Set html from url'
  constructor(public htmlText: string) {}
}

export class BookmarkFullText {
  static readonly type = '[FullText] Bookmark a fullText'
  constructor(public fullText: FullText, public language: string) {}
}

export class ChangeLanguage {
  static readonly type = '[FullText] Change fullText language'
  constructor(public selected: string) {}
}

export class HighlightFulltext {
  static readonly type = '[FullText] Highlight fullText '
  constructor(public queryWords: string[], public resultIntent: ResultIntent) {}
}
