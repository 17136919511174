import { TreeNode } from '@/core/types/tree-node.model'

export class GetDocumentsTree {
  static readonly type = '[DocumentsTree] Get Documents Tree'
  constructor(
    public entityType: string | null,
    public rootID: string | null,
    public size: number | null,
  ) {}
}

export class NodeClicked {
  static readonly type = '[DocumentsTree] Node Clicked'
  constructor(
    public node: TreeNode,
    public isExpanded: boolean,
    public tree: TreeNode[],
    public page: number,
    public countryCode: string,
    public size: number,
  ) {}
}

export class NodeCountryClicked {
  static readonly type = '[DocumentsTree] Country Node Clicked'
  constructor(
    public node: TreeNode,
    public isExpanded: boolean,
    public tree: TreeNode[],
    public page: number,
    public countryCode: string,
    public size: number,
  ) {}
}

export class HighlightNode {
  static readonly type = '[DocumentsTree] Highlight Node'
  constructor(public nodeId: string) {}
}

export class ReloadTree {
  static readonly type = '[DocumentsTree] Reload Tree'
}
