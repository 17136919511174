import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { LangFacade } from './services/lang.facade'
import { LangState } from './state/lang.state'

@NgModule({
  declarations: [],
  providers: [LangFacade],
  imports: [NgxsModule.forFeature([LangState])],
})
export class LangModule {}
