<div class="codices-header-menu-container">
  <button [matMenuTriggerFor]="menu" mat-icon-button class="dot-button">
    <mat-icon class="more-dots">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button *ngIf="!isLoggedIn" (click)="connect()" mat-menu-item>
      <span>{{ 'header.liaisonAgents' | translate }}</span>
    </button>
    <button *ngIf="isLoggedIn" (click)="disconnect()" mat-menu-item>
      <span>{{ 'disconnect' | translate }}</span>
    </button>
    <button mat-menu-item (click)="openPrivacyDialog()">
      <span>{{ 'header.confidentiality' | translate }}</span>
    </button>
    <button (click)="openHelp()" mat-menu-item>
      <span>{{ 'header.help' | translate }}</span>
    </button>
  </mat-menu>
</div>
