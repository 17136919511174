import { CoreSharedFacade } from '@/core/features/core-shared/services/core-shared.facade'
import { GeoFacade } from '@/core/features/geo/services/geo.facade'
import { DocType } from '@/core/types/doc-type.enum'
import { UserRole } from '@/core/types/user-role.enum'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { catchError, Observable, of, switchMap, tap, zip } from 'rxjs'
import { ConsentService } from './features/shared/consent/consent.service'
import { MeFacade } from './me/services/me.facade'

export const appInitProviderFactory = (
  provider: AppInitProvider,
): (() => Observable<boolean>) => {
  return () => provider.init()
}

@Injectable()
export class AppInitProvider {
  constructor(
    private geoFacade: GeoFacade,
    private coreSharedfacade: CoreSharedFacade,
    private translate: TranslateService,
    private consent: ConsentService,
    private meFacade: MeFacade,
  ) {}

  init(): Observable<boolean> {
    const url = new URL(window.location.href)
    const urlRedirectFromResult = url
    let languageFromParam = url.searchParams.get('lang')
    const isFulltextPath = url?.pathname.includes(
      DocType.fullText.toLocaleLowerCase(),
    )
    if (urlRedirectFromResult.pathname.includes('/codices/results/')) {
      const activatedRoute = url.pathname.split('#')[0]
      const splitedActivatedRoute = activatedRoute.split('/')

      if (splitedActivatedRoute.length > 3) {
        const entityType = splitedActivatedRoute[3]
        const rootID = splitedActivatedRoute[4]
        urlRedirectFromResult.pathname = `/codices/documents/${entityType.toLocaleLowerCase()}/${rootID}`
        this.coreSharedfacade.setLastVisitedLink(
          `/documents/${entityType.toLocaleLowerCase()}/${rootID}`,
        )
      }
      window.location.href = urlRedirectFromResult.href
    }

    return zip([
      this.geoFacade.fetchLanguages(),
      this.coreSharedfacade.language$,
    ]).pipe(
      switchMap(([, language]) => {
        if (languageFromParam !== null && !isFulltextPath) {
          if (languageFromParam === 'fra') {
            this.translate.setDefaultLang('fra')
            this.coreSharedfacade.changesLanguages('fra')
            this.translate.use('fra')
            languageFromParam = null
          } else {
            this.translate.setDefaultLang('eng')
            this.coreSharedfacade.changesLanguages('eng')
            this.translate.use('eng')
            languageFromParam = null
          }
        } else {
          const navigatorLanguage = navigator.language.split('-')[0]
          if (navigatorLanguage === 'fr') {
            this.translate.setDefaultLang('fra')
            this.coreSharedfacade.changesLanguages('fra')
            this.translate.use('fra')
          } else {
            this.translate.setDefaultLang('eng')
            this.coreSharedfacade.changesLanguages('eng')
            this.translate.use('eng')
          }
          if (language) {
            this.translate.setDefaultLang(language.toLocaleLowerCase())
            this.translate.use(language.toLowerCase())
          }
        }
        this.geoFacade.fetchCountries()
        this.geoFacade.fetchCourtGroups()
        this.consent.initialize()
        const parsedUrl = url.href.split('jwt=')
        if (parsedUrl[1]) {
          const jwt = parsedUrl[1] ?? ''
          this.meFacade.setAccessToken(jwt)
          return this.meFacade.getMe().pipe(
            tap(() => {
              if (this.meFacade.userRoles && !this.meFacade.isFromUnsubscribe) {
                this.meFacade.userRoles.includes(UserRole.LIAISON_OFFICER)
                  ? this.meFacade.goToLiaisonOfficer()
                  : this.meFacade.goToProofreader()
              }
              if (this.meFacade.userRoles && this.meFacade.isFromUnsubscribe) {
                const id = this.meFacade.idUnsubscribe
                this.meFacade.goToUnsubscribe(id)
              }
            }),
            switchMap(() => of(true)),
          )
        }
        return of(true)
      }),
      catchError((error) => {
        console.error(error)
        return of(false)
      }),
    )
  }
}
