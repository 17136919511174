import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { SetCurrentLanguage, SetLastVisitedLink } from './core-shared.action'

export class CoreSharedStateModel {
  language: string
  lastVisitedLink: string
}

@State({
  name: 'coreSharedState',
  defaults: {
    language: 'eng',
    lastVisitedLink: '',
  },
})
@Injectable()
export class CoreSharedState {
  @Selector()
  static selectCodicesLanguage(state: CoreSharedStateModel): any {
    return state.language
  }
  @Selector()
  static selectLastVisitedLink(state: CoreSharedStateModel): any {
    return state.lastVisitedLink
  }

  @Action(SetCurrentLanguage)
  setCodicesLanguage(
    ctx: StateContext<CoreSharedStateModel>,
    { lang }: SetCurrentLanguage,
  ): any {
    ctx.patchState({
      language: lang,
    })
  }

  @Action(SetLastVisitedLink)
  setLastVisitedLink(
    ctx: StateContext<CoreSharedStateModel>,
    { url }: SetLastVisitedLink,
  ): any {
    ctx.patchState({
      lastVisitedLink: url,
    })
  }
}
