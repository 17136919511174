import { CourtDescription } from '@/codices/app/data/models/court-description.model'
import { ResultIntent } from '@/codices/app/features/results/state/result.state'
import { DescriptionViewmodel } from './description.state'

export class GetDescription {
  static readonly type = '[Description] Get a description'
  constructor(public id: string | null) {}
}

export class GetDescriptionViewmodel {
  static readonly type = '[Description] Get a description'
  constructor(public selectedLanguage: string) {}
}

export class SetDescriptionViewmodel {
  static readonly type = '[Description] Set a description'
  constructor(public vm: DescriptionViewmodel) {}
}
export class BookmarkDescription {
  static readonly type = '[Description] Bookmark a description'
  constructor(
    public courtDescription: CourtDescription,
    public language: string,
  ) {}
}

export class HighlightDescription {
  static readonly type = '[Description] highlight description'
  constructor(public queryWords: string[], public resultIntent: ResultIntent) {}
}
