import { Page } from '@/core/types/entities-state-model'

export class AlertsFetchAll {
  static readonly type = '[Alerts] FetchAll'
}

export class AlertsDeleteById {
  static readonly type = '[Alerts] Delete By Id'
  constructor(public id: string) {}
}

export class AlertsChangePage {
  static readonly type = '[Alerts] Change Page'
  constructor(public page: Partial<Page>) {}
}
