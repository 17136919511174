import { Lang } from '@/core/types/lang'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store'
import { LangChangeLang } from './lang.action'

export type LangStateModel = {
  lang: Lang
}

export const LANG_STATE_TOKEN = new StateToken<LangStateModel>('lang')

@State<LangStateModel>({
  name: LANG_STATE_TOKEN,
  defaults: {
    lang: navigator.language.startsWith('fr') ? 'fra' : 'eng',
  },
})
@Injectable()
export class LangState {
  @Selector()
  static lang(state: LangStateModel): Lang {
    return state.lang
  }

  @Action(LangChangeLang)
  changeLang(
    ctx: StateContext<LangStateModel>,
    { lang }: LangChangeLang,
  ): void {
    ctx.patchState({ lang })
  }
}
