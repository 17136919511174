import { BaseEntity } from '@/core/types/base-entity.model'
import {
  EntitiesStateModelWithPage,
  Page,
} from '@/core/types/entities-state-model'
import { createSelector } from '@ngxs/store'

export class EntitiesState {
  static entities<T extends BaseEntity>(): (
    state: EntitiesStateModelWithPage<T>,
  ) => T[] {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.entities
    })
  }
  static entity<T extends BaseEntity>(
    id: string,
  ): (state: EntitiesStateModelWithPage<T>) => T | undefined {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.entities.find((entity) => entity.id === id)
    })
  }

  static totalCount<T extends BaseEntity>(): (
    state: EntitiesStateModelWithPage<T>,
  ) => number {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.totalCount
    })
  }

  static isLoading<T extends BaseEntity>(): (
    state: EntitiesStateModelWithPage<T>,
  ) => boolean {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.isLoading
    })
  }
}

export class EntitiesStateWithPage extends EntitiesState {
  static page<T extends BaseEntity>(): (
    state: EntitiesStateModelWithPage<T>,
  ) => Page {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.page
    })
  }

  static pageIndex<T extends BaseEntity>(): (
    state: EntitiesStateModelWithPage<T>,
  ) => number {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.page.index
    })
  }

  static pageSize<T extends BaseEntity>(): (
    state: EntitiesStateModelWithPage<T>,
  ) => number {
    return createSelector([this], (state: EntitiesStateModelWithPage<T>) => {
      return state.page.size
    })
  }
}
