import { Country, CountryVM } from '@/core/types/country.model'
import { CourtGroup } from '@/core/types/court-group.model'
import { Language } from '@/core/types/language.model'
import { Injectable, inject } from '@angular/core'
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store'
import { Observable, mergeMap, of, tap, withLatestFrom } from 'rxjs'
import { LangState } from '../../lang/state/lang.state'
import { GeoApiService } from '../services/geo-api.service'
import {
  GeoFetchCountries,
  GeoFetchCourtGroups,
  GeoFetchLanguages,
  GeoGenerateCountriesVM,
  GeoGetCountries,
  GeoGetCourtGroups,
  GeoGetLanguages,
} from './geo.action'

export type GeoStateModel = {
  languages: Language[]
  countries: Country[]
  countriesVM: CountryVM[]
  courtGroups: CourtGroup[]
}

export const GEO_STATE_NAME = new StateToken<GeoStateModel>('geo')

@State<GeoStateModel>({
  name: GEO_STATE_NAME,
  defaults: {
    languages: [],
    countries: [],
    countriesVM: [],
    courtGroups: [],
  },
})
@Injectable()
export class GeoState {
  private store = inject(Store)
  private api = inject(GeoApiService)

  @Selector()
  static languages(state: GeoStateModel): Language[] {
    return state.languages
  }

  @Selector()
  static countries(state: GeoStateModel): Country[] {
    return state.countries
  }

  @Selector()
  static countriesVM(state: GeoStateModel): CountryVM[] {
    return state.countriesVM
  }

  @Selector()
  static courtGroups(state: GeoStateModel): CourtGroup[] {
    return state.courtGroups
  }

  @Action(GeoFetchLanguages, { cancelUncompleted: true })
  fetchLanguages(ctx: StateContext<GeoStateModel>): Observable<Language[]> {
    return this.api.getLanguages().pipe(
      tap((res) => {
        ctx.patchState({
          languages: res,
        })
      }),
    )
  }

  @Action(GeoGetLanguages, { cancelUncompleted: true })
  getLanguages(
    ctx: StateContext<GeoStateModel>,
    { revalidate }: GeoGetLanguages,
  ): Observable<void> {
    const { languages } = ctx.getState()

    if (!revalidate && languages.length > 0) {
      // Cache is not empty, no need to fetch
      return of()
    }

    return ctx.dispatch(new GeoFetchLanguages())
  }

  @Action(GeoFetchCountries, { cancelUncompleted: true })
  fetchCountries(ctx: StateContext<GeoStateModel>): Observable<void> {
    return this.api.getCountries().pipe(
      tap((res) => {
        ctx.patchState({
          countries: res,
        })
      }),
      withLatestFrom(this.store.select(LangState.lang)),
      mergeMap(([, lang]) => ctx.dispatch(new GeoGenerateCountriesVM(lang))),
    )
  }

  @Action(GeoGetCountries, { cancelUncompleted: true })
  getCountries(
    ctx: StateContext<GeoStateModel>,
    { revalidate }: GeoGetCountries,
  ): Observable<void> {
    const { countries } = ctx.getState()

    if (!revalidate && countries.length > 0) {
      // Cache is not empty, no need to fetch
      return of()
    }

    return ctx.dispatch(new GeoFetchCountries())
  }

  @Action(GeoFetchCourtGroups)
  fetchCourtGroups(ctx: StateContext<GeoStateModel>): Observable<CourtGroup[]> {
    return this.api.getCourtGroups().pipe(
      tap((res) => {
        ctx.patchState({
          courtGroups: res,
        })
      }),
    )
  }

  @Action(GeoGetCourtGroups, { cancelUncompleted: true })
  getCourtGroups(
    ctx: StateContext<GeoStateModel>,
    { revalidate }: GeoGetCourtGroups,
  ): Observable<void> {
    const { courtGroups } = ctx.getState()

    if (!revalidate && courtGroups.length > 0) {
      // Cache is not empty, no need to fetch
      return of()
    }

    return ctx.dispatch(new GeoFetchCourtGroups())
  }

  @Action(GeoGenerateCountriesVM, { cancelUncompleted: true })
  generateCountriesVM(
    ctx: StateContext<GeoStateModel>,
    { lang }: GeoGenerateCountriesVM,
  ): void {
    const { countries } = ctx.getState()
    const countriesVM = countries.map<CountryVM>((country) => {
      const translation = country.countryTranslation[lang]
      return {
        id: country.id,
        name: translation.name,
        languageCode: translation.languageCode,
        countryCode: country.countryCode,
      }
    })

    ctx.patchState({
      countriesVM,
    })
  }
}
