import { Bookmark } from '@/core/types/bookmark.model'
import { Injectable } from '@angular/core'
import { BookmarksStorageService } from './bookmarks-storage.service'

@Injectable()
export class BookmarksApiService {
  constructor(private bookmarkStorageService: BookmarksStorageService) {}

  getBookmarks(): Bookmark[] {
    const bookmarkStates = this.bookmarkStorageService.getItem('bookmarkState')
    if (bookmarkStates !== null && bookmarkStates !== 'undefined') {
      const bookmarkStatesArr: any = JSON.parse(bookmarkStates)
      return bookmarkStatesArr.bookmarks
    }
    return []
  }
}
