import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, catchError } from 'rxjs'
import { CoreSharedFacade } from '../features/core-shared/services/core-shared.facade'
import {
  AppInjector,
  ENV_TOKEN,
} from '../features/shared/services/app.injector'
import { NotFoundErrors } from '../types/not-found-errors.enum'

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  websiteLanguage = 'eng'
  notFoundErrors = Object.values(NotFoundErrors)
  private environment = inject(ENV_TOKEN)
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const coreSharedFacade = AppInjector?.get(CoreSharedFacade)
    if (coreSharedFacade) {
      const lang = coreSharedFacade.getCodicesLanguage()
      if (lang) {
        this.websiteLanguage = lang.toLowerCase()
      }
    }
    const modifiedReq = request.clone({
      headers: request.headers.set(
        'Accept-Language',
        `${this.websiteLanguage}`,
      ),
    })
    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        const isStaticFileRequest = modifiedReq.url.includes('staticFiles')
        let isDocumentError = false
        this.notFoundErrors.forEach((err) => {
          const docError = error.error?.errors
          if (docError) {
            if (docError[err] && !isDocumentError) {
              isDocumentError = true
            }
          }
        })
        if (
          (!isStaticFileRequest && error.status === 404) ||
          (error.status === 400 && isDocumentError)
        ) {
          const returnUrl = `${window.location.origin}/${this.environment.appName}`
          window.location.href = `${returnUrl}/documents/welcome?not-available-document=true`
        }
        throw error
      }),
    )
  }
}
