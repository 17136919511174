import { Alert } from '@/core/types/alert.model'
import { Page } from '@/core/types/entities-state-model'
import { Injectable, inject } from '@angular/core'
import {
  Actions,
  Select,
  Store,
  ofActionErrored,
  ofActionSuccessful,
} from '@ngxs/store'
import { Observable } from 'rxjs'
import { IEntityWithPageFacade, PageParams } from '../entity.facade'
import { AlertApiService } from './alert.service'
import {
  AlertsChangePage,
  AlertsDeleteById,
  AlertsFetchAll,
} from './state/alert.action'
import { AlertsState } from './state/alert.state'

@Injectable()
export class AlertsFacade implements IEntityWithPageFacade<Alert> {
  /* DI */
  private store = inject(Store)
  private apiService = inject(AlertApiService)
  private actions$ = inject(Actions)

  /* Selectors */
  // Data
  @Select(AlertsState.entities())
  entities$: Observable<Alert[]>

  @Select(AlertsState.totalCount())
  totalCount$: Observable<number>

  @Select(AlertsState.isLoading())
  isLoading$: Observable<boolean>

  // Page
  @Select(AlertsState.page())
  page$: Observable<Page>

  @Select(AlertsState.pageIndex())
  pageIndex$: Observable<number>

  @Select(AlertsState.pageSize())
  pageSize$: Observable<number>

  // Events
  deleteErrored$: Observable<AlertsDeleteById> = this.actions$.pipe(
    ofActionErrored(AlertsDeleteById),
  )

  deleteSuccessful$: Observable<AlertsDeleteById> = this.actions$.pipe(
    ofActionSuccessful(AlertsDeleteById),
  )

  fetchAll(): Observable<void> {
    return this.store.dispatch(new AlertsFetchAll())
  }

  getAlert(label: string): Observable<Alert | undefined> {
    return this.apiService.get(label)
  }

  changePage({ index, size }: PageParams): Observable<void> {
    return this.store.dispatch(new AlertsChangePage({ index, size }))
  }

  createAlert(alert: Partial<Alert>): Observable<Alert> {
    return this.apiService.createAlert(alert)
  }

  deleteAlert(id: string): Observable<void> {
    return this.store.dispatch(new AlertsDeleteById(id))
  }
}
