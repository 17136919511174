import { FullText } from '@/codices/app/data/models/fulltext.model'
import { BookmarksFacade } from '@/codices/app/features/bookmarks/services/bookmarks.facade'
import { GeoFacade } from '@/core/features/geo/services/geo.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  EMPTY,
  Observable,
  catchError,
  map,
  of,
  tap,
  withLatestFrom,
} from 'rxjs'
import { FullTextApiService } from '../services/fulltext-api.service'
import { FulltextService } from '../services/fulltext.service'
import {
  BookmarkFullText,
  ChangeLanguage,
  GetFullText,
  GetHtmlText,
  HighlightFulltext,
  SetHtmlText,
} from './fulltext.action'

export class FulltextStateModel {
  fullText: FullText
  // fullViewmodel: FulltextViewmodel
  languages: LanguageTranslation[]
  isBookmarked: boolean
  selectedLanguage: string
}

export class FullTextStateModel {
  fullText: FullText
  url: string
  htmlText: string
  languages: LanguageTranslation[]
  isBookmarked: boolean
  selectedLanguage: string
}

@State({
  name: 'fullTextState',
  defaults: {
    fullText: null,
    url: '',
    htmlText: '',
    languages: [],
    isBookmarked: false,
    selectedLanguage: '',
  },
})
@Injectable()
export class FullTextState {
  constructor(
    private apiService: FullTextApiService,
    private bookmarkFacade: BookmarksFacade,
    private geoFacade: GeoFacade,
    private service: FulltextService,
  ) {}

  @Selector()
  static selectFullTextLanguages(
    state: FullTextStateModel,
  ): LanguageTranslation[] {
    return state.languages
  }

  @Selector()
  static selectFullText(state: FullTextStateModel): FullText {
    return state.fullText
  }

  @Selector()
  static selectFullTextUrl(state: FullTextStateModel): string {
    return state.url
  }

  @Selector()
  static selectFullTextHtml(state: FullTextStateModel): string {
    return state.htmlText
  }

  @Selector()
  static selectSelectedLanguage(state: FullTextStateModel): string {
    return state.selectedLanguage
  }

  @Action(GetFullText)
  getFulltext(
    ctx: StateContext<FullTextStateModel>,
    { id, language }: GetFullText,
  ): Observable<FullText> {
    return this.apiService.getFullText(id).pipe(
      withLatestFrom(this.bookmarkFacade.bookmarks$, this.geoFacade.countries$),
      map(([fullText, bookmarks, countries]) => {
        const state = ctx.getState()
        let selectedLanguage = language
        const fullTextCopy: FullText = FullText.createCopy(fullText, false)

        if (bookmarks.length > 0) {
          bookmarks.forEach((fullText: any) => {
            if (
              id !== null &&
              fullText.id.toLocaleUpperCase() === id.toLocaleUpperCase()
            ) {
              fullTextCopy.isBookmarked = true
            }
          })
        }
        const fullTextLanguages = this.getBaseLanguages(fullTextCopy)
        const isNotEnOrFr =
          !fullTextCopy.languages.includes('fra') &&
          !fullTextCopy.languages.includes('eng')
        const isFrAndNotEn =
          fullTextCopy.languages.includes('fra') &&
          !fullTextCopy.languages.includes('eng')
        const isEnAndNotFr =
          !fullTextCopy.languages.includes('fra') &&
          fullTextCopy.languages.includes('eng')

        if (isNotEnOrFr) {
          selectedLanguage = ''
        }
        if (isFrAndNotEn) {
          selectedLanguage = 'fra'
        }
        if (isEnAndNotFr) {
          selectedLanguage = 'eng'
        }
        if (
          !fullTextCopy.languages.includes(selectedLanguage) &&
          !isNotEnOrFr
        ) {
          selectedLanguage = fullTextCopy.languages[0]
        }
        const url = fullTextCopy.getUrlFromFullText(fullText, selectedLanguage)
        fullTextCopy.createBreadCrumb(countries, fullTextCopy.country)

        const precisTranslations = new Map(
          Object.entries(fullTextCopy.precis.precisTranslations),
        )
        const precisTranslation = precisTranslations.get(language.toLowerCase())
        if (precisTranslation) {
          fullTextCopy.precisNumber = precisTranslation?.decisionNumber
        } else {
          fullTextCopy.precisNumber = precisTranslations
            ?.entries()
            ?.next()?.value?.decisionNumber
        }

        ctx.patchState({
          ...state,
          fullText: fullTextCopy,
          url: url,
          languages: fullTextLanguages,
          selectedLanguage: selectedLanguage,
        })
        return fullTextCopy
      }),
    )
  }

  @Action(GetHtmlText)
  getHtmlText(
    ctx: StateContext<FullTextStateModel>,
    { url }: GetHtmlText,
  ): Observable<any> {
    if (url !== '') {
      return this.apiService.getHtmlFromUrl(url).pipe(
        tap((htmlText) => {
          const textDecoder = new TextDecoder('ISO-8859-1')
          const text = textDecoder.decode(htmlText)
          const state = ctx.getState()
          ctx.patchState({
            ...state,
            htmlText: text,
          })
        }),
        catchError((err) => {
          const errorText = 'documents.fullText.error'
          const state = ctx.getState()
          ctx.patchState({
            ...state,
            htmlText: errorText,
          })
          return of(err)
        }),
      )
    } else {
      return EMPTY
    }
  }

  @Action(SetHtmlText)
  setHtmlText(
    ctx: StateContext<FullTextStateModel>,
    { htmlText }: SetHtmlText,
  ): void {
    const state = ctx.getState()
    ctx.patchState({
      ...state,
      htmlText,
    })
  }

  @Action(ChangeLanguage)
  changeLanguage(
    ctx: StateContext<FullTextStateModel>,
    { selected }: ChangeLanguage,
  ): void {
    const state = ctx.getState()
    let selectedLang = selected
    if (state.fullText) {
      const fullTextCopy: FullText = FullText.createCopy(
        state.fullText,
        state.fullText?.isBookmarked,
      )

      const translations = new Map(
        Object.entries(fullTextCopy?.fullTextTranslations),
      )
      const fullTextLanguages = this.getBaseLanguages(fullTextCopy)
      const isNotEnOrFr =
        !fullTextCopy.languages.includes('fra') &&
        !fullTextCopy.languages.includes('eng')

      if (!fullTextCopy.languages.includes(selectedLang)) {
        selectedLang = fullTextCopy.languages[0]
        if (isNotEnOrFr) {
          selectedLang = ''
        }
      }
      let url = translations?.get(selectedLang.toLowerCase())?.filePath
      if (selectedLang === '' && state.url !== '') {
        url = state.url
        selectedLang = state.selectedLanguage
      }
      if (selectedLang === '' && state.url === undefined) {
        url = ''
        selectedLang = state.selectedLanguage
      }
      ctx.patchState({
        ...state,
        url: url,
        languages: fullTextLanguages,
        selectedLanguage: selectedLang,
      })
    }
  }

  @Action(BookmarkFullText)
  bookmarkFullText(
    ctx: StateContext<FullTextStateModel>,
    { fullText, language }: BookmarkFullText,
  ): void {
    const state = ctx.getState()
    const fullTextCopy: FullText = FullText.createCopy(
      fullText,
      !fullText?.isBookmarked,
    )
    if (fullTextCopy?.isBookmarked) {
      this.bookmarkFacade.addBookmark(fullTextCopy.toBookmark(language))
    } else {
      this.bookmarkFacade.deleteBookmark(fullTextCopy.id)
    }
    ctx.patchState({
      ...state,
      fullText: {
        ...state.fullText,
        isBookmarked: fullTextCopy.isBookmarked,
      } as FullText,
    })
  }

  @Action(HighlightFulltext)
  highlightFulltext(
    ctx: StateContext<FullTextStateModel>,
    { queryWords, resultIntent }: HighlightFulltext,
  ): void {
    const state = ctx.getState()
    if (queryWords != null && resultIntent != null && queryWords.length > 0) {
      const htmlText: string = this.service.highlight(queryWords)
      ctx.patchState({
        ...state,
        htmlText: htmlText,
      })
    }
  }

  private getBaseLanguages(fulltext: FullText): LanguageTranslation[] {
    const languages: LanguageTranslation[] = []
    fulltext.languages.forEach((fullTextLanguage) => {
      languages.push({
        name: '',
        languageTranslationCode: fullTextLanguage,
        languageCode: '',
      })
    })
    return languages
  }
}
