import { ApiService } from '@/core/features/shared/services/api.service'
import { Alert } from '@/core/types/alert.model'
import { ListResult } from '@/core/types/list-result.model'
import { Injectable, inject } from '@angular/core'
import { Observable, catchError, of } from 'rxjs'
import { EntityApiService } from '../entity-api.service'

const ENDPOINT = 'resultAlert'

@Injectable({
  providedIn: 'root',
})
export class AlertApiService extends EntityApiService<Alert> {
  constructor() {
    super(ENDPOINT)
  }
  private _api = inject(ApiService)

  getAllAlerts(): Observable<ListResult<Alert>> {
    return this._api.get<ListResult<Alert>>(`resultAlert`)
  }

  getAlert(label: string): Observable<Alert | undefined> {
    return this._api
      .get<Alert>(`resultAlert/${label}`)
      .pipe(catchError(() => of(undefined)))
  }

  createAlert(alert: Partial<Alert>): Observable<Alert> {
    return this._api.post<Alert>('resultAlert', alert)
  }

  deleteAlert(id: string): Observable<void> {
    return this._api.delete<void>(`resultAlert/${id}`)
  }
}
