<div class="header-language-container">
  <a [href]="bulletinUrl" class="bulletin-link" target="_blank">
    <img
      alt="Bulletin logo"
      class="bulletin-img"
      src="assets/Bulletin_Page_1.svg"
    />
  </a>
  <a [href]="wccjUrl" target="_blank">
    <img
      alt="WCCJ logo"
      class="WCCJ-img"
      src="assets/logo_WCCJ-modified 2.svg"
    />
  </a>
  <div class="codices-langage">
    <mat-select
      class="lang"
      [(value)]="selectedLanguage"
      (selectionChange)="changeLanguage()"
      matTooltip="{{ selectedLanguage.value | translate }}"
    >
      <mat-option *ngFor="let lang of languages" [value]="lang">
        {{ lang.value | uppercase }}
      </mat-option>
    </mat-select>
  </div>
</div>
