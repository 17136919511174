import { DocType } from '@/core/types/doc-type.enum'
import { plainToClass } from 'class-transformer'

export class IntroductionTranslation {
  title: string
  url: string
  languageCode: string
  id: string
}

export class Introduction {
  id: string
  type: DocType = DocType.introduction
  introductionTranslation: Map<string, IntroductionTranslation>
  level: number
  index: number
  languages: string[]

  static fromJson(obj: any): Introduction {
    return plainToClass(Introduction, obj)
  }
}
