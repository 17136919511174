import { SharedModule } from '@/codices/app/shared.module'
import { SharedPipesModule } from '@/core/pipes/shared-pipes.module'
import { NgModule } from '@angular/core'
import { CodicesHeaderMenuComponent } from './codices-header-menu/codices-header-menu.component'
import { CodicesHeaderComponent } from './codices-header/codices-header.component'
import { CodicesNavHeaderComponent } from './codices-nav-header/codices-nav-header.component'
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component'

@NgModule({
  declarations: [
    CodicesNavHeaderComponent,
    CodicesHeaderComponent,
    CodicesHeaderMenuComponent,
    PrivacyNoticeComponent,
  ],
  imports: [SharedModule, SharedPipesModule],
  exports: [
    CodicesNavHeaderComponent,
    CodicesHeaderMenuComponent,
    CodicesHeaderComponent,
    PrivacyNoticeComponent,
  ],
})
export class MenuModule {}
