import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { MeFacade } from '../me/services/me.facade'

export const canActivateAuthUsers: CanActivateFn = () => {
  const meFacade = inject(MeFacade)
  return meFacade.redirectWhenTokenExp()
}

export const canActivateAuthUnsubscribe: CanActivateFn = () => {
  const unsubscribeId = window.location.pathname.split('/')[3]
  const meFacade = inject(MeFacade)
  meFacade.setIsFromUnsubscribe(true, unsubscribeId)
  return meFacade.redirectWhenUnsubscribe()
}
