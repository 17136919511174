import { FullText } from '@/codices/app/data/models/fulltext.model'
import { environment } from '@/codices/env/environment'
import { ApiService } from '@/core/features/shared/services/api.service'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'

const ENDPOINT = 'fulltext'

@Injectable()
export class FullTextApiService {
  env = environment

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getFullText(id: string | null): Observable<FullText> {
    return this.apiService.get(`${ENDPOINT}/${id}`).pipe(
      map((response: any) => {
        return FullText.fromJson(response)
      }),
    )
  }

  getHtmlFromUrl(url: string): Observable<any> {
    const HTTPOptions: any = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ; charset=UTF-8',
      },
      responseType: 'arraybuffer',
    }
    return this.http.get<any>(
      this.env.apiUrl + '/staticFiles/' + url,
      HTTPOptions,
    )
  }
}
