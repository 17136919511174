import { ApiService } from '@/core/features/shared/services/api.service'
import { LiaisonOfficers } from '@/core/types/liaison-officers.model'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

const ENDPOINT = 'liaison-officers'

@Injectable()
export class LiaisonAgentsApiService {
  constructor(private apiService: ApiService) {}

  getLiaisonAgents(): Observable<LiaisonOfficers[]> {
    return this.apiService
      .get<LiaisonOfficers[]>(`${ENDPOINT}`)
      .pipe(map((value) => value))
  }
}
