import { Law } from '@/codices/app/data/models/law.model'
import { BookmarksFacade } from '@/codices/app/features/bookmarks/services/bookmarks.facade'
import { GeoFacade } from '@/core/features/geo/services/geo.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { TranslationModel } from '@/core/types/translation.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable, map, withLatestFrom } from 'rxjs'
import { LawApiService } from '../services/law-api.service'
import { LawService } from '../services/law.service'
import {
  BookmarkLaw,
  GetLaw,
  GetLawViewmodel,
  HighlightLaw,
  SetLawViewmodel,
} from './law.action'

export class LawStructureTitleAndNote {
  title: string
  note: string
  id: string
}

export class LawViewModel {
  law: TranslationModel
  structure: TranslationModel[]
}

export class LawStateModel {
  law: Law
  lawViewmodel: LawViewModel
  languages: LanguageTranslation[]
  isBookmarked: boolean
  selectedLanguage: string
}

@State({
  name: 'lawState',
  defaults: {
    law: null,
    lawViewmodel: null,
    languages: [],
    isBookmarked: false,
    selectedLanguage: '',
  },
})
@Injectable()
export class LawState {
  constructor(
    private apiService: LawApiService,
    private bookmarkFacade: BookmarksFacade,
    private geoFacade: GeoFacade,
    private service: LawService,
  ) {}

  languageTranslations: LanguageTranslation[] = []
  @Selector()
  static selectLawStateDatas(state: LawStateModel): Law {
    return state.law
  }

  @Selector()
  static selectLawViewModel(state: LawStateModel): LawViewModel {
    return state.lawViewmodel
  }

  @Selector()
  static selectLanguagesDatas(state: LawStateModel): LanguageTranslation[] {
    return state.languages
  }

  @Selector()
  static selectIsLawBookmarkedDatas(state: LawStateModel): boolean {
    return state.isBookmarked
  }

  @Selector()
  static selectSelectedLanguage(state: LawStateModel): string {
    return state.selectedLanguage
  }

  @Action(GetLaw)
  getLaw(ctx: StateContext<LawStateModel>, { id }: GetLaw): Observable<Law> {
    return this.apiService.getLaw(id).pipe(
      withLatestFrom(this.bookmarkFacade.bookmarks$, this.geoFacade.countries$),
      map(([law, bookmarks, countries]) => {
        const state = ctx.getState()
        const lawCopy: Law = Law.createCopy(law, false)

        if (bookmarks.length > 0) {
          bookmarks.forEach((law: any) => {
            if (
              id !== null &&
              law.id.toLocaleUpperCase() === id.toLocaleUpperCase()
            ) {
              lawCopy.isBookmarked = true
            }
          })
        }
        lawCopy.createBreadCrumb(countries, lawCopy.country)

        ctx.patchState({
          ...state,
          law: lawCopy,
        })
        return lawCopy
      }),
    )
  }

  @Action(GetLawViewmodel)
  getLawViewmodel(
    ctx: StateContext<LawStateModel>,
    { selectedLanguage }: GetLawViewmodel,
  ): void {
    const state = ctx.getState()

    if (state.law) {
      const lawCopy: Law = Law.createCopy(state.law, state.law?.isBookmarked)

      const translations = new Map<string, TranslationModel>(
        Object.entries(lawCopy.lawTranslations),
      )

      const isLanguageAvailable = translations.get(selectedLanguage)

      if (!isLanguageAvailable) {
        selectedLanguage = lawCopy.languages[0]
      }

      const viewModel = this.service.getViewModel(selectedLanguage)
      const lawLanguages = this.service.getBaseLanguages()

      ctx.patchState({
        ...state,
        lawViewmodel: viewModel,
        languages: lawLanguages,
        selectedLanguage: selectedLanguage,
      })
    }
  }

  @Action(SetLawViewmodel)
  setLawViewmodel(
    ctx: StateContext<LawStateModel>,
    { vm }: SetLawViewmodel,
  ): void {
    const state = ctx.getState()
    ctx.patchState({
      ...state,
      lawViewmodel: vm,
    })
  }

  @Action(BookmarkLaw)
  bookmarkLaw(
    ctx: StateContext<LawStateModel>,
    { law, language }: BookmarkLaw,
  ): void {
    const state = ctx.getState()
    const lawCopy: Law = Law.createCopy(law, !law.isBookmarked)
    if (lawCopy.isBookmarked) {
      this.bookmarkFacade.addBookmark(lawCopy.toBookmark(language))
    } else {
      this.bookmarkFacade.deleteBookmark(lawCopy.id)
    }
    ctx.patchState({
      ...state,
      law: {
        ...state.law,
        isBookmarked: lawCopy.isBookmarked,
      } as Law,
    })
  }

  @Action(HighlightLaw)
  highlightLaw(
    ctx: StateContext<LawStateModel>,
    { queryWords, resultIntent }: HighlightLaw,
  ): void {
    const state = ctx.getState()
    if (queryWords != null && resultIntent != null && queryWords.length > 0) {
      const vm: LawViewModel = this.service.highlight(queryWords)
      ctx.patchState({
        ...state,
        lawViewmodel: vm,
      })
    }
  }
}
