import { EntitiesState } from '@/core/features/shared/state/entities.state'
import { EntitiesStateModel } from '@/core/types/entities-state-model'
import { Thesaurus, ThesaurusWithFullname } from '@/core/types/thesaurus.model'
import { Injectable, inject } from '@angular/core'
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store'
import { Observable, catchError, mergeMap, of, tap } from 'rxjs'
import { THESAURUS_API_SERVICE_TOKEN } from '../services/thesaurus-api.service'
import { truncateString } from '../services/thesaurus.helpers'
import {
  ThesaurusFetchAll,
  ThesaurusGenerateChildren,
  ThesaurusGetAll,
} from './thesaurus.action'

export type ThesaurusStateModel = EntitiesStateModel<Thesaurus> & {
  children: ThesaurusWithFullname[]
}

export const THESAURUS_STATE_TOKEN = new StateToken<ThesaurusStateModel>(
  'thesaurus',
)

@State<ThesaurusStateModel>({
  name: THESAURUS_STATE_TOKEN,
  defaults: {
    entities: [],
    totalCount: 0,
    isLoading: false,
    children: [],
  },
})
@Injectable()
export class ThesaurusState extends EntitiesState {
  private api = inject(THESAURUS_API_SERVICE_TOKEN)

  @Selector()
  static children(state: ThesaurusStateModel): ThesaurusWithFullname[] {
    return state.children
  }

  @Action(ThesaurusFetchAll, { cancelUncompleted: true })
  fetchAll(ctx: StateContext<ThesaurusStateModel>): Observable<void> {
    ctx.patchState({
      isLoading: true,
    })

    return this.api.getAll().pipe(
      tap((res) => {
        ctx.patchState({
          entities: res,
          totalCount: res.length,
          isLoading: false,
        })
      }),
      mergeMap(() => ctx.dispatch(new ThesaurusGenerateChildren())),
      catchError(() => {
        ctx.patchState({
          isLoading: false,
        })
        return []
      }),
    )
  }

  @Action(ThesaurusGetAll, { cancelUncompleted: true })
  getAll(
    ctx: StateContext<ThesaurusStateModel>,
    { revalidate }: ThesaurusGetAll,
  ): Observable<void> {
    const { entities } = ctx.getState()

    if (!revalidate && entities.length > 0) {
      // Cache is not empty, no need to fetch
      return of()
    }

    return ctx.dispatch(new ThesaurusFetchAll())
  }

  @Action(ThesaurusGenerateChildren)
  generateChildren(ctx: StateContext<ThesaurusStateModel>): void {
    const { entities } = ctx.getState()
    const children = this.flatDeep(entities)
    ctx.patchState({
      children,
    })
  }

  private flatDeep(
    arr: Thesaurus[],
    translation?: ThesaurusWithFullname['thesaurusTranslations'],
  ): ThesaurusWithFullname[] {
    return arr.reduce<ThesaurusWithFullname[]>((acc, curr) => {
      const translations: ThesaurusWithFullname['thesaurusTranslations'] = {}
      const truncatedTranslations: ThesaurusWithFullname['thesaurusTranslations'] =
        {}

      const currIndex = curr.indexNumber
      const currHasChildren =
        Array.isArray(curr.children) && curr.children.length > 0

      for (const t in curr.thesaurusTranslations) {
        const currFullName = translation?.[t]?.fullName
        const currText = curr.thesaurusTranslations[t].text
        translations[t] = {
          ...curr.thesaurusTranslations[t],
          fullName: `${currIndex} ${currFullName ?? ''} ${currText}`,
        }
        truncatedTranslations[t] = {
          ...curr.thesaurusTranslations[t],
          fullName: `${currFullName ?? ''} ${truncateString(currText)} - `,
        }
      }

      acc.push({ ...curr, thesaurusTranslations: translations })

      if (currHasChildren)
        acc = acc.concat(this.flatDeep(curr.children, truncatedTranslations))

      return acc
    }, [])
  }
}
