<div>
  <button
    mat-button
    class="nav-button"
    [routerLink]="['/documents/welcome']"
    [class.active]="isDocButtonActive()"
  >
    {{ 'header.documents' | translate }}
  </button>
  <button
    mat-button
    class="nav-button"
    [routerLink]="['/search']"
    routerLinkActive="active"
  >
    {{ 'header.search' | translate }}
  </button>
  <button
    mat-button
    class="nav-button"
    [routerLink]="['/results']"
    [class.active]="isResultButtonActive()"
    routerLinkActive="active"
  >
    {{ 'header.results' | translate }}
  </button>
  <button
    mat-button
    class="nav-button"
    [routerLink]="['/bookmarks']"
    routerLinkActive="active"
  >
    {{ 'header.favorites' | translate }}
  </button>
  <button
    *ngIf="isButtonVisible(UserRole.LIAISON_OFFICER)"
    mat-button
    class="nav-button"
    [routerLink]="['/user/liaison-agent']"
    routerLinkActive="active"
  >
    {{ 'header.administrate' | translate }}
  </button>
  <button
    *ngIf="isButtonVisible(UserRole.PROOFREADER)"
    mat-button
    class="nav-button"
    [routerLink]="['/user/proofreader']"
    routerLinkActive="active"
  >
    {{ 'header.administrate' | translate }}
  </button>
</div>
