export enum NotFoundErrors {
  courtDescription = 'courtDescriptionId',
  precis = 'precisId',
  intro = 'rootId',
  precisInProgress = 'precisId',
  fullText = 'fullTextId',
  constitution = 'constitutionId',
  law = 'lawId',
  report = 'reportId',
}
