import { EntityApiService } from '@/core/features/shared/services/entity-api.service'
import { EbulletinCount } from '@/core/types/e-bulletin-count.model'
import { Ebulletin } from '@/core/types/e-bulletin.model'
import { Lang } from '@/core/types/lang'
import { ListResult } from '@/core/types/list-result.model'
import { PrecisHistory } from '@/core/types/precis-history.model'
import { PrecisPublicationState } from '@/core/types/precis-publication-state.enum'
import {
  FullTextData,
  Precis,
  PrecisBulkPayload,
  PrecisData,
} from '@/core/types/precis.model'
import { Status } from '@/core/types/status.model'
import { Injectable, InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'
import { Params } from '../../shared/services/api.service'

export const PRECIS_API_SERVICE_TOKEN = new InjectionToken<PrecisApiService>(
  'PrecisApiService',
)

@Injectable({
  providedIn: 'root',
})
export class PrecisApiService extends EntityApiService<Precis, PrecisData> {
  constructor() {
    super('precis')
  }

  getStatusHistory(id: string): Observable<PrecisHistory[]> {
    return this.api.get<PrecisHistory[]>(`precisStatusHistory/${id}`)
  }

  updatePublicationState(
    id: string,
    publicationState: PrecisPublicationState,
  ): Observable<void> {
    return this.api.patch(`precis/publicationState/${id}`, undefined, {
      publicationState,
    })
  }

  getStatutes(): Observable<Status[]> {
    return this.api.get<Status[]>('status')
  }

  submit(precis: PrecisData): Observable<Precis> {
    return this.api.post<Precis>('precis/submit', precis)
  }

  submitUpdateWithId(precis: PrecisData, id: string): Observable<Precis> {
    return this.api.put<Precis>(`precis/${id}/submit`, precis)
  }

  updateWithId(precis: PrecisData, id: string): Observable<Precis> {
    return this.api.put<Precis>(`precis/${id}`, precis)
  }

  bulkUpdate(req: PrecisBulkPayload): Observable<Precis[]> {
    return this.api.put<Precis[]>('precis/bulk', req)
  }

  import(file: File, lang: Lang): Observable<void> {
    const formData = new FormData()
    formData.append('file', file)
    return this.api.post('precis/import', formData, { language: lang })
  }

  importTranslation(
    precisId: string,
    file: File,
    lang: Lang,
  ): Observable<void> {
    const formData = new FormData()
    formData.append('file', file)
    return this.api.post(`precis/import-translation`, formData, {
      precisId: precisId,
      language: lang,
    })
  }

  importFullText(data: FullTextData): Observable<void> {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append(
      'additional_json_file_information',
      JSON.stringify(data.info),
    )
    return this.api.post('fulltext/import', formData)
  }

  exportMultiplePrecis(ids: string[]): Observable<any> {
    return this.api.post('precis/export', ids)
  }

  exportPrecis(id: string, language: string): Observable<any> {
    return this.api.download(`precis/${id}/export`, {
      language: language,
    })
  }

  getForUser(params: Params): Observable<ListResult<Precis>> {
    return this.api.get<ListResult<Precis>>(`precis/user`, params)
  }

  generateEbulletin(ebulletin: Ebulletin): Observable<EbulletinCount> {
    return this.api.post<EbulletinCount>('precis/ebulletin', undefined, {
      id: ebulletin.id,
      type: ebulletin.type,
    })
  }
}
