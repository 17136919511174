import { CodicesTree } from '@/codices/app/data/models/codices-tree.model'
import { DocType } from '@/core/types/doc-type.enum'
import { TreeNode } from '@/core/types/tree-node.model'

export class LoadResultsTree {
  static readonly type = '[ResultsTree] Load Results Tree'
  constructor(public tree: CodicesTree) {}
}

export class ResultNodeClicked {
  static readonly type = '[ResultsTree] Node Clicked'
  public isResultNode = true
  constructor(
    public node: TreeNode,
    public isExpanded: boolean,
    public tree: TreeNode[],
    public page: number,
    public countryCode: string,
    public size: number,
  ) {}
}

export class ResultNodeCountryClicked {
  static readonly type = '[ResultsTree] Country Node Clicked'
  constructor(
    public node: TreeNode,
    public isExpanded: boolean,
    public tree: TreeNode[],
    public page: number,
    public countryCode: string,
    public size: number,
  ) {}
}

export class ResultHighlightNode {
  static readonly type = '[ResultsTree] Highlight Node'
  constructor(public nodeId: string) {}
}

export class SetPreloadResultTree {
  static readonly type = '[ResultsTree] Set new tree for results'
  constructor(public nodeId: string, public docType: DocType) {}
}
