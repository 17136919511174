import { Country, CountryVM } from '@/core/types/country.model'
import { CourtGroup } from '@/core/types/court-group.model'
import { Language } from '@/core/types/language.model'
import { Injectable, inject } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable, map, withLatestFrom } from 'rxjs'
import { LangFacade } from '../../lang/services/lang.facade'
import {
  GeoFetchCountries,
  GeoFetchCourtGroups,
  GeoFetchLanguages,
  GeoGetCountries,
  GeoGetCourtGroups,
  GeoGetLanguages,
} from '../state/geo.action'
import { GeoState } from '../state/geo.state'

/**
 * Facade for the geo feature
 * Can be used in other features to access languages, countries, court groups
 */
@Injectable({
  providedIn: 'root',
})
export class GeoFacade {
  /* DI */
  private store = inject(Store)
  private langFacade = inject(LangFacade)

  /* Selectors */
  /**
   * Selects the list of languages from the state
   * @returns The list of languages
   */
  @Select(GeoState.languages)
  languages$: Observable<Language[]>

  /**
   * Selects the list of countries from the state
   * @returns The list of countries
   */
  @Select(GeoState.countries)
  countries$: Observable<Country[]>

  /**
   * Selects the list of countries with view model from the state
   * @returns The list of countries with view model
   */
  @Select(GeoState.countriesVM)
  countriesVM$: Observable<CountryVM[]>

  /**
   * Selects the list of court groups from the state
   * @returns The list of court groups
   */
  @Select(GeoState.courtGroups)
  courtGroups$: Observable<CourtGroup[]>

  /* Actions */
  /**
   * Fetches languages from the API and updates the state
   * @returns The list of languages
   */
  fetchLanguages(): Observable<Language[]> {
    return this.store.dispatch(new GeoFetchLanguages()).pipe(
      withLatestFrom(this.languages$),
      map(([, languages]) => languages),
    )
  }

  /**
   * Fetches countries from the API and updates the state
   * @returns The list of countries
   */
  fetchCountries(): Observable<Country[]> {
    return this.store.dispatch(new GeoFetchCountries()).pipe(
      withLatestFrom(this.countries$),
      map(([, countries]) => countries),
    )
  }

  /**
   * Fetches court groups from the API and updates the state
   * @returns The list of court groups
   */
  fetchCourtGroups(): Observable<CourtGroup[]> {
    return this.store.dispatch(new GeoFetchCourtGroups()).pipe(
      withLatestFrom(this.courtGroups$),
      map(([, courtGroups]) => courtGroups),
    )
  }

  /**
   * Gets languages from the state (cache) or fetches them from the API
   * @param revalidate Whether to fetch the languages from the API
   * @returns The list of languages
   */
  getLanguages(revalidate = false): Observable<Language[]> {
    return this.store.dispatch(new GeoGetLanguages(revalidate)).pipe(
      withLatestFrom(this.languages$),
      map(([, languages]) => languages),
    )
  }

  /**
   * Gets countries from the state (cache) or fetches them from the API
   * @param revalidate Whether to fetch the countries from the API
   * @returns The list of countries
   */
  getCountries(revalidate = false): Observable<Country[]> {
    return this.store.dispatch(new GeoGetCountries(revalidate)).pipe(
      withLatestFrom(this.countries$),
      map(([, countries]) => countries),
    )
  }

  /**
   * Gets court groups from the state (cache) or fetches them from the API
   * @param revalidate Whether to fetch the court groups from the API
   * @returns The list of court groups
   */
  getCourtGroups(revalidate = false): Observable<CourtGroup[]> {
    return this.store.dispatch(new GeoGetCourtGroups(revalidate)).pipe(
      withLatestFrom(this.courtGroups$),
      map(([, courtGroups]) => courtGroups),
    )
  }

  /**
   * Gets the name of a country in the current language or the first available language
   * @param country The country
   * @returns The name of the country
   */
  getCountryName(country: Country): string {
    const name = country.countryTranslation[this.langFacade.lang]?.name
    return name ? name : Object.values(country.countryTranslation)[0].name
  }
}
