import { ApiService } from '@/core/features/shared/services/api.service'
import { Constitution } from '@/core/types/constitution.model'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

const endpoint = 'constitution'

@Injectable()
export class ConstitutionApiService {
  constructor(private apiService: ApiService) {}

  getConstitution(id: string | null, date: Date): Observable<Constitution> {
    return this.apiService.get(`${endpoint}/${id}`).pipe(
      map((value) => {
        return Constitution.fromJson(value)
      }),
    )
  }
}
