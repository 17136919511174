import { ENV_TOKEN } from '@/core/features/shared/services/app.injector'
import { UserRole } from '@/core/types/user-role.enum'
import { User } from '@/core/types/user.model'
import { Injectable, inject } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { jwtDecode } from 'jwt-decode'
import * as moment from 'moment'
import { Observable, take } from 'rxjs'
import {
  GetMe,
  SetAccessToken,
  SetIsFromUnsubscribe,
  SetMe,
} from '../state/me.action'
import { MeState } from '../state/me.state'

@Injectable({
  providedIn: 'root',
})
export class MeFacade {
  private store = inject(Store)
  private environment = inject(ENV_TOKEN)
  @Select(MeState.me)
  me$: Observable<User | null>

  @Select(MeState.userRoles)
  userRoles$: Observable<UserRole[] | null>

  @Select(MeState.isFromUnsubscribe)
  isFromUnsubscribe$: Observable<boolean>

  @Select(MeState.idUnsubscribe)
  idUnsubscribe$: Observable<string>

  get me(): User | null {
    return this.store.selectSnapshot(MeState.me)
  }

  get userRoles(): UserRole[] | null {
    return this.store.selectSnapshot(MeState.userRoles)
  }

  get accessToken(): string {
    return this.store.selectSnapshot(MeState.accessToken)
  }

  get isFromUnsubscribe(): boolean {
    return this.store.selectSnapshot(MeState.isFromUnsubscribe)
  }

  get idUnsubscribe(): string {
    return this.store.selectSnapshot(MeState.idUnsubscribe)
  }

  isLoggedIn(): boolean {
    return this.store.selectSnapshot(MeState.isLoggedIn)
  }

  setAccessToken(token: string): void {
    this.store.dispatch(new SetAccessToken(token))
  }

  getMe(): Observable<void> {
    return this.store.dispatch(new GetMe())
  }

  setMe(user: User | null): Observable<void> {
    return this.store.dispatch(new SetMe(user))
  }

  setIsFromUnsubscribe(
    isFromUnsubscribe: boolean,
    idUnsubscribe: string,
  ): Observable<void> {
    return this.store.dispatch(
      new SetIsFromUnsubscribe(isFromUnsubscribe, idUnsubscribe),
    )
  }

  isUserAllowed(site: 'codices' | 'backoffice'): boolean {
    let isAllowed = false
    this.userRoles$.pipe(take(1)).subscribe((userRoles) => {
      const roles = userRoles
      if (roles) {
        isAllowed =
          site === 'backoffice' && roles
            ? roles.includes(UserRole.ADMIN)
            : roles.includes(UserRole.LIAISON_OFFICER) ||
              roles.includes(UserRole.PROOFREADER)
      }
    })
    return isAllowed
  }

  disconnect(): void {
    this.setAccessToken('')
    this.setMe(null)
    this.redirectLogout()
  }

  goToLiaisonOfficer(): void {
    const returnUrl = `${window.location.origin}/${this.environment.appName}`
    window.location.href = `${returnUrl}/user/liaison-agent`
  }

  goToProofreader(): void {
    const returnUrl = `${window.location.origin}/${this.environment.appName}`
    window.location.href = `${returnUrl}/user/proofreader`
  }

  redirectCodices(isNotAllowed = false): void {
    const returnUrl = `${window.location.origin}/${this.environment.appName}`
    window.location.href = isNotAllowed
      ? `${returnUrl}/documents/welcome?not-allowed-role=true`
      : `${returnUrl}/documents/welcome`
  }

  redirectCass(): void {
    const returnUrl = `${window.location.origin}/${this.environment.appName}`
    window.location.href = `${this.environment.apiUrl}/saml/login?returnUrl=${returnUrl}`
  }

  redirectLogout(): void {
    window.location.href = this.environment.disconnectCasUrl
  }

  redirectWhenTokenExp(): boolean {
    if (this.accessToken !== '') {
      const decodedJwt: any = jwtDecode(this.accessToken)
      const expirationDate = moment(decodedJwt.exp * 1000)
        .local()
        .valueOf()
      if (expirationDate < moment().local().valueOf()) {
        this.handleRedirectToCass()
        return false
      }
    }
    if (!this.isUserAllowed('codices')) {
      this.setAccessToken('')
      this.redirectCodices(true)
      return false
    }
    return true
  }

  redirectWhenUnsubscribe(): boolean {
    if (this.accessToken !== '') {
      const decodedJwt: any = jwtDecode(this.accessToken)
      const expirationDate = moment(decodedJwt.exp * 1000)
        .local()
        .valueOf()
      if (expirationDate < moment().local().valueOf()) {
        this.handleRedirectToCass()
        return false
      }
    }
    if (this.isUserAllowed('backoffice')) {
      this.setAccessToken('')
      this.redirectCodices(true)
      return false
    }
    if (!this.isUserAllowed('codices')) {
      this.handleRedirectToCass()
      return false
    }
    return true
  }

  goToUnsubscribe(id: string): void {
    const returnUrl = `${window.location.origin}/${this.environment.appName}`
    window.location.href = `${returnUrl}/alert/${id}/unsubscribe`
  }

  private handleRedirectToCass(): void {
    this.setAccessToken('')
    this.redirectCass()
  }
}
