import { CodicesTree } from '@/codices/app/data/models/codices-tree.model'
import { TreeHelper } from '@/codices/app/features/shared/tree/services/tree.helper'
import { TreesApiService } from '@/codices/app/features/shared/tree/services/trees-api.service'
import { DocType } from '@/core/types/doc-type.enum'
import { TreeNode } from '@/core/types/tree-node.model'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { EMPTY, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import {
  LoadResultsTree,
  ResultHighlightNode,
  ResultNodeClicked,
  ResultNodeCountryClicked,
  SetPreloadResultTree,
} from './results-tree.action'

export enum ResultsTreeIntent {
  reloadTree = 'reloadTree',
  highlightNode = 'highlightNode',
  clearHighlight = 'clearHighlight',
}

export class ResultsTreeStateModel {
  treeNodes: TreeNode[]
  treeIntent: { intent: ResultsTreeIntent; index: string }
}

@State({
  name: 'resultsTreeState',
  defaults: {
    treeNodes: [],
    treeIntent: null,
  },
})
@Injectable()
export class ResultsTreeState {
  constructor(
    private store: Store,
    private service: TreesApiService,
    private router: Router,
  ) {}

  @Selector()
  static selectResultsTreeNodes(state: ResultsTreeStateModel): TreeNode[] {
    return state.treeNodes
  }

  @Selector()
  static selectTreeIntent(state: ResultsTreeStateModel): {
    intent: ResultsTreeIntent
    index: string
  } {
    return state.treeIntent
  }

  @Action(LoadResultsTree)
  getResultsTree(
    ctx: StateContext<ResultsTreeStateModel>,
    { tree }: LoadResultsTree,
  ): void {
    const instance = CodicesTree.fromJson(tree)
    ctx.patchState({
      treeNodes: instance.toArrayNoIntro(),
    })
  }

  @Action(ResultNodeClicked)
  nodeClicked(
    ctx: StateContext<ResultsTreeStateModel>,
    { node, isExpanded, tree, page, countryCode, size }: ResultNodeClicked,
  ): Observable<TreeNode> {
    // Logic 1: User clicked on 'more' node
    if (node.type === DocType.more && node.parent) {
      return this.doMoreButtonCLicked(ctx, node, tree, page, countryCode, size)
    }
    // Logic 2: User clicked on country node (Laws, Constitutions, Descriptions...)
    if (isExpanded && node.countryCode && node.children.length === 0) {
      this.addIsLoadingNode(ctx, node)
      return this.loadMoreResults(ctx, node, tree, page, countryCode, size)
    }

    return EMPTY
  }

  @Action(ResultNodeCountryClicked)
  nodeCountryClicked(
    ctx: StateContext<ResultsTreeStateModel>,
    {
      node,
      isExpanded,
      tree,
      page,
      countryCode,
      size,
    }: ResultNodeCountryClicked,
  ): any {
    if (
      node &&
      node.countryCode &&
      node.type !== DocType.loading &&
      node.children.length
    ) {
      this.nodeClicked(
        ctx,
        new ResultNodeClicked(
          node.children[0],
          isExpanded,
          tree,
          page,
          countryCode,
          size,
        ),
      )
    }
  }

  @Action(ResultHighlightNode)
  highlightNode(
    ctx: StateContext<ResultsTreeStateModel>,
    { nodeId }: ResultHighlightNode,
  ): void {
    ctx.patchState({
      treeIntent: {
        intent: ResultsTreeIntent.highlightNode,
        index: nodeId,
      },
    })
  }

  @Action(SetPreloadResultTree)
  setPreloadResultTree(
    ctx: StateContext<ResultsTreeStateModel>,
    { nodeId, docType }: SetPreloadResultTree,
  ): Observable<any> {
    const rootID = nodeId
    // TODO: sortir la taille de la pagination dans env
    const size = 20
    return this.service.getResultTreeSpecificNode(docType, rootID, size).pipe(
      tap((resultTreeNode: TreeNode) => {
        const state = ctx.getState()
        const resultTreeCopy: TreeNode[] = JSON.parse(
          JSON.stringify(state.treeNodes),
        )
        const nodesArray = resultTreeNode.children
        let updatedResultTree: TreeNode[] = []
        const node = TreeHelper.findNode(
          nodesArray,
          (n: TreeNode): boolean => n.id === rootID,
        )
        if (node) {
          const n = TreeHelper.findNodeParents(
            nodesArray,
            (n: TreeNode): boolean => n.id === node.id,
          )
          if (updatedResultTree.length === 0) {
            updatedResultTree = this.addChildResults(resultTreeCopy, n[0])
          }
        }

        if (updatedResultTree.length > 0) {
          ctx.patchState({
            treeNodes: updatedResultTree,
          })
        }
      }),
    )
  }

  private doMoreButtonCLicked(
    ctx: StateContext<ResultsTreeStateModel>,
    node: TreeNode,
    tree: TreeNode[],
    page: number,
    countryCode: string,
    size: number,
  ): Observable<TreeNode> {
    const nodeCopy = Object.assign({}, node)
    const parent = TreeHelper.findNode(tree, TreeHelper.findNodeFct(nodeCopy))
    if (parent && nodeCopy.countryCode === parent.countryCode) {
      node.toLoadingNode()
      return this.loadMoreResults(ctx, parent, tree, page, countryCode, size)
    }
    return EMPTY
  }

  private loadMoreResults(
    ctx: StateContext<ResultsTreeStateModel>,
    node: TreeNode,
    tree: TreeNode[],
    page: number,
    countryCode: string,
    size: number,
  ): Observable<TreeNode> {
    return this.service
      .getNodeChildren(node.type, page, countryCode, size)
      .pipe(
        tap((resultNode: TreeNode) => {
          // Merge new children to node
          node.mergeFromUpdatedNode(resultNode)
          // Remove loading node
          node.removeIsLoadingFromChildren()
          // If node has children, select first child
          if (node.children.length) {
            this.store.dispatch(
              new ResultNodeClicked(
                node.children[0],
                true,
                tree,
                page,
                countryCode,
                size,
              ),
            )
          }
          // Reload tree
          ctx.patchState({
            treeIntent: {
              intent: ResultsTreeIntent.reloadTree,
              index: crypto.randomUUID(),
            },
          })
        }),
      )
  }

  private addChildResults(
    resultTree: TreeNode[],
    countryNode: TreeNode,
  ): TreeNode[] {
    return resultTree.map((resultTreeNode) => {
      if (
        resultTreeNode.id === '00000000-0000-0000-0000-000000000000' &&
        resultTreeNode.countryCode === countryNode.countryCode &&
        resultTreeNode.type === countryNode.type
      ) {
        resultTreeNode.children = countryNode.children
      } else if (resultTreeNode.children.length > 0) {
        this.addChildResults(resultTreeNode.children, countryNode)
      }
      return resultTreeNode
    })
  }

  private addIsLoadingNode(
    ctx: StateContext<ResultsTreeStateModel>,
    node: TreeNode,
  ): void {
    node.children.push(TreeNode.getLoadingNode(node))
    ctx.patchState({
      treeIntent: {
        intent: ResultsTreeIntent.reloadTree,
        index: crypto.randomUUID(),
      },
    })
  }
}
