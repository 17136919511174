import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable, take, tap, withLatestFrom } from 'rxjs'

import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import {
  CleanHighlightIds,
  GetTaxon,
  GoToNextWord,
  GoToPreviousWord,
  SetHighlightIds,
  SetQueryWords,
} from '../state/highlight.action'
import { HighlightState } from '../state/highlight.state'
import { HighlightService } from './highlight.service'

@Injectable()
export class HighlightFacade {
  ids: string[] = []

  constructor(private store: Store, private service: HighlightService) {}

  @Select(HighlightState.selectCurrentWord)
  currentWordId$: Observable<string>

  @Select(HighlightState.selectIndexCurrentWord)
  currentWordIndex$: Observable<number>

  @Select(HighlightState.selectIds)
  ids$: Observable<string[]>

  @Select(HighlightState.selectQueryWords)
  queryWords$: Observable<string[]>

  @Select(HighlightState.selectFirstNext)
  isFirstNext$: Observable<boolean>

  @Select(HighlightState.selectTaxon)
  taxon$: Observable<TaxonDto>

  @Select(HighlightState.selectHighlightType)
  highlightType$: Observable<SearchTypes>

  public setQueryWords(
    queryWords: string[],
    hightlightType: SearchTypes,
  ): void {
    this.store.dispatch(new SetQueryWords(queryWords, hightlightType))
  }

  public getTaxon(id: string | null): void {
    this.store.dispatch(new GetTaxon(id))
  }

  public setHighlightIds(ids: string[]): void {
    this.store.dispatch(new SetHighlightIds(ids))
  }

  public cleanHighlightIds(): void {
    this.store.dispatch(new CleanHighlightIds())
  }

  public goToNextWord(id: string, index: number, isFirstNext: boolean): void {
    this.store.dispatch(new GoToNextWord(id, index, isFirstNext))
  }

  public goToPreviousWord(id: string, index: number): void {
    this.store.dispatch(new GoToPreviousWord(id, index))
  }

  public highlightText(words: string[], text: string): string {
    return this.service.highlightText(this.ids, words, text)
  }

  highlightFirstWord(): Observable<any> {
    return this.ids$.pipe(
      withLatestFrom(this.currentWordIndex$, this.isFirstNext$),
      take(1),
      tap(([highlightIds, index, isFirstNext]) => {
        if (index === 0 && isFirstNext) {
          const element = document.getElementById(highlightIds[0])
          if (element) {
            element.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            })
            element.style.background = 'rgba(255,128,0,0.7)'
          }
          this.goToNextWord('', 0, false)
        }
      }),
    )
  }
}
