import { ConstitutionNode } from '@/core/types/constitution-nodes.model'
import { DocTypes } from '@/core/types/doc-types.enum'
import { TranslationModel } from '@/core/types/translation.model'
import { plainToClass } from 'class-transformer'
import { BaseDocumentClass } from './base-document-class.model'
import { Bookmark } from './bookmark.model'
import { Bookmarkable } from './bookmarkable.inteface'

export class Constitution extends BaseDocumentClass implements Bookmarkable {
  order: number
  referenceCode: string
  version: number
  isReferenceable: boolean
  validityDate: Date
  expirationDate: Date
  nodes: ConstitutionNode[]
  constitutionTranslations: Record<string, TranslationModel>
  languages: string[]
  country: string
  docType: string
  isFinalized: boolean
  isLastVersion: boolean
  id: string
  constitutionId: string
  createdBy: string
  createdDate: Date
  lastModifiedBy: string
  lastModifiedDate: Date
  isSoftDeleted: boolean
  deletedDate: Date
  bookmarkOrder: number
  isBookmarked: boolean

  createBookmarkTitle(language: string): string {
    const translations = this.constitutionTranslations
    const constTranslation = translations[language]
    if (constTranslation) {
      return constTranslation.title
    } else {
      return translations[Object.keys(translations)[0]].title
    }
  }

  toBookmark(language: string): Bookmark {
    return new Bookmark(
      this.constitutionId,
      'CONSTITUTION',
      this.createBookmarkTitle(language),
      this.bookmarkOrder,
      this.breadCrumb,
    )
  }

  static fromJson(obj: any): Constitution {
    return plainToClass(Constitution, obj)
  }

  static initialConstitution(): Constitution {
    const constitution = new Constitution()
    constitution.constitutionTranslations = {}
    constitution.constitutionTranslations['fra'] = {
      languageCode: 'fra',
      title: 'Titre de la constitution',
    } as TranslationModel
    constitution.constitutionTranslations['eng'] = {
      languageCode: 'eng',
      title: 'Constitution title',
    } as TranslationModel
    constitution.languages = ['fra', 'eng']
    constitution.docType = DocTypes.constitution
    return constitution
  }

  static createCopy(
    constitution: Constitution,
    isBookmarked: boolean,
  ): Constitution {
    const constitutionCopy: Constitution = Constitution.fromJson(
      Object.assign({}, Constitution.fromJson(constitution)),
    )
    constitutionCopy.isBookmarked = isBookmarked

    return constitutionCopy
  }
}
