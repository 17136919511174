import { Injectable } from '@angular/core'

@Injectable()
export class HighlightAdvancedSearchService {
  highlightTextAdvancedSearch(
    noBooleanText: string,
    text: string,
    queryWords: string[],
  ): string[] {
    if (text.includes('"')) {
      const searchWithQuotesRegex = text.match(/\w+|"[^"]+"/g)
      let searchWithQuotes: string[] = []
      if (searchWithQuotesRegex !== null) {
        searchWithQuotes.push(...searchWithQuotesRegex)
        searchWithQuotes = searchWithQuotes?.map((searchWithQuote) => {
          searchWithQuote = this.booleanTextSearch(searchWithQuote)
          return searchWithQuote.replace(/"/g, '')
        })
        queryWords = searchWithQuotes
      }
    } else {
      queryWords.push(...noBooleanText.split(' '))
      if (queryWords.length > 1) {
        queryWords.unshift(noBooleanText)
      }
    }
    return queryWords
  }

  booleanTextSearch(text: string): string {
    if (!text.includes('"')) {
      const textWithoutBoolean = text
        .replace(/\band\b/gi, '')
        .replace(/\bnot\b/gi, '')
        .replace(/\bor\b/gi, '')
        .replace(/&/gi, '')
        .replace(/\|/gi, '')
        .replace(/\b&\b/gi, '')
        .replace(/\(/gi, '')
        .replace(/\)/gi, '')
      return textWithoutBoolean
    }
    return text
  }
}
