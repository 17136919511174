import { Introduction } from '@/codices/app/data/models/introduction'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IntroductionApiService } from './intro-api.service'

@Injectable()
export class IntroductionFacade {
  constructor(public introApiservice: IntroductionApiService) {}
  getIntroduction(id: string): Observable<Introduction> {
    return this.introApiservice.getIntroduction(id)
  }

  getIntroductionText(url: string | undefined): Observable<string> {
    return this.introApiservice.getIntroductionText(url)
  }
}
