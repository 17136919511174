import { Law } from '@/codices/app/data/models/law.model'
import { ApiService } from '@/core/features/shared/services/api.service'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'

const endpoint = 'law'

@Injectable()
export class LawApiService {
  constructor(private apiService: ApiService) {}

  getLaw(id: string | null): Observable<Law> {
    return this.apiService
      .get(`${endpoint}/${id}`)
      .pipe(map((value) => Law.fromJson(value)))
  }
}
