import { Law } from '@/codices/app/data/models/law.model'
import { ResultIntent } from '@/codices/app/features/results/state/result.state'
import { DocType } from '@/core/types/doc-type.enum'
import { LawViewModel } from './law.state'

export class GetLaw {
  static readonly type = '[Law] Get a law'
  constructor(public id: string | null) {}
}

export class GetLawViewmodel {
  static readonly type = '[Law] Get a law viewmodel'
  constructor(
    public selectedLanguage: string,
    public currentDisplayedResultType?: DocType,
    public queryWords?: string[],
    public resultIntent?: ResultIntent,
  ) {}
}

export class SetLawViewmodel {
  static readonly type = '[Law] Set a law viewmodel'
  constructor(public vm: LawViewModel) {}
}
export class BookmarkLaw {
  static readonly type = '[Law] Bookmark a law'
  constructor(public law: Law, public language: string) {}
}

export class GetLawStructureNote {
  static readonly type = '[Law] Get note of a law structure'
  constructor(public law: Law, public language: string) {}
}

export class GetSelectedLanguage {
  static readonly type = '[Law] Get selected language of a law structure'
}

export class HighlightLaw {
  static readonly type = '[Law] Highlight law'
  constructor(public queryWords: string[], public resultIntent: ResultIntent) {}
}
