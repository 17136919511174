import {
  CountryFilter,
  Result,
  SearchResult,
  ThesaurusFilter,
} from '@/codices/app/data/models/result.model'
import { SearchBookmark } from '@/codices/app/data/models/search-bookmark.model'
import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { BookmarksFacade } from '@/codices/app/features/bookmarks/services/bookmarks.facade'
import { DocType } from '@/core/types/doc-type.enum'
import { SearchModel } from '@/core/types/search.model'
import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { of } from 'rxjs'
import { withLatestFrom } from 'rxjs/operators'
import { AdvancedSearch } from '../../search/state/search.action'
import { NodeTypeHelper } from '../../shared/tree/model/node.helper'
import { ResultsFacade } from '../services/results.facade'
import {
  BookmarkResults,
  CheckResultCountry,
  CheckThesaurus,
  GoNextDocument,
  GoPreviousDocument,
  NavigateToResult,
  NavigateToResultInTree,
  ResetCurrentDisplayedResultType,
  ResetIntent,
  ReturnToResultList,
  SetAdvancedResults,
  SetAlphaResults,
  SetArticleResults,
  SetCurrentDisplayedResult,
  SetCurrentDisplayedResultType,
  VerifyBookmarkResult,
} from './result.action'

export enum ResultIntent {
  showIsFirstResult,
  showIsLastResult,
  countryFilterResults,
  thesaurusFilterResults,
  navigateToResultItem,
}

export enum NavigateAfterGettingResultTo {
  default,
  nextResult,
  previousResult,
  resultsList,
}

export class ResultsStateModel {
  results: Result
  alphaIndexId: string
  taxonId: string
  articleId: string
  searchModel: SearchModel
  totalResults: number
  size: number
  page: number
  searchType: SearchTypes
  currentDisplayedResult: string
  currentDisplayedResultType: DocType
  intent: { intent: ResultIntent; index: string } | null
  isLoading: boolean
}

@State({
  name: 'resultState',
  defaults: {
    results: undefined,
    alphaIndexId: '',
    taxonId: '',
    articleId: '',
    searchModel: undefined,
    totalResults: 0,
    searchType: null,
    currentDisplayedResult: '',
    intent: null,
    isLoading: false,
  },
})
@Injectable()
export class ResultState {
  navigateTo: NavigateAfterGettingResultTo

  constructor(
    private bookmarkFacade: BookmarksFacade,
    private resultFacade: ResultsFacade,
    private store: Store,
    private router: Router,
  ) {}

  @Selector()
  static selectResultStateDatas(state: ResultsStateModel): any {
    return state.results
  }
  @Selector()
  static selectAlphaSearchModel(state: ResultsStateModel): any {
    return {
      alphaIndexId: state.alphaIndexId,
      taxonId: state.taxonId,
      page: state.page,
      size: state.size,
    }
  }
  @Selector()
  static selectArticleSearchModel(state: ResultsStateModel): any {
    return {
      articleId: state.articleId,
      page: state.page,
      size: state.size,
    }
  }
  @Selector()
  static selectSearchModel(state: ResultsStateModel): any {
    return state.searchModel
  }
  @Selector()
  static selectTotalResults(state: ResultsStateModel): any {
    return state.totalResults
  }
  @Selector()
  static selectSearchType(state: ResultsStateModel): any {
    return state.searchType
  }
  @Selector()
  static selectIntent(state: ResultsStateModel): any {
    return state.intent
  }
  @Selector()
  static selectCurrentDisplayedResultType(state: ResultsStateModel): any {
    return state.currentDisplayedResultType
  }
  @Selector()
  static selectIsLoading(state: ResultsStateModel): any {
    return state.isLoading
  }

  @Action(SetAdvancedResults)
  setAdvancedResults(
    resultsStateContext: StateContext<ResultsStateModel>,
    { results, searchModel, searchType }: SetAdvancedResults,
  ): any {
    const state = resultsStateContext.getState()
    const resultsCopy: Result = JSON.parse(JSON.stringify(results))
    const totalResults = this.setResultsCountAndFilters(
      resultsCopy,
      searchModel,
      state,
    )
    const newState = {
      ...state,
      intent: null,
      results: resultsCopy,
      searchModel: searchModel,
      searchType: searchType,
      totalResults: totalResults,
    }
    if (state.results) {
      const existingResults: Result = JSON.parse(JSON.stringify(state.results))
      this.setExtistingResult(existingResults, results, resultsCopy, state)
      newState.results = existingResults
    }

    resultsStateContext.setState(newState)

    if (this.navigateTo === NavigateAfterGettingResultTo.nextResult) {
      this.navigateTo = NavigateAfterGettingResultTo.default
      this.goNextDocument(resultsStateContext)
    } else if (
      this.navigateTo === NavigateAfterGettingResultTo.previousResult
    ) {
      this.navigateTo = NavigateAfterGettingResultTo.default
      this.goPreviousDocument(resultsStateContext)
    } else if (this.navigateTo === NavigateAfterGettingResultTo.resultsList) {
      this.navigateTo = NavigateAfterGettingResultTo.default
      this.router.navigateByUrl('/results')
    }
  }

  @Action(SetAlphaResults)
  setAlphaResults(
    resultsStateContext: StateContext<ResultsStateModel>,
    { results, alphaId, taxonId, page, size, searchType }: SetAlphaResults,
  ): any {
    const state = resultsStateContext.getState()
    const resultsCopy: Result = JSON.parse(JSON.stringify(results))
    const totalResults = this.setResultsCountAndFilters(
      resultsCopy,
      state.searchModel,
      state,
    )
    const newState = {
      ...state,
      results: resultsCopy,
      alphaIndexId: alphaId,
      taxonId: taxonId,
      page: page,
      size: size,
      searchType: searchType,
      totalResults: totalResults,
      intent: null,
    }

    if (state.results) {
      const existingResults: Result = JSON.parse(JSON.stringify(state.results))
      this.setExtistingResult(existingResults, results, resultsCopy, state)
      newState.results = existingResults
    }
    resultsStateContext.setState(newState)
  }

  @Action(SetArticleResults)
  setArticleResults(
    resultsStateContext: StateContext<ResultsStateModel>,
    { results, articleId, page, size, searchType }: SetArticleResults,
  ): any {
    const state = resultsStateContext.getState()
    const resultsCopy: Result = JSON.parse(JSON.stringify(results))
    const totalResults = this.setResultsCountAndFilters(
      resultsCopy,
      state.searchModel,
      state,
    )
    const newState = {
      ...state,
      results: resultsCopy,
      articleId,
      page: page,
      size: size,
      searchType: searchType,
      totalResults: totalResults,
      intent: null,
    }
    if (state.results) {
      const existingResults: Result = JSON.parse(JSON.stringify(state.results))
      this.setExtistingResult(existingResults, results, resultsCopy, state)
      newState.results = existingResults
    }
    resultsStateContext.setState(newState)
  }

  @Action(BookmarkResults)
  bookmarkResults(
    resultsStateContext: StateContext<ResultsStateModel>,
    { id, results, name, isBookmarked }: BookmarkResults,
  ): void {
    const state = resultsStateContext.getState()
    const resultsCopy: Result = Result.createCopy(
      results,
      !results.isBookmarked,
    )
    resultsCopy.resultName = name
    const searchBookmark: SearchBookmark = new SearchBookmark(
      id,
      'SEARCH',
      name,
      0,
      state.searchModel,
      state.alphaIndexId,
      state.taxonId,
      state.alphaIndexId,
      state.searchType,
      '',
    )
    if (isBookmarked) {
      this.bookmarkFacade.addBookmark(searchBookmark)
    } else {
      this.bookmarkFacade.deleteBookmark(id)
    }
    resultsStateContext.patchState({
      ...state,
      results: resultsCopy,
    })
  }

  @Action(VerifyBookmarkResult)
  verifyBookmarkResult(
    resultsStateContext: StateContext<ResultsStateModel>,
    { results }: VerifyBookmarkResult,
  ): void {
    of({})
      .pipe(withLatestFrom(this.bookmarkFacade.bookmarks$))
      .subscribe(([, bookmarksArray]) => {
        const state = resultsStateContext.getState()
        const resultsCopy: Result = Result.createCopy(
          results,
          results.isBookmarked,
        )
        const bookmarkIndex = bookmarksArray?.findIndex(
          (bookmark) =>
            bookmark.id.toLocaleLowerCase() ===
            resultsCopy.id.toLocaleLowerCase(),
        )
        resultsCopy.isBookmarked = bookmarkIndex !== -1

        resultsStateContext.patchState({
          ...state,
          results: {
            ...state.results,
            isBookmarked: resultsCopy.isBookmarked,
          } as Result,
        })
      })
  }

  @Action(CheckResultCountry)
  checkResultCountry(
    resultsStateContext: StateContext<ResultsStateModel>,
    { countryFilter }: CheckResultCountry,
  ): any {
    const state = resultsStateContext.getState()
    const countryFilters = state.results?.countryFilters
    const countryFiltersCopy: CountryFilter[] = JSON.parse(
      JSON.stringify(countryFilters),
    )
    const index = countryFilters?.findIndex(
      (filter) => filter.title === countryFilter.title,
    )
    if (index !== undefined) {
      countryFiltersCopy[index] = countryFilter
    }
    resultsStateContext.setState({
      ...state,
      intent: {
        intent: ResultIntent.countryFilterResults,
        index: crypto.randomUUID(),
      },
      results: {
        ...state.results,
        countryFilters: countryFiltersCopy,
      } as Result,
    })
  }

  @Action(CheckThesaurus)
  checkThesaurus(
    resultsStateContext: StateContext<ResultsStateModel>,
    { thesaurusFilter }: CheckThesaurus,
  ): any {
    const state = resultsStateContext.getState()
    const thesaurusFilters = state.results?.thesaurusFilters
    const thesaurusFiltersCopy: ThesaurusFilter[] = JSON.parse(
      JSON.stringify(thesaurusFilters),
    )
    const index = thesaurusFilters?.findIndex(
      (filter) => filter.title === thesaurusFilter.title,
    )
    if (index !== undefined) {
      thesaurusFiltersCopy[index] = thesaurusFilter
    }
    resultsStateContext.setState({
      ...state,
      intent: {
        intent: ResultIntent.thesaurusFilterResults,
        index: crypto.randomUUID(),
      },
      results: {
        ...state.results,
        thesaurusFilters: thesaurusFiltersCopy,
      } as Result,
    })
  }

  @Action(SetCurrentDisplayedResult)
  setCurrentDisplayedResult(
    resultsStateContext: StateContext<ResultsStateModel>,
    { id }: SetCurrentDisplayedResult,
  ): any {
    const state = resultsStateContext.getState()
    resultsStateContext.setState({
      ...state,
      currentDisplayedResult: id,
    })
  }

  @Action(GoNextDocument)
  goNextDocument(resultsStateContext: StateContext<ResultsStateModel>): any {
    const state = resultsStateContext.getState()
    const currentResultId = state.currentDisplayedResult
    const results = state.results?.searchResult
    const index = results.findIndex((x) => x.id === currentResultId)
    const isLastResult = index === results.length - 1

    if (isLastResult) {
      // If there are more results to load
      if (state.results?.hasMoreChildren) {
        this.navigateTo = NavigateAfterGettingResultTo.nextResult
        this.store.dispatch(
          new AdvancedSearch({
            ...state.searchModel,
            Page: state.searchModel.Page + 1,
          } as any),
        )
      } else {
        this.emitIntent(ResultIntent.showIsLastResult, resultsStateContext)
      }
    } else {
      this.navigateToNextOrPrevDoc(results[index + 1], resultsStateContext)
    }
  }

  @Action(GoPreviousDocument)
  goPreviousDocument(
    resultsStateContext: StateContext<ResultsStateModel>,
  ): any {
    const state = resultsStateContext.getState()
    const currentResultId = state.currentDisplayedResult
    const results = state.results?.searchResult
    let index = results.findIndex((x) => x.id === currentResultId)
    if (index === -1) {
      index = results.length
    }
    const isFirstResult = index === 0 && state.searchModel.Page === 0

    if (isFirstResult) {
      this.emitIntent(ResultIntent.showIsFirstResult, resultsStateContext)
    } else if (index === 0) {
      this.navigateTo = NavigateAfterGettingResultTo.previousResult
      this.store.dispatch(
        new AdvancedSearch({
          ...state.searchModel,
          Page: state.searchModel.Page - 1,
        } as any),
      )
    } else {
      this.navigateToNextOrPrevDoc(results[index - 1], resultsStateContext)
    }
  }

  @Action(NavigateToResult)
  navigateToResult(
    resultsStateContext: StateContext<ResultsStateModel>,
    { searchResult }: NavigateToResult,
  ): any {
    const state = resultsStateContext.getState()
    const path = `/results/${NodeTypeHelper.typeToPath(
      searchResult.type,
    )}/${searchResult.rootId.toUpperCase()}`
    const fragment =
      searchResult.type === DocType.courtDescription ||
      searchResult.type === DocType.law ||
      searchResult.type === DocType.constitution
        ? { fragment: 'a' + searchResult.id }
        : undefined
    this.store.dispatch(new SetCurrentDisplayedResultType(searchResult.type))
    this.emitIntent(ResultIntent.navigateToResultItem, resultsStateContext)
    resultsStateContext.setState({
      ...state,
      isLoading: true,
    })
    this.router.navigate([path], fragment).then(() => {
      resultsStateContext.setState({
        ...state,
        isLoading: false,
      })
    })
  }

  @Action(NavigateToResultInTree)
  navigateToResultInTree(
    resultsStateContext: StateContext<ResultsStateModel>,
    { resultType }: NavigateToResultInTree,
  ): any {
    this.store.dispatch(new SetCurrentDisplayedResultType(resultType))
    this.emitIntent(ResultIntent.navigateToResultItem, resultsStateContext)
  }

  @Action(ResetIntent)
  resetIntent(resultsStateContext: StateContext<ResultsStateModel>): any {
    const state = resultsStateContext.getState()
    resultsStateContext.patchState({
      ...state,
      intent: null,
    })
  }

  @Action(ReturnToResultList)
  returnToResultList(
    resultsStateContext: StateContext<ResultsStateModel>,
  ): any {
    const state = resultsStateContext.getState()
    if (state.searchType === SearchTypes.ADVANCED) {
      this.navigateTo = NavigateAfterGettingResultTo.resultsList
    }
    this.resetIntent(resultsStateContext)
  }

  @Action(SetCurrentDisplayedResultType)
  setCurrentDisplayedType(
    resultsStateContext: StateContext<ResultsStateModel>,
    { resultType }: SetCurrentDisplayedResultType,
  ): any {
    const state = resultsStateContext.getState()
    resultsStateContext.patchState({
      ...state,
      currentDisplayedResultType: resultType,
    })
  }

  @Action(ResetCurrentDisplayedResultType)
  resetCurrentDisplayedType(
    resultsStateContext: StateContext<ResultsStateModel>,
  ): any {
    const state = resultsStateContext.getState()
    resultsStateContext.patchState({
      ...state,
      currentDisplayedResultType: undefined,
    })
  }

  setExtistingResult(
    existingResults: Result,
    results: Result,
    resultsCopy: Result,
    state: ResultsStateModel,
  ): void {
    if (
      state?.intent?.intent === ResultIntent.countryFilterResults ||
      state?.intent?.intent === ResultIntent.thesaurusFilterResults
    ) {
      const countryFiltersDictionary: Map<string, boolean> = new Map(
        existingResults.countryFilters.map((countryFi) => [
          countryFi.code,
          countryFi.isChecked,
        ]),
      )
      const thesaurusFiltersDictionary: Map<string, boolean> = new Map(
        existingResults.thesaurusFilters.map((thesaurusFi) => [
          thesaurusFi.indexNumber,
          thesaurusFi.isChecked,
        ]),
      )
      resultsCopy.thesaurusFilters.forEach((thesaurusFilter) => {
        const isInThesaurusDictionary = thesaurusFiltersDictionary?.get(
          thesaurusFilter.indexNumber,
        )
        if (isInThesaurusDictionary !== undefined) {
          thesaurusFilter.isChecked = isInThesaurusDictionary
        }
      })
      resultsCopy.countryFilters.forEach((countryFilter) => {
        const isInCountriesDictionary = countryFiltersDictionary?.get(
          countryFilter.code,
        )
        if (isInCountriesDictionary !== undefined) {
          countryFilter.isChecked = isInCountriesDictionary
        }
      })
    }
    existingResults.hasMoreChildren = results.hasMoreChildren
    existingResults.resultName = results.resultName
    existingResults.id = resultsCopy.id
    existingResults.searchResult = results.searchResult
    existingResults.countryFilters = resultsCopy.countryFilters
    existingResults.thesaurusFilters = resultsCopy.thesaurusFilters
    existingResults.tree = results.tree
    this.store.dispatch(new ResetIntent())
  }

  setResultsCountAndFilters(
    resultsCopy: Result,
    searchModel: SearchModel,
    state: ResultsStateModel,
  ): number {
    let counter = 0
    resultsCopy.countryFilters?.forEach((countryFilter) => {
      counter += countryFilter.count
    })
    if (
      searchModel?.CountryFilterList?.length > 0 &&
      searchModel?.ThesaurusFilterList?.length === 0
    ) {
      resultsCopy.countryFilters = JSON.parse(
        JSON.stringify(state.results.countryFilters),
      )
    }
    if (
      searchModel?.ThesaurusFilterList?.length > 0 &&
      searchModel?.CountryFilterList?.length === 0
    ) {
      resultsCopy.thesaurusFilters = JSON.parse(
        JSON.stringify(state.results.thesaurusFilters),
      )
    }
    return counter
  }

  navigate(path: string, fragment?: NavigationExtras): void {
    this.router.navigate([path], fragment)
  }

  navigateToNextOrPrevDoc(
    result: SearchResult,
    resultsStateContext: StateContext<ResultsStateModel>,
  ): void {
    if (
      result.type === DocType.courtDescription ||
      result.type === DocType.law ||
      result.type === DocType.constitution
    ) {
      this.resultFacade
        .setPreloadResultTree(result.rootId, result.type)
        .subscribe(() => {
          this.navigateToResult(resultsStateContext, {
            searchResult: result,
          })
        })
    } else {
      this.navigateToResult(resultsStateContext, {
        searchResult: result,
      })
    }
  }

  emitIntent(
    intent: ResultIntent,
    resultsStateContext: StateContext<ResultsStateModel>,
  ): void {
    const state = resultsStateContext.getState()
    resultsStateContext.patchState({
      ...state,
      intent: {
        intent: intent,
        index: crypto.randomUUID(),
      },
    })
  }
}
