import { CodicesTree } from '@/codices/app/data/models/codices-tree.model'
import {
  CountryFilter,
  Result,
  SearchResult,
  ThesaurusFilter,
} from '@/codices/app/data/models/result.model'
import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { DocType } from '@/core/types/doc-type.enum'
import { SearchModel } from '@/core/types/search.model'
import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import {
  LoadResultsTree,
  SetPreloadResultTree,
} from '../results-tree/state/results-tree.action'
import {
  BookmarkResults,
  CheckResultCountry,
  CheckThesaurus,
  GoNextDocument,
  GoPreviousDocument,
  NavigateToResult,
  NavigateToResultInTree,
  ResetCurrentDisplayedResultType,
  ResetIntent,
  ReturnToResultList,
  SetAdvancedResults,
  SetAlphaResults,
  SetArticleResults,
  VerifyBookmarkResult,
} from '../state/result.action'
import { ResultState } from '../state/result.state'

@Injectable()
export class ResultsFacade {
  constructor(private store: Store) {}

  @Select(ResultState.selectResultStateDatas)
  results$: Observable<Result>

  @Select(ResultState.selectAlphaSearchModel)
  alphaSearchModel$: Observable<any>

  @Select(ResultState.selectArticleSearchModel)
  articleSearchModel$: Observable<any>

  @Select(ResultState.selectSearchModel)
  searchModel$: Observable<SearchModel>

  @Select(ResultState.selectTotalResults)
  totalResults$: Observable<number>

  @Select(ResultState.selectSearchType)
  searchType$: Observable<SearchTypes>

  @Select(ResultState.selectIntent)
  intent$: Observable<any>

  @Select(ResultState.selectCurrentDisplayedResultType)
  currentDisplayedResultType$: Observable<DocType>

  @Select(ResultState.selectIsLoading)
  isLoading$: Observable<boolean>

  public setAlphaResults(
    results: Result,
    alphaId: string,
    taxonId: string,
    page: number,
    size: number,
    searchType: SearchTypes,
  ): Observable<any> {
    return this.store.dispatch(
      new SetAlphaResults(results, alphaId, taxonId, page, size, searchType),
    )
  }

  public setAdvancedResults(
    results: Result,
    searchModel: SearchModel,
    searchType: SearchTypes,
  ): Observable<any> {
    return this.store.dispatch(
      new SetAdvancedResults(results, searchModel, searchType),
    )
  }

  public setArticleResults(
    results: Result,
    articleId: string,
    page: number,
    size: number,
    searchType: SearchTypes,
  ): Observable<any> {
    return this.store.dispatch(
      new SetArticleResults(results, articleId, page, size, searchType),
    )
  }

  public bookmarkResult(
    id: string,
    results: Result,
    name: string,
    isBookmarked: boolean,
  ): void {
    this.store.dispatch(new BookmarkResults(id, results, name, isBookmarked))
  }

  public verifyBookmarkResult(results: Result): void {
    this.store.dispatch(new VerifyBookmarkResult(results))
  }

  public checkCountryFilter(countryFilter: CountryFilter): void {
    this.store.dispatch(new CheckResultCountry(countryFilter))
  }

  public checkThesaurusFilter(thesaurusFilter: ThesaurusFilter): void {
    this.store.dispatch(new CheckThesaurus(thesaurusFilter))
  }

  public loadResultsTree(tree: CodicesTree): void {
    this.store.dispatch(new LoadResultsTree(tree))
  }

  public goNextDocument(): void {
    this.store.dispatch(new GoNextDocument())
  }

  public goPreviousDocument(): void {
    this.store.dispatch(new GoPreviousDocument())
  }

  public navigateToResult(searchResult: SearchResult): void {
    this.store.dispatch(new NavigateToResult(searchResult))
  }

  public navigateToResultInTree(resultType: DocType): void {
    this.store.dispatch(new NavigateToResultInTree(resultType))
  }

  public resetIntent(): void {
    this.store.dispatch(new ResetIntent())
  }

  public resetCurrentDisplayedType(): void {
    this.store.dispatch(new ResetCurrentDisplayedResultType())
  }

  public returnToResults(): void {
    this.store.dispatch(new ReturnToResultList())
  }

  public setPreloadResultTree(
    id: string,
    countryCode: DocType,
  ): Observable<any> {
    return this.store.dispatch(new SetPreloadResultTree(id, countryCode))
  }
}
