import { BaseDocumentClass } from '@/core/types/base-document-class.model'
import { Bookmark } from '@/core/types/bookmark.model'
import { Bookmarkable } from '@/core/types/bookmarkable.inteface'
import { LawStructure } from '@/core/types/law-structure.model'
import { TranslationModel } from '@/core/types/translation.model'
import { plainToClass } from 'class-transformer'

export class Law extends BaseDocumentClass implements Bookmarkable {
  lawStructures: LawStructure[]
  lawTranslations: Map<string, TranslationModel>
  languages: string[]
  country: string
  docType: string
  isFinalized: boolean
  isBookmarked: boolean
  id: string
  createdBy: string
  createdDate: string
  lastModifiedBy: string
  lastModifiedDate: string
  isSoftDeleted: boolean
  deletedDate: string
  bookmarkOrder: number

  createBookmarkTitle(language: string): string {
    const translations = new Map(Object.entries(this.lawTranslations))
    const lawTranslation = translations.get(language)
    if (lawTranslation) {
      return lawTranslation.title
    } else {
      return translations.values().next().value.title
    }
  }

  toBookmark(language: string): Bookmark {
    return new Bookmark(
      this.id,
      'LAW',
      this.createBookmarkTitle(language),
      this.bookmarkOrder,
      this.breadCrumb,
    )
  }

  static fromJson(obj: any): Law {
    return plainToClass(Law, obj)
  }

  static createCopy(law: Law, isBookmarked: boolean): Law {
    const lawCopy: Law = Law.fromJson(Object.assign({}, Law.fromJson(law)))
    lawCopy.isBookmarked = isBookmarked
    return lawCopy
  }
}
