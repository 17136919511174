import { MeFacade } from '@/codices/app/me/services/me.facade'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'shell-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.sass'],
})
export class HeaderAccountComponent implements OnInit {
  userName: string
  constructor(private meFacade: MeFacade) {}
  ngOnInit(): void {
    this.userName = `${this.meFacade.me?.firstName} ${this.meFacade.me?.lastName}`
  }
}
