import { PrecisDto } from '@/codices/app/data/models/precis'
import { precisTranslationDto } from '@/codices/app/data/models/precis-translation'
import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import { ThesaurusTranslation } from '@/codices/app/data/models/thesaurus-translation.model'
import { BookmarksFacade } from '@/codices/app/features/bookmarks/services/bookmarks.facade'
import { HighlightFacade } from '@/codices/app/features/results/services/highlight.facade'
import { GeoFacade } from '@/core/features/geo/services/geo.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable, map, withLatestFrom } from 'rxjs'
import { PrecisApiService } from '../services/precis-api.service'
import { PrecisService } from '../services/precis.service'
import {
  BookmarkPrecis,
  GetPrecis,
  GetPrecisViewModel,
  HighlightPrecis,
  SetPrecisViewModel,
} from './precis.action'

export class PrecisStateModel {
  precis: PrecisDto
  precisViewmodel: PrecisViewModel
  languages: LanguageTranslation[]
  selectedLanguage: string
  bookmarkOrder: number
}

export class PrecisViewModel {
  precis: PrecisDto
  translation: precisTranslationDto
  alphaIndex: string[]
  alphaIndexTaxons: TaxonDto[]
  alphaIndexTaxonsArray: TaxonDto[][]
  thesaurus: ThesaurusTranslation[]
  languages: LanguageTranslation[]
  bookmarkOrder: number
  dateString: string
}

@State({
  name: 'precisState',
  defaults: {
    precis: null,
    precisViewmodel: null,
    languages: [],
    selectedLanguage: '',
    bookmarkOrder: null,
  },
})
@Injectable()
export class PrecisState {
  constructor(
    private service: PrecisService,
    private apiService: PrecisApiService,
    private bookmarkFacade: BookmarksFacade,
    private highlightFacade: HighlightFacade,
    private geoFacade: GeoFacade,
  ) {}

  languageTranslations: LanguageTranslation[] = []

  @Selector()
  static selectPrecis(state: PrecisStateModel): PrecisDto {
    return state.precis
  }

  @Selector()
  static selectPrecisViewmodel(state: PrecisStateModel): PrecisViewModel {
    return state.precisViewmodel
  }

  @Selector()
  static selectPrecisLanguages(state: PrecisStateModel): LanguageTranslation[] {
    return state.languages
  }

  @Selector()
  static selectSelectedLanguage(state: PrecisStateModel): string {
    return state.selectedLanguage
  }

  @Action(GetPrecis)
  getPrecis(
    precisStateContext: StateContext<PrecisStateModel>,
    { id }: GetPrecis,
  ): Observable<PrecisDto> {
    return this.apiService.getPrecis(id).pipe(
      withLatestFrom(this.bookmarkFacade.bookmarks$, this.geoFacade.countries$),
      map(([precisWithAllTranslations, bookmarks, countries]) => {
        const state = precisStateContext.getState()
        const precisWithAllTranslationsCopy = PrecisDto.fromJson(
          precisWithAllTranslations,
        )
        if (bookmarks.length > 0) {
          bookmarks.forEach((precis: any) => {
            if (
              id !== null &&
              precis.id.toLocaleUpperCase() === id.toLocaleUpperCase()
            ) {
              precisWithAllTranslationsCopy.isBookmarked = true
            }
          })
        }
        precisWithAllTranslationsCopy.createBreadCrumb(
          countries,
          precisWithAllTranslationsCopy.country,
        )
        precisStateContext.patchState({
          ...state,
          precis: precisWithAllTranslationsCopy,
        })
        return precisWithAllTranslationsCopy
      }),
    )
  }

  @Action(GetPrecisViewModel)
  getPrecisViewmodel(
    precisStateContext: StateContext<PrecisStateModel>,
    { selectedLanguage }: GetPrecisViewModel,
  ): void {
    const state = precisStateContext.getState()
    let selectedLang = selectedLanguage

    if (state.precis) {
      const translations = new Map<string, precisTranslationDto>(
        Object.entries(state.precis.precisTranslations),
      )
      let precisTranslated = translations.get(selectedLang)

      if (!precisTranslated) {
        const firstTrans: any[] = translations.entries().next().value
        precisTranslated = firstTrans[1]
        selectedLang = firstTrans[0]
      }

      if (precisTranslated) {
        const precisVM: PrecisViewModel =
          this.service.getViewModel(selectedLang)

        precisStateContext.patchState({
          ...state,
          precisViewmodel: precisVM,
          languages: this.service.getBaseLanguages(),
          selectedLanguage: selectedLang,
        })
      }
    }
  }

  @Action(HighlightPrecis)
  highlightPrecis(
    precisStateContext: StateContext<PrecisStateModel>,
    { queryWords, resultIntent, highlightType }: HighlightPrecis,
  ): void {
    const state = precisStateContext.getState()
    if (queryWords != null && resultIntent != null && queryWords.length > 0) {
      const vm: PrecisViewModel = this.service.highlight(
        queryWords,
        highlightType,
      )
      precisStateContext.patchState({
        ...state,
        precisViewmodel: vm,
      })
    }
  }

  @Action(SetPrecisViewModel)
  setPrecisViewModel(
    precisStateContext: StateContext<PrecisStateModel>,
    { vm }: SetPrecisViewModel,
  ): void {
    const state = precisStateContext.getState()
    this.highlightFacade.goToNextWord('', 0, true)
    precisStateContext.patchState({
      ...state,
      precisViewmodel: vm,
    })
  }

  @Action(BookmarkPrecis)
  bookmarkPrecis(
    precisStateContext: StateContext<PrecisStateModel>,
    { precis }: BookmarkPrecis,
  ): void {
    const state = precisStateContext.getState()
    const precisCopy: PrecisDto = PrecisDto.createCopy(
      precis,
      !precis.isBookmarked,
    )

    const vmCopy = Object.assign({}, state.precisViewmodel)
    vmCopy.precis = precisCopy

    if (precisCopy.isBookmarked) {
      this.bookmarkFacade.addBookmark(
        precisCopy.toBookmark(state.selectedLanguage),
      )
    } else {
      this.bookmarkFacade.deleteBookmark(precisCopy.id)
    }
    precisStateContext.patchState({
      ...state,
      precis: precisCopy,
      precisViewmodel: vmCopy,
    })
  }
}
