import { CodicesTree } from '@/codices/app/data/models/codices-tree.model'
import { TreeHelper } from '@/codices/app/features/shared/tree/services/tree.helper'
import { TreesApiService } from '@/codices/app/features/shared/tree/services/trees-api.service'
import { NodeClickHandler } from '@/codices/features/shared/tree/state/node-click.handler'
import { DocType } from '@/core/types/doc-type.enum'
import { TreeNode } from '@/core/types/tree-node.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { EMPTY, Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import {
  GetDocumentsTree,
  HighlightNode,
  NodeClicked,
  NodeCountryClicked,
  ReloadTree,
} from './documents-tree.action'

export enum DocumentsTreeIntent {
  reloadTree = 'reloadTree',
  highlightNode = 'highlightNode',
  clearHighlight = 'clearHighlight',
}

export class DocumentsTreeStateModel {
  treeNodes: TreeNode[]
  treeIntent: { intent: DocumentsTreeIntent; index: string }
}

@State({
  name: 'documentsTreeState',
  defaults: {
    treeNodes: [],
    treeIntent: null,
  },
})
@Injectable()
export class DocumentsTreeState {
  constructor(
    private store: Store,
    private service: TreesApiService,
    private nodeClickHandler: NodeClickHandler,
  ) {}

  @Selector()
  static selectDocumentsTreeNodes(state: DocumentsTreeStateModel): TreeNode[] {
    return state.treeNodes
  }

  @Selector()
  static selectTreeIntent(state: DocumentsTreeStateModel): {
    intent: DocumentsTreeIntent
    index: string
  } {
    return state.treeIntent
  }

  @Action(GetDocumentsTree)
  getDocumentsTree(
    ctx: StateContext<DocumentsTreeStateModel>,
    { entityType, rootID, size }: GetDocumentsTree,
  ): Observable<CodicesTree> {
    return this.service.getCodicesTree(entityType, rootID, size).pipe(
      tap((results: CodicesTree) => {
        const nodesArray = results?.toArray() ?? []
        ctx.patchState({
          treeNodes: nodesArray,
        })
      }),
    )
  }

  @Action(NodeClicked)
  nodeClicked(
    ctx: StateContext<DocumentsTreeStateModel>,
    { node, isExpanded, tree, page, countryCode, size }: NodeClicked,
  ): Observable<TreeNode> {
    // Logic 1: User clicked on 'more' node
    if (node.type === DocType.more && node.parent) {
      return this.doMoreButtonCLicked(ctx, node, tree, page, countryCode, size)
    }
    // Logic 2: User clicked on country node (Laws, Constitutions, Descriptions...)
    if (isExpanded && node.countryCode && node.children.length === 0) {
      this.addIsLoadingNode(ctx, node)
      return this.loadFirstResults(ctx, node, tree, page, countryCode, size)
    }
    // Logic 3: User clicked on report node
    if (isExpanded && node.type === DocType.report) {
      return of(node)
    }

    return EMPTY
  }

  @Action(NodeCountryClicked)
  nodeCountryClicked(
    ctx: StateContext<DocumentsTreeStateModel>,
    { node, isExpanded, tree, page, countryCode, size }: NodeCountryClicked,
  ): any {
    if (
      node &&
      node.countryCode &&
      node.type !== DocType.loading &&
      node.children.length
    ) {
      this.nodeClicked(ctx, {
        node: node.children[0],
        isExpanded,
        tree,
        page,
        countryCode,
        size,
      })
    }
  }

  @Action(HighlightNode)
  highlightNode(
    ctx: StateContext<DocumentsTreeStateModel>,
    { nodeId }: HighlightNode,
  ): void {
    ctx.patchState({
      treeIntent: {
        intent: DocumentsTreeIntent.highlightNode,
        index: nodeId,
      },
    })
  }

  @Action(ReloadTree)
  reloadTree(ctx: StateContext<DocumentsTreeStateModel>): void {
    ctx.patchState({
      treeIntent: {
        intent: DocumentsTreeIntent.reloadTree,
        index: crypto.randomUUID(),
      },
    })
  }

  private doMoreButtonCLicked(
    ctx: StateContext<DocumentsTreeStateModel>,
    node: TreeNode,
    tree: TreeNode[],
    page: number,
    countryCode: string,
    size: number,
  ): Observable<TreeNode> {
    const nodeCopy = Object.assign({}, node)
    const parent = TreeHelper.findNode(tree, TreeHelper.findNodeFct(nodeCopy))
    if (parent && nodeCopy.countryCode === parent.countryCode) {
      node.toLoadingNode()
      return this.loadMoreResults(ctx, parent, tree, page, countryCode, size)
    }
    return EMPTY
  }

  private loadFirstResults(
    ctx: StateContext<DocumentsTreeStateModel>,
    node: TreeNode,
    tree: TreeNode[],
    page: number,
    countryCode: string,
    size: number,
  ): Observable<TreeNode> {
    return this.service
      .getNodeChildren(node.type, page, countryCode, size)
      .pipe(
        tap((resultNode: TreeNode) => {
          // Merge new children to node
          node.mergeFromUpdatedNode(resultNode)
          // Remove loading node
          node.removeIsLoadingFromChildren()
          //this.modifyNodeFromTree(tree, node)
          // If node has children, select first child
          if (node.children.length) {
            this.store.dispatch(
              new NodeClicked(
                node.children[0],
                true,
                tree,
                page,
                countryCode,
                size,
              ),
            )
          }
          // Reload tree
          ctx.patchState({
            treeIntent: {
              intent: DocumentsTreeIntent.reloadTree,
              index: crypto.randomUUID(),
            },
          })
        }),
      )
  }

  private loadMoreResults(
    ctx: StateContext<DocumentsTreeStateModel>,
    node: TreeNode,
    tree: TreeNode[],
    page: number,
    countryCode: string,
    size: number,
  ): Observable<TreeNode> {
    return this.service
      .getNodeChildren(node.type, page, countryCode, size)
      .pipe(
        tap((resultNode: TreeNode) => {
          // Merge new children to node
          node.mergeFromUpdatedNode(resultNode)
          // Remove loading node
          node.removeIsLoadingFromChildren()
          //this.modifyNodeFromTree(tree, node)
          // Reload tree
          ctx.patchState({
            treeIntent: {
              intent: DocumentsTreeIntent.reloadTree,
              index: crypto.randomUUID(),
            },
          })
        }),
      )
  }

  private addIsLoadingNode(
    ctx: StateContext<DocumentsTreeStateModel>,
    node: TreeNode,
  ): void {
    node.children.push(TreeNode.getLoadingNode(node))
    ctx.patchState({
      treeIntent: {
        intent: DocumentsTreeIntent.reloadTree,
        index: crypto.randomUUID(),
      },
    })
  }

  //TODO to fix tree issue when we click on previous
  // private appendNodesToTree(
  //   currentTree: TreeNode[],
  //   childrenNodes: TreeNode[][],
  // ): void {
  //   currentTree.forEach((treeNode) => {
  //     if (
  //       treeNode?.level === 2 &&
  //       treeNode.children.length > 0 &&
  //       treeNode?.countryCode
  //     ) {
  //       childrenNodes.push(treeNode.children)
  //     } else {
  //       this.appendNodesToTree(treeNode.children, childrenNodes)
  //     }
  //   })
  // }

  // private modifyNodeFromTree(
  //   currentTree: TreeNode[],
  //   clickedNode: TreeNode,
  // ): void {
  //   currentTree.forEach((treeNode) => {
  //     if (
  //       treeNode?.level === 2 &&
  //       treeNode?.countryCode === clickedNode?.countryCode &&
  //       treeNode?.type === clickedNode?.type
  //     ) {
  //       treeNode = clickedNode
  //     } else {
  //       this.modifyNodeFromTree(treeNode.children, clickedNode)
  //     }
  //   })
  // }
}
