import { ApiService } from '@/core/features/shared/services/api.service'
import { Country } from '@/core/types/country.model'
import { CourtGroup } from '@/core/types/court-group.model'
import { Language } from '@/core/types/language.model'
import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

const LANGUAGES_ENDPOINT = 'language'
const COUNTRIES_ENDPOINT = 'country'
const GROUPS_ENDPOINT = 'courtgroup'

@Injectable({
  providedIn: 'root',
})
export class GeoApiService {
  private api = inject(ApiService)

  getLanguages(): Observable<Language[]> {
    return this.api.get<Language[]>(LANGUAGES_ENDPOINT)
  }

  getCountries(): Observable<Country[]> {
    return this.api.get<Country[]>(COUNTRIES_ENDPOINT)
  }

  getCourtGroups(): Observable<CourtGroup[]> {
    return this.api.get<CourtGroup[]>(GROUPS_ENDPOINT)
  }
}
