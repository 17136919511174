import { NgModule } from '@angular/core'
import { GeoFacade } from '../features/geo/services/geo.facade'
import { BookmarkPipe } from './bookmark.pipe'
import { DatePipe } from './date.pipe'
import { LanguagePipe } from './language.pipe'
import { SafePipe } from './safe.pipe'

@NgModule({
  declarations: [LanguagePipe, SafePipe, DatePipe, BookmarkPipe],
  exports: [LanguagePipe, SafePipe, DatePipe, BookmarkPipe],
  providers: [GeoFacade],
  imports: [],
})
export class SharedPipesModule {}
