import { CourtDescription } from '@/codices/app/data/models/court-description.model'
import { HighlightFacade } from '@/codices/app/features/results/services/highlight.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { TranslationModel } from '@/core/types/translation.model'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import {
  DocumentGetViewModel,
  DocumentHighlight,
} from '../../services/documents.service'
import {
  DescriptionStateModel,
  DescriptionViewmodel,
} from '../state/description.state'

@Injectable()
export class DescriptionService
  implements DocumentGetViewModel, DocumentHighlight
{
  constructor(private store: Store, private highlightFacade: HighlightFacade) {}

  getViewModel(selectedLang: string): any {
    const descriptionState = this.store.snapshot()
      .descriptionState as DescriptionStateModel

    const viewModel = new DescriptionViewmodel()
    const translations = new Map(
      Object.entries(descriptionState.description.courtDescriptionTranslations),
    )
    if (translations) {
      const descriptionTranslations = translations.get(selectedLang)
      if (descriptionTranslations) {
        viewModel.description = {
          id: 'a' + descriptionState.description.id,
          title: descriptionTranslations.title,
          text: descriptionTranslations.text,
          languageCode: selectedLang,
          countryName: descriptionState.description.country,
          note: descriptionTranslations.note
            ? descriptionTranslations.note
            : '',
        }
      }
    }

    let childrenTranslation: TranslationModel[] = []
    if (
      descriptionState.description.children &&
      descriptionState.description.children.length > 0
    ) {
      descriptionState.description.children.forEach((child) => {
        childrenTranslation = this.buildChildrenViewModelRecursive(
          child,
          selectedLang,
          childrenTranslation,
        )
      })

      viewModel.children = childrenTranslation
    }
    return viewModel
  }

  getBaseLanguages(): LanguageTranslation[] {
    const languages: LanguageTranslation[] = []
    const descriptionState = this.store.snapshot()
      .descriptionState as DescriptionStateModel

    if (descriptionState.description) {
      descriptionState.description.languages.forEach((descriptionLanguage) => {
        languages.push({
          name: '',
          languageTranslationCode: descriptionLanguage,
          languageCode: '',
        })
      })
    }

    return languages
  }

  highlight(queryWords: string[]): any {
    const vm = this.store.snapshot().descriptionState
      .descriptionViewmodel as DescriptionViewmodel
    const vmCopy: DescriptionViewmodel = JSON.parse(JSON.stringify(vm))
    this.highlightFacade.cleanHighlightIds()
    this.highlightFacade.ids = []

    vmCopy?.children.map((child) => {
      const childText = this.highlightFacade.highlightText(
        queryWords,
        child?.text,
      )
      const childTitle = this.highlightFacade.highlightText(
        queryWords,
        child?.title,
      )
      child.text = childText
      child.title = childTitle
      return child
    })

    this.highlightFacade.setHighlightIds(this.highlightFacade.ids)
    return vmCopy
  }

  private buildChildrenViewModelRecursive(
    child: CourtDescription,
    selectedLanguage: string,
    childrenTranslation: TranslationModel[],
  ): TranslationModel[] {
    const translations = new Map(
      Object.entries(child.courtDescriptionTranslations),
    )
    if (translations) {
      const descriptionTranslations = translations.get(selectedLanguage)
      if (descriptionTranslations) {
        childrenTranslation.push({
          id: 'a' + child.id,
          title: descriptionTranslations.title,
          text: descriptionTranslations.text,
          languageCode: selectedLanguage,
          countryName: child.country,
          note: descriptionTranslations.note
            ? descriptionTranslations.note
            : '',
        })
      }
    }

    if (child.children && child.children.length > 0) {
      child.children.forEach((c) => {
        childrenTranslation = this.buildChildrenViewModelRecursive(
          c,
          selectedLanguage,
          childrenTranslation,
        )
      })
    }
    return childrenTranslation
  }
}
