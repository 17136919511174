import {
  CountryFilter,
  ThesaurusFilter,
} from '@/codices/app/data/models/result.model'

export class AddCountryFilter {
  static readonly type = '[Results] Add a country filter '
  constructor(public countryFilter: CountryFilter) {}
}

export class RemoveAddedCountryFilter {
  static readonly type = '[Results] Remove a country filter '
  constructor(public countryFilter: CountryFilter) {}
}

export class AddThesaurusFilter {
  static readonly type = '[Results] Add a thesaurus filter '
  constructor(public thesaurusFilter: ThesaurusFilter) {}
}

export class RemoveAddedThesaurusFilter {
  static readonly type = '[Results] Remove a thesaurus filter '
  constructor(public thesaurusFilter: ThesaurusFilter) {}
}

export class DownloadResults {
  static readonly type = '[Results] Download Results'
}
