import { plainToClass, Type } from 'class-transformer'
import 'reflect-metadata'
import { ContinentCodes } from './continent-code.enum'
import { DocType } from './doc-type.enum'
import { IntroNodeType } from './intro-node-type.enum'

export class TreeNode {
  title: string
  id: string
  hasMoreChildren: boolean
  level: number
  type: DocType
  url: string | null
  indexPagination = 0
  countryCode: string
  @Type(() => TreeNode)
  children: TreeNode[]
  introductionType: IntroNodeType
  selected: boolean
  indeterminate: boolean
  @Type(() => TreeNode)
  parent: TreeNode
  treeId: string
  isActive: boolean
  continentCode: ContinentCodes
  resultCount: number

  static fromJson(obj: any): TreeNode {
    return plainToClass(TreeNode, obj)
  }

  static getMoreNode(parentNode: TreeNode): TreeNode {
    const node = this.getEmptyNode(parentNode)
    node.title = 'documents.tree.more'
    node.type = DocType.more
    if (parentNode.children.length > 0) {
      node.indexPagination = parentNode.children.length / 20
    } else {
      node.indexPagination = parentNode.indexPagination
    }
    return node
  }

  static getLoadingNode(parentNode: TreeNode): TreeNode {
    const node = this.getEmptyNode(parentNode)
    node.title = 'documents.tree.loading'
    node.type = DocType.loading
    return node
  }

  static getEmptyNode(parentNode: TreeNode): TreeNode {
    return plainToClass(TreeNode, {
      title: '',
      id: parentNode.id,
      hasMoreChildren: false,
      level: parentNode.level,
      type: null,
      url: null,
      indexPagination: parentNode.indexPagination,
      isClicked: false,
      countryCode: parentNode.countryCode,
      children: [],
      introductionType: IntroNodeType.none,
      selected: false,
      indeterminate: false,
      parent: parentNode,
      isActive: false,
      parentId: parentNode.id,
    })
  }

  public mergeFromUpdatedNode(node: TreeNode): void {
    if (this.children[this.children.length - 1]?.type === DocType.loading) {
      this.children.pop()
    }
    this.children = this.children.concat(node.children)
    this.hasMoreChildren = node.hasMoreChildren
    if (this.hasMoreChildren) {
      this.indexPagination = this.children.length / 20
      this.children = Object.assign([], this.children)
      this.children.push(TreeNode.getMoreNode(this))
    }
  }

  public removeIsLoadingFromChildren(): void {
    this.children = this.children.filter(
      (child) => child.type !== DocType.loading,
    )
  }

  public toLoadingNode(): void {
    this.title = 'documents.tree.loading'
    this.type = DocType.loading
  }

  public isLoadingOrMoreNode(): boolean {
    return this.type === DocType.loading || this.type === DocType.more
  }
}
