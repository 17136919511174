import { environment } from '@/core/environment'
import { CoreSharedFacade } from '@/core/features/core-shared/services/core-shared.facade'
import { SelectOption } from '@/core/types/select-option.model'
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core'

@Component({
  selector: 'shell-header-language',
  templateUrl: './header-language.component.html',
  styleUrls: ['./header-language.component.sass'],
})
export class HeaderLanguageComponent implements AfterViewInit {
  languages: SelectOption[] = [
    { key: 'FR-fr', value: 'fra' },
    { key: 'EN-en', value: 'eng' },
  ]
  selectedLanguage = this.languages[0]
  bulletinUrl = environment.navBar.BULLETINS_URL
  wccjUrl = environment.navBar.WCCJ_UR

  constructor(
    private facade: CoreSharedFacade,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.facade.language$.subscribe((lang) => {
      const selected = this.languages.find((x) => x.value === lang)
      this.selectedLanguage = selected != null ? selected : this.languages[0]
    })

    //to avoid error "Expression has changed after it was checked"
    this.cdRef.detectChanges()
  }

  changeLanguage(): void {
    const parsedUrl = new URL(window.location.href)
    const baseUrl = parsedUrl.origin
    this.facade.changesLanguages(this.selectedLanguage.value)
    window.location.href = `${baseUrl}/codices/documents/welcome?lang=${this.selectedLanguage.value}`
  }
}
