import { CodicesTree } from '@/codices/app/data/models/codices-tree.model'
import { ApiService } from '@/core/features/shared/services/api.service'
import { DocType } from '@/core/types/doc-type.enum'
import { TreeNode } from '@/core/types/tree-node.model'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, map } from 'rxjs'

const TREE_ENDPOINT = 'tree'
const PRELOAD_SEARCH_TREE_ENDPOINT = 'tree/preloadSearchTree'
const MORE_LAW_ENDPOINT = 'law/tree'
const MORE_PRECIS_ENDPOINT = 'precis/tree'
const MORE_FULLTEXT_ENDPOINT = 'fulltext/tree'
const MORE_CONSTITUION_ENDPOINT = 'constitution/tree'
const MORE_COURTDESCRIPTION_ENDPOINT = 'courtDescription/tree'

@Injectable()
export class TreesApiService {
  constructor(private apiService: ApiService) {}

  getCodicesTree(
    entityType: string | null,
    rootID: string | null,
    size: number | null,
  ): Observable<CodicesTree> {
    const endpoint = TREE_ENDPOINT
    if (entityType && rootID && size) {
      return this.apiService
        .get(endpoint, {
          entityType,
          rootID,
          size,
        })
        .pipe(
          map((response) => {
            return CodicesTree.fromJson(response)
          }),
        )
    }
    return this.apiService.get(endpoint).pipe(
      map((response) => {
        return CodicesTree.fromJson(response)
      }),
    )
  }

  getResultTreeSpecificNode(
    entityType: string | null,
    rootID: string | null,
    size: number | null,
  ): Observable<TreeNode> {
    const endpoint = PRELOAD_SEARCH_TREE_ENDPOINT
    return this.apiService
      .get<any>(endpoint, {
        entityType: entityType ?? undefined,
        rootID: rootID ?? undefined,
        size: size ?? undefined,
      })
      .pipe(
        map((response) => {
          return response
        }),
      )
  }

  getNodeChildren(
    type: string,
    page: number,
    countryCode: string,
    size: number,
  ): Observable<TreeNode> {
    const callConfig: any = {
      endpoint: null,
      params: {
        page,
        countryCode,
        size,
      },
    }

    switch (type) {
      case DocType.precis:
      case DocType.precisInProgress:
        callConfig.endpoint = MORE_PRECIS_ENDPOINT
        callConfig.params.isFinalized = type === DocType.precis
        break
      case DocType.law:
        callConfig.endpoint = MORE_LAW_ENDPOINT
        break
      case DocType.fullText:
        callConfig.endpoint = MORE_FULLTEXT_ENDPOINT
        break
      case DocType.constitution:
        callConfig.endpoint = MORE_CONSTITUION_ENDPOINT
        break
      case DocType.courtDescription:
        callConfig.endpoint = MORE_COURTDESCRIPTION_ENDPOINT
        break
      default:
        return EMPTY
    }

    return this.apiService.get(callConfig.endpoint, callConfig.params).pipe(
      map((response) => {
        return TreeNode.fromJson(response)
      }),
    )
  }
}
