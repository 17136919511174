import { NgModule } from '@angular/core'
import { ExtraOptions, RouterModule, Routes } from '@angular/router'
import { canActivateAuthUsers } from './auth/auth.guard'
import { DashboardComponent } from './features/users/users-dashboard.component'
import { canActivateUsersDashboard } from './features/users/users-dashboard.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/documents/welcome',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/documents/services/documents.module').then(
        (m) => m.DocumentsModule,
      ),
  },
  {
    path: 'bookmarks',
    loadChildren: () =>
      import('./features/bookmarks/services/bookmarks.module').then(
        (m) => m.BookmarksModule,
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./features/search/services/search.module').then(
        (m) => m.SearchModule,
      ),
  },
  {
    path: 'results',
    loadChildren: () =>
      import('./features/results/services/results.module').then(
        (m) => m.ResultsModule,
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./features/users/users.routes').then((m) => m.USERS_ROUTES),
    canActivate: [canActivateAuthUsers],
  },
  {
    path: 'alert',
    loadChildren: () =>
      import('./features/results/alert-unsubscribe/alert-unsubscribe.routes'),
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [canActivateUsersDashboard],
  },
]

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
