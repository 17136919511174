import { MeFacade } from '@/codices/app/me/services/me.facade'
import { environment } from '@/core/environment'
import { EntitiesStateWithPage } from '@/core/features/shared/state/entities.state'
import { EntitiesStateModelWithPage } from '@/core/types/entities-state-model'
import { ListResult } from '@/core/types/list-result.model'
import { PrecisFilters } from '@/core/types/precis-filters'
import { Precis } from '@/core/types/precis.model'
import { Status } from '@/core/types/status.model'
import { Injectable, inject } from '@angular/core'
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  createSelector,
} from '@ngxs/store'
import { Observable, catchError, mergeMap, of, tap } from 'rxjs'
import { PRECIS_API_SERVICE_TOKEN } from '../services/precis-api.service'
import {
  PrecisChangePage,
  PrecisDelete,
  PrecisFetchAll,
  PrecisFetchAllForUser,
  PrecisGetStatutes,
  PrecisPatchFilters,
  PrecisUpdatePublicationState,
} from './precis.action'

export type PrecisStateModel = EntitiesStateModelWithPage<Precis> & {
  filters: PrecisFilters
  statutes: Status[]
  isForUser: boolean
}

export const PRECIS_STATE_TOKEN = new StateToken<PrecisStateModel>('precis')

@State<PrecisStateModel>({
  name: PRECIS_STATE_TOKEN,
  defaults: {
    entities: [],
    totalCount: 0,
    page: {
      index: environment.precis.defaultPageIndex,
      size: environment.precis.defaultPageSize,
    },
    isLoading: false,
    filters: {
      text: undefined,
      user: undefined,
      year: undefined,
      ebulletin: undefined,
      statusList: undefined,
      publicationStateList: undefined,
      fulltextSelect: undefined,
    },
    statutes: [],
    isForUser: false,
  },
})
@Injectable()
export class PrecisState extends EntitiesStateWithPage {
  private api = inject(PRECIS_API_SERVICE_TOKEN)
  private meFacade = inject(MeFacade)

  @Selector()
  static filters(state: PrecisStateModel): PrecisFilters {
    return state.filters
  }

  static filter(
    key: keyof PrecisFilters,
  ): (state: PrecisStateModel) => PrecisFilters[keyof PrecisFilters] {
    return createSelector(
      [PrecisState],
      (state: PrecisStateModel) => state.filters?.[key],
    )
  }

  @Selector()
  static statutes(state: PrecisStateModel): Status[] {
    return state.statutes
  }

  @Action(PrecisFetchAll, { cancelUncompleted: true })
  fetchAll(
    ctx: StateContext<PrecisStateModel>,
  ): Observable<ListResult<Precis>> {
    ctx.patchState({
      isLoading: true,
    })

    const { page, filters } = ctx.getState()

    return this.api.get({ page: page.index, size: page.size, ...filters }).pipe(
      tap((res) => {
        ctx.patchState({
          entities: res.listResult,
          totalCount: res.totalResultCount,
          isLoading: false,
        })
      }),
      catchError((e) => {
        ctx.patchState({
          isLoading: false,
        })
        throw e
      }),
    )
  }

  @Action(PrecisFetchAllForUser, { cancelUncompleted: true })
  fetchAllForUser(
    ctx: StateContext<PrecisStateModel>,
  ): Observable<ListResult<Precis>> {
    ctx.patchState({
      isLoading: true,
      isForUser: true,
    })

    const user = this.meFacade.me
    const { page, filters } = ctx.getState()

    if (user === null) return of()

    return this.api.getForUser({ ...page, ...filters }).pipe(
      tap((res) => {
        ctx.patchState({
          entities: res.listResult,
          totalCount: res.totalResultCount,
          isLoading: false,
        })
      }),
      catchError((e) => {
        ctx.patchState({
          isLoading: false,
        })
        throw e
      }),
    )
  }

  @Action(PrecisDelete, { cancelUncompleted: true })
  delete(
    ctx: StateContext<PrecisStateModel>,
    { id }: PrecisDelete,
  ): Observable<void> {
    ctx.patchState({
      isLoading: true,
    })

    const { isForUser } = ctx.getState()

    return this.api.delete(id).pipe(
      catchError((e) => {
        ctx.patchState({
          isLoading: false,
        })
        throw e
      }),
      tap(() => {
        ctx.dispatch(
          isForUser ? new PrecisFetchAllForUser() : new PrecisFetchAll(),
        )
      }),
    )
  }

  @Action(PrecisUpdatePublicationState, { cancelUncompleted: true })
  updatePublicationState(
    ctx: StateContext<PrecisStateModel>,
    { id, state }: PrecisUpdatePublicationState,
  ): Observable<void> {
    ctx.patchState({
      isLoading: true,
    })

    return this.api.updatePublicationState(id, state).pipe(
      catchError((e) => {
        ctx.patchState({
          isLoading: false,
        })
        throw e
      }),
      mergeMap(() => ctx.dispatch(new PrecisFetchAll())),
    )
  }

  @Action(PrecisChangePage)
  changePage(
    ctx: StateContext<PrecisStateModel>,
    { page }: PrecisChangePage,
  ): void {
    const { page: currPage, isForUser } = ctx.getState()

    ctx.patchState({
      page: {
        ...currPage,
        ...page,
      },
    })

    ctx.dispatch(isForUser ? new PrecisFetchAllForUser() : new PrecisFetchAll())
  }

  @Action(PrecisPatchFilters)
  patchFilters(
    ctx: StateContext<PrecisStateModel>,
    { filters }: PrecisPatchFilters,
  ): void {
    const { filters: currFilters, isForUser } = ctx.getState()

    ctx.patchState({
      filters: {
        ...currFilters,
        ...filters,
      },
    })

    ctx.dispatch(isForUser ? new PrecisFetchAllForUser() : new PrecisFetchAll())
  }

  @Action(PrecisGetStatutes, { cancelUncompleted: true })
  getStatutes(ctx: StateContext<PrecisStateModel>): Observable<Status[]> {
    const { statutes } = ctx.getState()

    if (statutes.length) {
      return of(statutes)
    }

    return this.api.getStatutes().pipe(
      tap((res) => {
        ctx.patchState({
          statutes: res,
        })
      }),
    )
  }
}
