import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { AppResourcesService } from '../../shared/services/app-static-ressources.service'

@Component({
  selector: 'privacy-notice-modal',
  templateUrl: 'privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.sass'],
})
export class PrivacyNoticeComponent implements OnInit, OnDestroy {
  public privacyNotice: any
  public privacyNoticeString = ''
  private unsubscribe$ = new Subject()

  constructor(private resourceService: AppResourcesService) {}

  ngOnInit(): void {
    this.resourceService
      .getLatestHtmlResource()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.privacyNotice = result.html
        this.privacyNoticeString = this.privacyNotice
        this.privacyNoticeString = this.privacyNoticeString.replace(
          'href="www.codices.coe.int"',
          'href="http://www.codices.coe.int"',
        )
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null)
    this.unsubscribe$.complete()
  }
}
