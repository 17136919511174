import { BaseDocumentClass } from '@/core/types/base-document-class.model'
import { Bookmark } from '@/core/types/bookmark.model'
import { Bookmarkable } from '@/core/types/bookmarkable.inteface'
import { TranslationModel } from '@/core/types/translation.model'
import { plainToClass } from 'class-transformer'

export class CourtDescription
  extends BaseDocumentClass
  implements Bookmarkable
{
  id: string
  courtId: string
  country: string
  level: number
  index: number
  isBookmarked: boolean
  languages: string[]
  courtDescriptionTranslations: Map<string, TranslationModel>
  parentId: string
  children: CourtDescription[]
  bookmarkOrder: number
  navigatorLanguage = navigator.language.split('-')[0]

  createBookmarkTitle(
    courtDescription: CourtDescription,
    language: string,
  ): string {
    const translations = new Map(
      Object.entries(courtDescription.courtDescriptionTranslations),
    )
    const courtTranslation = translations.get(language)
    if (courtTranslation) {
      return courtTranslation.title
    } else {
      return translations.values().next().value.title
    }
  }

  static fromJson(obj: any): CourtDescription {
    return plainToClass(CourtDescription, obj)
  }

  toBookmark(language: string): Bookmark {
    return new Bookmark(
      this.id,
      'COURTDESCRIPTION',
      this.createBookmarkTitle(this, language),
      this.bookmarkOrder,
      this.breadCrumb,
    )
  }

  static createCopy(
    description: CourtDescription,
    isBookmarked: boolean,
  ): CourtDescription {
    const courtDescription: CourtDescription = CourtDescription.fromJson(
      Object.assign({}, CourtDescription.fromJson(description)),
    )
    courtDescription.isBookmarked = isBookmarked
    return courtDescription
  }
}
