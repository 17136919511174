import { Component, OnInit, inject } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MeFacade } from './me/services/me.facade'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [MatSnackBar],
})
export class AppComponent implements OnInit {
  private meFacade = inject(MeFacade)

  ngOnInit(): void {
    this.meFacade.setIsFromUnsubscribe(false, '')
  }
}
