import { CourtDescription } from '@/codices/app/data/models/court-description.model'
import { ApiService } from '@/core/features/shared/services/api.service'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'

const ENDPOINT = 'courtdescription'

@Injectable()
export class DescriptionApiService {
  constructor(private apiService: ApiService) {}

  getDescription(id: string | null): Observable<CourtDescription> {
    return this.apiService.get(`${ENDPOINT}/${id}`).pipe(
      map((response: any) => {
        return CourtDescription.fromJson(response)
      }),
    )
  }
}
