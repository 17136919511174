<ng-container *ngIf="privacyNoticeString === ''; else privacyNoticeNotEmpty">
  <mat-spinner></mat-spinner>
</ng-container>
<ng-template #privacyNoticeNotEmpty>
  <mat-dialog-content>
    <div [innerHtml]="privacyNoticeString"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="buttons-container">
    <button mat-dialog-close mat-stroked-button class="close-button">
      {{ 'buttons.close' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
