export class Bookmark {
  id: string
  title: string
  type: string
  breadCrumb: string
  order: number
  constructor(
    id: string,
    type: string,
    title: string,
    order: number,
    breadCrumb: string,
  ) {
    this.id = id
    this.title = title
    this.type = type
    this.breadCrumb = breadCrumb
    this.order = order
  }
}
