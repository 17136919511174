import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import { plainToClass } from 'class-transformer'
import { ThesaurusDto } from './thesaurus.model'

export class AlphaIndexDto {
  id: string
  taxon: TaxonDto[]
  alphaIndexReferences: AlphaIndexDto[]
  thesaursusReferences: ThesaurusDto[]
  languageCode: string
  static fromJson(obj: any): AlphaIndexDto {
    return plainToClass(AlphaIndexDto, obj)
  }
}
