import { VendorsModule } from '@/vendors/vendors.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { HeaderAccountComponent } from './header-account/header-account.component'
import { HeaderLanguageComponent } from './header-language/header-language.component'
import { HeaderComponent } from './header/header.component'

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderLanguageComponent,
    HeaderAccountComponent,
  ],
  imports: [CommonModule, VendorsModule, TranslateModule],
  exports: [HeaderComponent, HeaderLanguageComponent, HeaderAccountComponent],
})
export class ShellModule {}
