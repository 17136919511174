<div class="mat-toolbar-container">
  <a [href]="vensiteUrl" target="_blank">
    <img
      alt="Venice Commission logo"
      class="venice-com-img"
      src="assets/COE-logo-and-Venice-Commission-White-BW-Fil 3.svg"
    />
  </a>
  <a [href]="vensiteUrl" target="_blank">
    <img
      alt="COE logo"
      class="COE-img"
      src="assets/COE-logo-and-Venice-Commission-White-BW-Fil 5.svg"
    />
  </a>
  <span (click)="logoClick()" class="title-codices">{{
    'app.title' | translate
  }}</span>
</div>
