import { ResultHighlightNode } from '@/codices/app/features/results/results-tree/state/results-tree.action'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { SetCurrentDisplayedResult } from '../../results/state/result.action'
import { HighlightNode } from '../tree/state/documents-tree.action'

@Injectable()
export class DocumentsFacade {
  constructor(private store: Store, private router: Router) {}

  public documentDisplayed(id: string): void {
    if (this.isResult()) {
      // Highlight the node in the results tree
      this.store.dispatch(new ResultHighlightNode(id))
      // Set current result
      this.store.dispatch(new SetCurrentDisplayedResult(id))
    } else {
      // Highlight the node in the documents tree
      this.store.dispatch(new HighlightNode(id))
    }
  }

  private isResult(): boolean {
    return this.router.url.includes('/results')
  }
}
