import { MeFacade } from '@/codices/app/me/services/me.facade'
import { GlobalFacade } from '@/core/features/global/services/global.facade'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, Subject, combineLatest, of, take, takeUntil } from 'rxjs'

@Component({
  selector: 'app-codices-header',
  templateUrl: './codices-header.component.html',
  styleUrls: ['./codices-header.component.sass'],
})
export class CodicesHeaderComponent implements OnInit {
  openMenu$: Observable<boolean> = this.globalFacade.isMenuOpen$
  openTree$: Observable<boolean> = this.globalFacade.isTreeOpen$
  onDestroy$: Subject<boolean> = new Subject()

  constructor(
    private router: Router,
    private globalFacade: GlobalFacade,
    private meFacade: MeFacade,
  ) {}

  ngOnInit(): void {
    of(this.router.url)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.globalFacade.setIsMenuOpen(false)
        this.globalFacade.setIsTreeOpen(false)
        this.globalFacade.setIsFilterDrawerOpen(false)
      })
  }
  handleLogoCLicked(): void {
    this.router.navigate(['/documents/welcome'], {
      state: { bypassFormGuard: true },
    })
  }

  openMenuForSmallScreens(): void {
    combineLatest([this.openMenu$, this.openTree$])
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(([openMenu, openTree]) => {
        if (openTree) {
          this.globalFacade.setIsTreeOpen(false)
        }
        this.globalFacade.setIsMenuOpen(!openMenu)
      })
  }

  isUserConnected(): boolean {
    return this.meFacade.me !== null
  }
}
