import { SearchBookmark } from '@/codices/app/data/models/search-bookmark.model'
import { Bookmark } from '@/core/types/bookmark.model'
import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import {
  AddBookmark,
  DeleteBookmark,
  GetBookmarks,
  UpdateBookmark,
} from '../state/bookmarks.action'
import { BookmarkState } from '../state/bookmarks.state'

@Injectable()
export class BookmarksFacade {
  @Select(BookmarkState.selectBookmarkStateDatas)
  bookmarks$: Observable<(Bookmark | SearchBookmark)[]>

  constructor(private store: Store) {}

  public getBookmarks(language: string): void {
    this.store.dispatch(new GetBookmarks(language))
  }

  public updateBookmark(id: string, text: string): void {
    this.store.dispatch(new UpdateBookmark(id, text))
  }

  public deleteBookmark(id: string): void {
    this.store.dispatch(new DeleteBookmark(id))
  }

  public addBookmark(bookmark: any): void {
    this.store.dispatch(new AddBookmark(bookmark))
  }
}
