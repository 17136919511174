import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { GlobalFacade } from './services/global.facade'
import { GlobalState } from './state/global.state'

@NgModule({
  declarations: [],
  providers: [GlobalFacade],
  imports: [NgxsModule.forFeature([GlobalState])],
})
export class GlobalModule {}
