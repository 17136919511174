import { plainToClass } from 'class-transformer'
import 'reflect-metadata'
import { PrecisDto } from './precis'
import { ThesaurusTranslation } from './thesaurus-translation.model'

export class ThesaurusTree {
  children: ThesaurusTree[]
  constitutionArticle: any[]
  fullPath: string
  hasConstitutionArticle: boolean
  hasPrecis: boolean
  id: string
  index: number
  indexNumber: string
  level: number
  precis: PrecisDto[]
  thesaurusTranslations: Map<string, ThesaurusTranslation>
  parent: ThesaurusTree
  selectedTranslation: ThesaurusTranslation
  isVisible: boolean

  static fromJson(obj: any): ThesaurusTree {
    return plainToClass(ThesaurusTree, obj)
  }

  static fromArrayJson(obj: any[]): ThesaurusTree[] {
    const thesaurusTreeArr: ThesaurusTree[] = []
    obj.forEach((thesaurusTree) => {
      thesaurusTreeArr.push(plainToClass(ThesaurusTree, thesaurusTree))
    })
    return thesaurusTreeArr
  }
}
