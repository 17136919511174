import { Country } from '@/core/types/country.model'

export class BaseDocumentClass {
  breadCrumb: string

  createBreadCrumb(countries: Country[], documentCountry: string): void {
    countries.forEach((country) => {
      if (country.countryCode === documentCountry) {
        const countryTranslations = country.countryTranslation
        const continentTranslations = country.continent.continentTranslations
        this.breadCrumb = `${
          Object.entries(continentTranslations)[0][1].name
        }/${Object.entries(countryTranslations)[0][1].name}`
      }
    })
  }
}
