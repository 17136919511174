import { MeFacade } from '@/codices/app/me/services/me.facade'
import { GlobalFacade } from '@/core/features/global/services/global.facade'
import { UserRole } from '@/core/types/user-role.enum'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-codices-nav-header',
  templateUrl: './codices-nav-header.component.html',
  styleUrls: ['./codices-nav-header.component.sass'],
})
export class CodicesNavHeaderComponent {
  UserRole = UserRole

  constructor(
    private router: Router,
    private globalFacade: GlobalFacade,
    private meFacade: MeFacade,
  ) {}

  isDocButtonActive(): boolean {
    return this.router.url.includes('/documents')
  }

  isResultButtonActive(): boolean {
    return this.router.url.includes('/results')
  }

  isButtonVisible(userRole: UserRole): boolean {
    return this.meFacade.userRoles && this.meFacade.userRoles.length > 0
      ? this.meFacade.userRoles.includes(userRole)
      : false
  }
}
