import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import {
  OpenFilterDrawer,
  OpenLoader,
  OpenMenuForSmallScreens,
  OpenTreeForSmallScreens,
} from '../state/global.action'
import { GlobalState } from '../state/global.state'

@Injectable({
  providedIn: 'root',
})
export class GlobalFacade {
  @Select(GlobalState.selectIsMenuOpen)
  isMenuOpen$: Observable<boolean>

  @Select(GlobalState.selectIsTreeOpen)
  isTreeOpen$: Observable<boolean>

  @Select(GlobalState.selectisFilterDrawerOpen)
  isFilterDrawerOpen$: Observable<boolean>

  @Select(GlobalState.selectIsLoading)
  isLoading$: Observable<boolean>

  constructor(private store: Store) {}

  public setIsMenuOpen(isOpen: boolean): void {
    this.store.dispatch(new OpenMenuForSmallScreens(isOpen))
  }

  public setIsTreeOpen(isOpen: boolean): void {
    this.store.dispatch(new OpenTreeForSmallScreens(isOpen))
  }

  public setIsFilterDrawerOpen(isOpen: boolean): void {
    this.store.dispatch(new OpenFilterDrawer(isOpen))
  }

  public setIsLoading(isLoading: boolean): Observable<void> {
    return this.store.dispatch(new OpenLoader(isLoading))
  }
}
