import { MeState } from '@/codices/app/me/state/me.state'
import { environment } from '@/codices/env/environment'
import { CoreSharedState } from '@/core/features/core-shared/state/core-shared.state'
import { setAppInjector } from '@/core/features/shared/services/app.injector'
import { CoreServicesModule } from '@/core/features/shared/services/core-services.module'
import { NetworkInterceptor } from '@/core/interceptors/network-interceptor'
import { APP_BASE_HREF } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin'
import { NgxsModule } from '@ngxs/store'
import { AppInitProvider, appInitProviderFactory } from './app-init-provider'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthInterceptor } from './auth/services/auth.interceptor'
import { CodicesNgxsModule } from './codices-ngxs.module'
import { BookmarkState } from './features/bookmarks/state/bookmarks.state'
import { MenuModule } from './features/menu/menu.module'
import { HighlightState } from './features/results/state/highlight.state'
import { ConsentService } from './features/shared/consent/consent.service'
import { AppResourcesService } from './features/shared/services/app-static-ressources.service'
import { CustomErrorHandler } from './features/shared/services/custom-error-handler.service'
import { StorageEngineService } from './features/shared/services/storage-engine.service'
import { SharedModule } from './shared.module'

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreServicesModule.forRoot(environment),
    TranslateModule.forRoot({
      defaultLanguage: 'eng',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    CodicesNgxsModule,
    NgxsStoragePluginModule.forRoot({
      key: [BookmarkState, CoreSharedState, HighlightState, MeState],
      beforeSerialize: (obj, key) => {
        if (key === 'resultState') {
          return { ...obj, intent: undefined }
        }
        return obj
      },
    }),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    SharedModule,
    MenuModule,
  ],
  providers: [
    ConsentService,
    AppResourcesService,
    { provide: APP_BASE_HREF, useValue: '/codices' },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: StorageEngineService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AppInitProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitProviderFactory,
      deps: [AppInitProvider],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector)
  }
}
