import { Injectable, inject } from '@angular/core'
import { STORAGE_ENGINE } from '@ngxs/storage-plugin'
import { StorageEngineService } from '../../shared/services/storage-engine.service'

@Injectable()
export class BookmarksStorageService {
  private storageService: StorageEngineService = inject(STORAGE_ENGINE)

  getItem(key: string): string | null {
    return this.storageService.getItem(key)
  }
}
