import { ENV_TOKEN } from '@/core/features/shared/services/app.injector'
import { UserRole } from '@/core/types/user-role.enum'
import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { MeFacade } from '../../me/services/me.facade'

export const canActivateUsersDashboard: CanActivateFn = () => {
  const meFacade = inject(MeFacade)
  const router = inject(Router)
  const environment = inject(ENV_TOKEN)

  const me = meFacade.me

  if (!me) {
    const returnUrl = `${window.location.origin}/${environment.appName}`
    window.location.href = `${environment.apiUrl}/saml/login?returnUrl=${returnUrl}`
    return false
  }

  if (me && meFacade.isUserAllowed('codices')) {
    if (me.authorities.includes(UserRole.LIAISON_OFFICER)) {
      router.navigate(['/user/liaison-agent'])
      return false
    }
    if (me.authorities.includes(UserRole.PROOFREADER)) {
      router.navigate(['/user/proofreader'])
      return false
    }
  }

  meFacade.redirectCodices(true)
  return false
}
