import { Bookmark } from '@/core/types/bookmark.model'

export class GetBookmarks {
  static readonly type = '[Bookmark] Get Bookmarks'
  constructor(public language: string) {}
}

export class DeleteBookmark {
  static readonly type = '[Bookmark] Delete Bookmark'
  constructor(public id: string) {}
}

export class UpdateBookmark {
  static readonly type = '[Bookmark] Update Bookmark'
  constructor(public id: string, public title: string) {}
}

export class AddBookmark {
  static readonly type = '[Bookmark] Add Bookmark'
  constructor(public bookmark: Bookmark) {}
}
