import { MeFacade } from '@/codices/app/me/services/me.facade'
import { LangFacade } from '@/core/features/lang/services/lang.facade'
import { ENV_TOKEN } from '@/core/features/shared/services/app.injector'
import { AfterContentChecked, Component, inject } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { PrivacyNoticeComponent } from '../privacy-notice/privacy-notice.component'

@Component({
  selector: 'app-codices-header-menu',
  templateUrl: './codices-header-menu.component.html',
  styleUrls: ['./codices-header-menu.component.sass'],
})
export class CodicesHeaderMenuComponent implements AfterContentChecked {
  private meFacade = inject(MeFacade)
  private langFacade = inject(LangFacade)
  private environment = inject(ENV_TOKEN)

  public isLoggedIn = false
  constructor(private dialog: MatDialog) {}

  openPrivacyDialog(): void {
    this.dialog.open(PrivacyNoticeComponent)
  }

  ngAfterContentChecked(): void {
    this.isLoggedIn = this.meFacade.isLoggedIn()
  }

  openHelp(): void {
    window.open(
      this.langFacade.lang === 'fra'
        ? this.environment.helpUrlFra
        : this.environment.helpUrlEng,
      '_blank',
      'noopener',
    )
  }

  connect(): void {
    this.goToConnectionPage()
  }

  disconnect(): void {
    this.meFacade.disconnect()
  }

  goToConnectionPage(): void {
    this.meFacade.redirectCass()
  }
}
