import { BookmarksFacade } from '@/codices/app/features/bookmarks/services/bookmarks.facade'
import { GeoFacade } from '@/core/features/geo/services/geo.facade'
import { Constitution } from '@/core/types/constitution.model'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { TranslationModel } from '@/core/types/translation.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable, map, withLatestFrom } from 'rxjs'
import { ConstitutionApiService } from '../services/constitution-api.service'
import { ConstitutionService } from '../services/constitution.service'
import {
  BookmarkConstitution,
  GetConstitution,
  GetConstitutionViewModel,
  HighlightConstitution,
  SetConstitutionViewModel,
} from './constitution.action'

export class ConstitutionStateModel {
  constitution: Constitution
  constitutionViewModel: ConstitutionViewModel
  languages: LanguageTranslation[]
  selectedLanguage: string
  isBookmarked: boolean
}

export class ConstitutionViewModel {
  country: string
  isBookmarked: boolean
  constitution: TranslationModel
  Nodes: ConstitutionNodeViewModel[]
}

export class ConstitutionNodeViewModel {
  structure: TranslationModel
  articles: TranslationModel[]
}

@State({
  name: 'constitutionState',
  defaults: {
    constitution: null,
    constitutionViewModel: null,
    languages: [],
    isBookmarked: false,
    selectedLanguage: '',
  },
})
@Injectable()
export class ConstitutionState {
  constructor(
    private apiService: ConstitutionApiService,
    private bookmarkFacade: BookmarksFacade,
    private service: ConstitutionService,
    private geoFacade: GeoFacade,
  ) {}
  languageTranslations: LanguageTranslation[] = []

  @Selector()
  static selectConstitutionStateDatas(
    state: ConstitutionStateModel,
  ): Constitution {
    return state.constitution
  }

  @Selector()
  static selectConstitutionViewModelStateDatas(
    state: ConstitutionStateModel,
  ): ConstitutionViewModel {
    return state.constitutionViewModel
  }

  @Selector()
  static selectIsConstitutionBookmarkedDatas(
    state: ConstitutionStateModel,
  ): boolean {
    return state.isBookmarked
  }

  @Selector()
  static selectSelectedLanguage(state: ConstitutionStateModel): string {
    return state.selectedLanguage
  }

  @Selector()
  static selectLanguagesDatas(
    state: ConstitutionStateModel,
  ): LanguageTranslation[] {
    return state.languages
  }

  @Action(GetConstitution)
  getConstitution(
    ctx: StateContext<ConstitutionStateModel>,
    { id, date }: GetConstitution,
  ): Observable<Constitution> {
    return this.apiService.getConstitution(id, date).pipe(
      withLatestFrom(this.bookmarkFacade.bookmarks$, this.geoFacade.countries$),
      map(([constitution, bookmarks, countries]) => {
        const state = ctx.getState()
        const constitutionCopy: Constitution = Constitution.createCopy(
          constitution,
          false,
        )
        if (bookmarks.length > 0) {
          bookmarks.forEach((constitutionLanguages: any) => {
            if (
              id !== null &&
              constitutionLanguages.id.toLocaleUpperCase() ===
                id.toLocaleUpperCase()
            ) {
              constitutionCopy.isBookmarked = true
            }
          })
        }
        constitutionCopy.createBreadCrumb(countries, constitutionCopy.country)
        ctx.patchState({
          ...state,
          constitution: constitutionCopy,
        })
        return constitutionCopy
      }),
    )
  }

  @Action(GetConstitutionViewModel)
  getConstitutionViewModel(
    ctx: StateContext<ConstitutionStateModel>,
    { language }: GetConstitutionViewModel,
  ): void {
    const state = ctx.getState()

    if (state.constitution) {
      const isLanguageAvailable = state.constitution.languages.some(
        (l) => l === language,
      )

      if (!isLanguageAvailable) {
        language = state.constitution.languages[0]
      }

      const viewModel = this.service.getViewModel(language)
      const constitutionLanguages = this.service.getBaseLanguages()

      ctx.patchState({
        ...state,
        constitutionViewModel: viewModel,
        languages: constitutionLanguages,
        selectedLanguage: language,
      })
    }
  }

  @Action(SetConstitutionViewModel)
  setConstitutionViewModel(
    ctx: StateContext<ConstitutionStateModel>,
    { vm }: SetConstitutionViewModel,
  ): void {
    const state = ctx.getState()
    ctx.patchState({
      ...state,
      constitutionViewModel: vm,
    })
  }

  @Action(BookmarkConstitution)
  bookmarkConstitution(
    ctx: StateContext<ConstitutionStateModel>,
    { constitution, language }: BookmarkConstitution,
  ): void {
    const state = ctx.getState()
    const constitutionCopy: Constitution = Constitution.createCopy(
      constitution,
      !constitution.isBookmarked,
    )
    if (constitutionCopy.isBookmarked) {
      this.bookmarkFacade.addBookmark(constitutionCopy.toBookmark(language))
    } else {
      this.bookmarkFacade.deleteBookmark(constitutionCopy.constitutionId)
    }
    ctx.patchState({
      ...state,
      constitution: constitutionCopy,
    })
  }

  @Action(HighlightConstitution)
  highlightConstitution(
    ctx: StateContext<ConstitutionStateModel>,
    { queryWords, resultIntent }: HighlightConstitution,
  ): void {
    const state = ctx.getState()
    if (queryWords != null && resultIntent != null && queryWords.length > 0) {
      const vm: ConstitutionViewModel = this.service.highlight(queryWords)
      ctx.patchState({
        ...state,
        constitutionViewModel: vm,
      })
    }
  }
}
