import { SearchTypes } from '@/codices/app/data/models/search-types.enum'

export class SetQueryWords {
  static readonly type = '[Highlight] Set query words'
  constructor(public queryWords: string[], public highlightType: SearchTypes) {}
}

export class GetTaxon {
  static readonly type = '[Search Taxon] Get a taxon'
  constructor(public id: string | null) {}
}
export class SetHighlightIds {
  static readonly type = '[Highlight] Set ids for highlight'
  constructor(public ids: string[]) {}
}

export class CleanHighlightIds {
  static readonly type = '[Highlight] Clean ids for highlight'
}

export class SetCurrentWord {
  static readonly type = '[Highlight] Set current highlighted word'
  constructor(public id: string) {}
}

export class GoToNextWord {
  static readonly type = '[Highlight] Go to next highlighted word'
  constructor(
    public id: string,
    public index: number,
    public isFirstNext: boolean,
  ) {}
}

export class GoToPreviousWord {
  static readonly type = '[Highlight] Go to previous highlighted word'
  constructor(public id: string, public index: number) {}
}
