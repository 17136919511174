import { BaseDocumentClass } from '@/core/types/base-document-class.model'
import { Bookmark } from '@/core/types/bookmark.model'
import { Bookmarkable } from '@/core/types/bookmarkable.inteface'
import { plainToClass } from 'class-transformer'
import './custom-string'
import { FullTextTranslation } from './fulltext-translation.model'
import { LanguageCodes } from './language-code.enum'
import { PrecisDto } from './precis'

export class FullText extends BaseDocumentClass implements Bookmarkable {
  id: string
  country: string
  filePath: string
  precis: PrecisDto
  isBookmarked: boolean
  bookmarkOrder: number
  languages: string[]
  precisReferenceCode: string
  precisDate: string
  precisNumber: string
  fullTextTranslations: Map<string, FullTextTranslation>

  static fromJson(obj: any): FullText {
    const result: FullText = { ...obj } as FullText
    result.precisReferenceCode = result.precis.referenceCode
    result.precisDate = new Date(result.precis.decisionDate)
      .toLocaleDateString()
      .changeToDateStringFormat()
    return plainToClass(FullText, result)
  }

  public getUrlFromFullText(fullText: FullText, language: string): string {
    let url = ''

    const fullTextTranslations = new Map(
      Object.entries(this.fullTextTranslations),
    )
    let translation = fullTextTranslations.get(language.toLowerCase())
    if (translation) {
      url = translation.filePath
    }
    if (url === '' && language.toLocaleLowerCase() === LanguageCodes.FR) {
      translation = fullTextTranslations.get(LanguageCodes.EN)
      if (translation) {
        url = translation.filePath
      }
    }
    if (url === '' && language.toLocaleLowerCase() === LanguageCodes.EN) {
      translation = fullTextTranslations.get(LanguageCodes.FR)
      if (translation) {
        url = translation.filePath
      }
    }
    if (url === '' && language.toLocaleLowerCase() === '') {
      url = ''
    }
    return url
  }

  createBookmarkTitle(language: string): string {
    let title = ''

    const precisTranslations = new Map(
      Object.entries(this.precis.precisTranslations),
    )
    const precisTranslation = precisTranslations.get(language.toLowerCase())

    const decisionNumber = precisTranslation
      ? precisTranslation?.decisionNumber
      : precisTranslations?.entries()?.next()?.value?.decisionNumber
    title += `${this.precis.referenceCode} ${this.transformDateBookmarkTitle(
      this.precis.decisionDate,
    )} ${decisionNumber}`
    return title
  }

  toBookmark(language: string): Bookmark {
    return new Bookmark(
      this.id,
      'FULLTEXT',
      this.createBookmarkTitle(language),
      this.bookmarkOrder,
      this.breadCrumb,
    )
  }

  private transformDateBookmarkTitle(date: any): string {
    return new Date(date).toLocaleDateString().replace(/\//g, '.')
  }

  static createCopy(fullText: FullText, isBookmarked: boolean): FullText {
    const fulltextCopy: FullText = FullText.fromJson(
      Object.assign({}, FullText.fromJson(fullText)),
    )
    fulltextCopy.isBookmarked = isBookmarked
    return fulltextCopy
  }
}
