import { AlphaIndexDto } from '@/codices/app/data/models/alpha-index.model'
import { Result } from '@/codices/app/data/models/result.model'
import { ThesaurusTree } from '@/codices/app/data/models/thesaurus-tree.model'
import { ApiService } from '@/core/features/shared/services/api.service'
import { SearchModel } from '@/core/types/search.model'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

const ALPHA_INDEX_LINKED_TO_PRECIS_ENDPOINT = 'taxon/linked-precis'
const PRECIS_LINKED_TO_ARTICLE_ENDPOINT = 'constitution/linked-precis'
const THESAURUS_ENDPOINT = 'thesaurus'
const ALPHA_INDEX_ENDPOINT = 'alphaindex'
const SEARCH_ENDPOINT = 'search'

@Injectable()
export class SearchApiService {
  constructor(private apiService: ApiService) {}

  getAlphaIndexes(
    alphaIndex: string,
    selectedLanguage: string | undefined,
  ): Observable<AlphaIndexDto> {
    return this.apiService
      .get(`${ALPHA_INDEX_ENDPOINT}`, {
        contains: alphaIndex,
        languageCode: selectedLanguage,
      })
      .pipe(
        map((value) => {
          return AlphaIndexDto.fromJson(value)
        }),
      )
  }

  getThesaurus(): Observable<ThesaurusTree> {
    return this.apiService.get(`${THESAURUS_ENDPOINT}`).pipe(
      map((value) => {
        return ThesaurusTree.fromJson(value)
      }),
    )
  }

  searchAlphaIndex(
    alphaIndexId: string,
    taxonId: string,
    page: number,
    size: number,
  ): Observable<Result> {
    return this.apiService
      .get(`${ALPHA_INDEX_LINKED_TO_PRECIS_ENDPOINT}`, {
        alphaIndexId: alphaIndexId,
        taxonId: taxonId,
        page: page,
        size: size,
      })
      .pipe(
        map((value) => {
          return Result.fromJson(value)
        }),
      )
  }

  advancedSearch(searchModel: SearchModel): Observable<Result> {
    return this.apiService.post(`${SEARCH_ENDPOINT}`, searchModel as any).pipe(
      map((value) => {
        return Result.fromJson(value)
      }),
    )
  }

  searchPrecisLinkedToArticle(articleId: string | null): Observable<Result> {
    return this.apiService
      .get(`${PRECIS_LINKED_TO_ARTICLE_ENDPOINT}`, {
        id: articleId ?? undefined,
      })
      .pipe(
        map((value) => {
          return Result.fromJson(value)
        }),
      )
  }
}
