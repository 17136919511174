import { CountryVM } from '@/core/types/country.model'
import { Groups } from '@/core/types/groups.model'
import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { GeoFacade } from '../../geo/services/geo.facade'
import { GeoState } from '../../geo/state/geo.state'
import { LangChangeLang } from '../../lang/state/lang.action'
import { LangState } from '../../lang/state/lang.state'
import { SetLastVisitedLink } from '../state/core-shared.action'
import { CoreSharedState } from '../state/core-shared.state'

@Injectable()
export class CoreSharedFacade {
  @Select(LangState.lang)
  language$: Observable<string>

  constructor(private store: Store, private geoFacade: GeoFacade) {}

  public getCodicesLanguage(): string {
    const lang = this.store.selectSnapshot(LangState.lang)
    return lang
  }

  public getGroups(): Groups[] {
    const groups = this.store.selectSnapshot(GeoState.courtGroups)
    return groups
  }

  public getCountriesObs(): Observable<CountryVM[]> {
    return this.geoFacade.countriesVM$
  }

  public getCodicesLanguageFromStorage(): string | null {
    return localStorage.getItem('language')
  }

  public changesLanguages(selectedLanguage: string): void {
    this.store.dispatch(
      new LangChangeLang(selectedLanguage === 'fra' ? 'fra' : 'eng'),
    )
  }

  public getLastVisitedLink(): string {
    return this.store.selectSnapshot(CoreSharedState.selectLastVisitedLink)
  }

  public setLastVisitedLink(url: string): void {
    this.store.dispatch(new SetLastVisitedLink(url))
  }
}
