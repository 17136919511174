import { HighlightFacade } from '@/codices/app/features/results/services/highlight.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { LawStructure } from '@/core/types/law-structure.model'
import { TranslationModel } from '@/core/types/translation.model'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import {
  DocumentGetViewModel,
  DocumentHighlight,
} from '../../services/documents.service'
import { LawStateModel, LawViewModel } from '../state/law.state'

@Injectable()
export class LawService implements DocumentHighlight, DocumentGetViewModel {
  constructor(private highlightFacade: HighlightFacade, private store: Store) {}

  highlight(queryWords: string[]): any {
    const vm = this.store.snapshot().lawState.lawViewmodel as LawViewModel

    const vmCopy: LawViewModel = JSON.parse(JSON.stringify(vm))

    this.highlightFacade.cleanHighlightIds()
    this.highlightFacade.ids = []

    vmCopy.law.title = this.highlightFacade.highlightText(
      queryWords,
      vmCopy?.law?.title,
    )
    vmCopy.law.text = this.highlightFacade.highlightText(
      queryWords,
      vmCopy?.law?.text,
    )
    vmCopy?.structure.map((structure) => {
      structure.text = this.highlightFacade.highlightText(
        queryWords,
        structure?.text,
      )
      structure.title = this.highlightFacade.highlightText(
        queryWords,
        structure?.title,
      )
      return structure
    })
    this.highlightFacade.setHighlightIds(this.highlightFacade.ids)
    return vmCopy
  }

  getViewModel(selectedLang: string): any {
    const lawState = this.store.snapshot().lawState as LawStateModel

    const lawViewModel = new LawViewModel()

    const translations = new Map(Object.entries(lawState.law.lawTranslations))

    if (translations) {
      const lawTranslations = translations.get(selectedLang)
      if (lawTranslations) {
        lawViewModel.law = {
          id: 'a' + lawState.law.id,
          title: lawTranslations.title,
          text: lawTranslations.text,
          languageCode: selectedLang,
          countryName: lawState.law.country,
          note: lawTranslations.note ? lawTranslations.note : '',
        }
      }
    }

    let structureTranslation: TranslationModel[] = []
    if (lawState.law.lawStructures && lawState.law.lawStructures.length > 0) {
      lawState.law.lawStructures.forEach((structure) => {
        structureTranslation = this.buildLawStructureViewModelRecursive(
          structure,
          selectedLang,
          structureTranslation,
        )
      })

      lawViewModel.structure = structureTranslation
    }
    return lawViewModel
  }

  getBaseLanguages(): LanguageTranslation[] {
    const lawState = this.store.snapshot().lawState as LawStateModel
    const languages: LanguageTranslation[] = []
    lawState.law.languages.forEach((lawLanguage) => {
      languages.push({
        name: '',
        languageTranslationCode: lawLanguage,
        languageCode: '',
      })
    })
    return languages
  }

  private buildLawStructureViewModelRecursive(
    lawStructure: LawStructure,
    selectedLanguage: string,
    translations: TranslationModel[],
  ): TranslationModel[] {
    const lawStructureTranslations = new Map(
      Object.entries(lawStructure.lawStructureTranslations),
    )

    if (lawStructureTranslations) {
      const lawStructureTranslation =
        lawStructureTranslations.get(selectedLanguage)
      if (lawStructureTranslation) {
        const structureTranslation = {
          id: 'a' + lawStructure.id,
          title: lawStructureTranslation.title,
          text: lawStructureTranslation.text,
          languageCode: selectedLanguage,
          note: lawStructureTranslation.note
            ? lawStructureTranslation.note
            : '',
        }
        translations.push(structureTranslation)
      }
    }
    if (lawStructure.children && lawStructure.children.length > 0) {
      lawStructure.children.forEach((child) =>
        this.buildLawStructureViewModelRecursive(
          child,
          selectedLanguage,
          translations,
        ),
      )
    }
    return translations
  }
}
