import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable, tap } from 'rxjs'
import { HighlightService } from '../services/highlight.service'
import {
  CleanHighlightIds,
  GetTaxon,
  GoToNextWord,
  GoToPreviousWord,
  SetHighlightIds,
  SetQueryWords,
} from './highlight.action'

export class HighlightStateModel {
  queryWords: string[]
  ids: string[]
  currentWordId: string
  indexCurrentWord: number
  firstNext: boolean
  firstPrevious: boolean
  highlightType: SearchTypes
  taxon: TaxonDto | null
}

@State({
  name: 'highlightState',
  defaults: {
    queryWords: [],
    ids: [],
    currentWordId: '',
    indexCurrentWord: 0,
    firstNext: true,
    highlightType: '',
    taxon: null,
  },
})
@Injectable()
export class HighlightState {
  constructor(private service: HighlightService) {}

  @Selector()
  static selectQueryWords(state: HighlightStateModel): any {
    return state.queryWords
  }
  @Selector()
  static selectIds(state: HighlightStateModel): any {
    return state.ids
  }
  @Selector()
  static selectCurrentWord(state: HighlightStateModel): any {
    return state.currentWordId
  }
  @Selector()
  static selectIndexCurrentWord(state: HighlightStateModel): any {
    return state.indexCurrentWord
  }
  @Selector()
  static selectFirstNext(state: HighlightStateModel): any {
    return state.firstNext
  }
  @Selector()
  static selectTaxon(state: HighlightStateModel): any {
    return state.taxon
  }
  @Selector()
  static selectHighlightType(state: HighlightStateModel): any {
    return state.highlightType
  }

  @Action(SetQueryWords)
  setQueryWords(
    highlightStateContext: StateContext<HighlightStateModel>,
    { queryWords, highlightType }: SetQueryWords,
  ): void {
    const state = highlightStateContext.getState()
    highlightStateContext.patchState({
      ...state,
      queryWords,
      highlightType,
      ids: [],
    })
  }

  @Action(SetHighlightIds)
  setIds(
    highlightStateContext: StateContext<HighlightStateModel>,
    { ids }: SetHighlightIds,
  ): void {
    const state = highlightStateContext.getState()
    if (state.ids.length > 0) {
      let idsCopy = [...state.ids]
      idsCopy = idsCopy.concat(ids)
      highlightStateContext.patchState({
        ...state,
        ids: idsCopy,
      })
    } else {
      highlightStateContext.patchState({
        ...state,
        ids,
      })
    }
  }

  @Action(CleanHighlightIds)
  cleanHighlightIds(
    highlightStateContext: StateContext<HighlightStateModel>,
  ): void {
    const state = highlightStateContext.getState()
    highlightStateContext.patchState({
      ...state,
      ids: [],
    })
  }

  @Action(GoToNextWord)
  goToNextWord(
    highlightStateContext: StateContext<HighlightStateModel>,
    { id, index, isFirstNext }: GoToNextWord,
  ): void {
    const state = highlightStateContext.getState()
    highlightStateContext.patchState({
      ...state,
      indexCurrentWord: index,
      currentWordId: id,
      firstNext: isFirstNext,
    })
  }

  @Action(GoToPreviousWord)
  goToPreviousWord(
    highlightStateContext: StateContext<HighlightStateModel>,
    { id, index }: GoToPreviousWord,
  ): void {
    const state = highlightStateContext.getState()
    highlightStateContext.patchState({
      ...state,
      indexCurrentWord: index,
      currentWordId: id,
    })
  }

  @Action(GetTaxon)
  getTaxon(
    highlightStateContext: StateContext<HighlightStateModel>,
    { id }: GetTaxon,
  ): Observable<TaxonDto> {
    return this.service.getTaxon(id).pipe(
      tap((taxon: any) => {
        const state = highlightStateContext.getState()
        highlightStateContext.patchState({
          ...state,
          taxon,
        })
      }),
    )
  }
}
