import { User } from '@/core/types/user.model'

export class SetAccessToken {
  static readonly type = '[Me] Set Access Token'
  constructor(public accessToken: string) {}
}

export class GetMe {
  static readonly type = '[Me] Get Me'
}

export class SetMe {
  static readonly type = '[Me] Set Me'
  constructor(public user: User | null) {}
}

export class SetIsFromUnsubscribe {
  static readonly type = '[Me] Set If User Come From Unsubscribe'
  constructor(
    public isFromUnsubscribe: boolean,
    public idUnsubscribe: string,
  ) {}
}
