import { EntityApiService } from '@/core/features/shared/services/entity-api.service'
import { User } from '@/core/types/user.model'
import { Injectable } from '@angular/core'

@Injectable()
export class UsersApiService extends EntityApiService<User> {
  constructor() {
    super('users')
  }
}
