import { HighlightFacade } from '@/codices/app/features/results/services/highlight.facade'
import { ConstitutionNode } from '@/core/types/constitution-nodes.model'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import {
  DocumentGetViewModel,
  DocumentHighlight,
} from '../../services/documents.service'
import {
  ConstitutionNodeViewModel,
  ConstitutionStateModel,
  ConstitutionViewModel,
} from '../state/constitution.state'

@Injectable()
export class ConstitutionService
  implements DocumentGetViewModel, DocumentHighlight
{
  constructor(private store: Store, private highlightFacade: HighlightFacade) {}

  getViewModel(selectedLang: string): any {
    const constitutionState = this.store.snapshot()
      .constitutionState as ConstitutionStateModel
    const constitutionViewModel = new ConstitutionViewModel()
    constitutionViewModel.country = constitutionState.constitution.country

    const translations = constitutionState.constitution.constitutionTranslations

    if (translations) {
      const constitutionTranslation = translations[selectedLang]
      if (constitutionTranslation) {
        constitutionViewModel.constitution = {
          id: 'a' + constitutionState.constitution.id,
          title: constitutionTranslation.title,
          text: constitutionTranslation.text,
          countryName: constitutionTranslation.countryName,
          languageCode: selectedLang,
          note: constitutionTranslation.note
            ? constitutionTranslation.note
            : '',
          titleNote: constitutionTranslation.titleNote
            ? constitutionTranslation.titleNote
            : '',
        }
      }
    }

    constitutionViewModel.Nodes = []
    let constitutionViewModelNodes: ConstitutionNodeViewModel[] = []
    constitutionState.constitution.nodes.forEach((node) => {
      constitutionViewModelNodes = this.buildConstitutionNodeViewModel(
        node,
        selectedLang,
        constitutionViewModelNodes,
      )
    })

    constitutionViewModel.Nodes = constitutionViewModelNodes
    return constitutionViewModel
  }

  getBaseLanguages(): LanguageTranslation[] {
    const languages: LanguageTranslation[] = []
    const constitutionState = this.store.snapshot()
      .constitutionState as ConstitutionStateModel
    constitutionState.constitution.languages.forEach((constitutionLanguage) => {
      languages.push({
        name: '',
        languageTranslationCode: constitutionLanguage,
        languageCode: '',
      })
    })
    return languages
  }

  highlight(queryWords: string[]): any {
    const vm = this.store.snapshot().constitutionState
      .constitutionViewModel as ConstitutionViewModel
    const vmCopy: ConstitutionViewModel = JSON.parse(JSON.stringify(vm))
    this.highlightFacade.cleanHighlightIds()
    this.highlightFacade.ids = []

    vmCopy.constitution.title = this.highlightFacade.highlightText(
      queryWords,
      vmCopy?.constitution?.title,
    )

    vmCopy.constitution.text = this.highlightFacade.highlightText(
      queryWords,
      vmCopy?.constitution?.text,
    )
    vmCopy?.Nodes.map((node) => {
      if (node.structure) {
        node.structure.title = this.highlightFacade.highlightText(
          queryWords,
          node?.structure?.title,
        )
      }
      node.articles.forEach((article) => {
        article.title = this.highlightFacade.highlightText(
          queryWords,
          article?.title,
        )
        article.text = this.highlightFacade.highlightText(
          queryWords,
          article?.text,
        )
      })
      return node
    })

    this.highlightFacade.setHighlightIds(this.highlightFacade.ids)
    return vmCopy
  }

  private buildConstitutionNodeViewModel(
    node: ConstitutionNode,
    language: string,
    existingNodes: ConstitutionNodeViewModel[],
  ): ConstitutionNodeViewModel[] {
    const constitutionNodeViewModel = new ConstitutionNodeViewModel()

    const translations =
      node.constitutionStructure.constitutionStructureTranslations

    const structureTranslation = translations[language]
    if (structureTranslation) {
      constitutionNodeViewModel.structure = {
        id: 'a' + node.id,
        title: structureTranslation.title,
        text: structureTranslation.text,
        countryName: '',
        languageCode: language,
        note: structureTranslation.note ? structureTranslation.note : '',
      }
    }

    if (constitutionNodeViewModel.structure != null || translations) {
      constitutionNodeViewModel.articles = []
      node.constitutionStructure.constitutionArticles.forEach((article) => {
        const articleTranslations = article.constitutionArticleTranslations

        const articleTranslation = articleTranslations[language]
        if (articleTranslation) {
          constitutionNodeViewModel.articles.push({
            id: 'a' + article.id,
            title: articleTranslation.title,
            text: articleTranslation.text,
            countryName: '',
            languageCode: language,
            note: articleTranslation.note ? articleTranslation.note : '',
            hasLink: article.hasLink,
          })
        }
      })

      existingNodes.push(constitutionNodeViewModel)
    }

    if (node.children.length > 0) {
      node.children.forEach((child) => {
        existingNodes = this.buildConstitutionNodeViewModel(
          child,
          language,
          existingNodes,
        )
      })
    }
    return existingNodes
  }
}
