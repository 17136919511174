import { BaseEntity } from '@/core/types/base-entity.model'
import { ListResult } from '@/core/types/list-result.model'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { JsonObject, PartialDeep } from 'type-fest'
import { ApiService, Params } from './api.service'

@Injectable()
export abstract class EntityApiService<
  TEntity extends BaseEntity,
  TEntityData extends PartialDeep<JsonObject> = TEntity,
> {
  /* DI */
  protected api = inject(ApiService)

  /* Private properties */
  private _endpoint: string

  /* Constructor */
  constructor(endpoint: string) {
    this._endpoint = endpoint
  }

  /* Public methods */
  get(params?: Params): Observable<ListResult<TEntity>>
  get(id: string, params?: Params): Observable<TEntity>
  get(
    idOrParams?: string | Params,
    params?: Params,
  ): Observable<TEntity> | Observable<ListResult<TEntity>> {
    if (typeof idOrParams === 'string') {
      const id = idOrParams
      return this.api.get<TEntity>(`${this._endpoint}/${id}`, params)
    }
    return this.api.get<ListResult<TEntity>>(this._endpoint, idOrParams)
  }

  create(entity: Partial<TEntityData>): Observable<TEntity> {
    return this.api.post<TEntity>(this._endpoint, entity)
  }

  update(
    entity: Partial<TEntityData> & Pick<TEntity, 'id'>,
  ): Observable<TEntity> {
    return this.api.put<TEntity>(this._endpoint, entity)
  }

  patch(
    entity: Partial<TEntityData> & Pick<TEntity, 'id'>,
  ): Observable<TEntity> {
    return this.api.patch<TEntity>(`${this._endpoint}/${entity.id}`, entity)
  }

  delete(id: string): Observable<void> {
    return this.api.delete<void>(`${this._endpoint}/${id}`)
  }
}
