import { Injectable } from '@angular/core'
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translateSevice: TranslateService) {
    super()
    this.getAndInitTranslations()
  }

  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ): string => {
    if (length == 0 || pageSize == 0) {
      return this.translateSevice.instant('paginationNoIndexLabel', {
        length: length,
      })
    }
    length = Math.max(length, 0)
    const startIndex = page * pageSize
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize
    return this.translateSevice.instant('paginationIndexLabel', {
      startIndex: startIndex,
      endIndex: endIndex,
      length: length,
    })
  }

  getAndInitTranslations(): void {
    this.itemsPerPageLabel = this.translateSevice.instant('paginationLabel')
    this.getRangeLabel = this.getRangeLabel
    this.changes.next()
  }
}
