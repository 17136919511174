import { EntityApiService } from '@/core/features/shared/services/entity-api.service'
import { User } from '@/core/types/user.model'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

const ENDPOINT = 'account'

@Injectable({
  providedIn: 'root',
})
export class MeApiService extends EntityApiService<User> {
  constructor() {
    super(ENDPOINT)
  }

  getAccount(): Observable<User> {
    return this.api.get(ENDPOINT)
  }
}
