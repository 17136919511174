import { CoreSharedFacade } from '@/core/features/core-shared/services/core-shared.facade'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'
import { Observable, switchMap, take } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AppResourcesService {
  assetsPath = './assets/i18n/privacy-notice/privacy-notice.LANGUAGE.html'

  constructor(private http: HttpClient, private facade: CoreSharedFacade) {}

  getLatestHtmlResource(): Observable<{ html: SafeHtml }> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentLanguage = this.facade.getCodicesLanguage()
    let path = this.assetsPath
    if (currentLanguage) {
      path = this.assetsPath.replace('LANGUAGE', currentLanguage.toLowerCase())
    } else {
      path = this.assetsPath.replace('LANGUAGE', 'eng')
    }

    return this.http.get(path, { responseType: 'blob' }).pipe(
      switchMap((res) => {
        return new Observable<{ html: SafeHtml }>((observer$) => {
          const reader = new FileReader()
          reader.onloadend = function (): void {
            const result = this.result as string
            observer$.next({
              html: result,
            })
          }
          if (res) {
            reader.readAsText(res)
          }
        })
      }),
      take(1),
    )
  }
}
