/**
 * Fetch all languages from API
 */
export class GeoFetchLanguages {
  static readonly type = '[Geo] FetchLanguages'
}

/**
 * Fetch all countries from API
 */
export class GeoFetchCountries {
  static readonly type = '[Geo] FetchCountries'
}

/**
 * Fetch all court groups from API
 */
export class GeoFetchCourtGroups {
  static readonly type = '[Geo] FetchCourtGroups'
}

/**
 * Generate countriesVM from countries
 * @param lang - Language to use
 */
export class GeoGenerateCountriesVM {
  static readonly type = '[Geo] GenerateCountriesVM'
  constructor(public lang: string) {}
}

/**
 * Get all languages from state (cache) or API (if cache is empty)
 * @param revalidate - Force API call
 */
export class GeoGetLanguages {
  static readonly type = '[Geo] GetAllLanguages'
  constructor(public revalidate = false) {}
}

/**
 * Get all countries from state (cache) or API (if cache is empty)
 * @param revalidate - Force API call
 */
export class GeoGetCountries {
  static readonly type = '[Geo] GetAllCountries'
  constructor(public revalidate = false) {}
}

/**
 * Get all court groups from state (cache) or API (if cache is empty)
 * @param revalidate - Force API call
 */
export class GeoGetCourtGroups {
  static readonly type = '[Geo] GetAllCourtGroups'
  constructor(public revalidate = false) {}
}
