import { environment } from '@/core/environment'
import { Alert } from '@/core/types/alert.model'
import { EntitiesStateModelWithPage } from '@/core/types/entities-state-model'
import { ListResult } from '@/core/types/list-result.model'
import { Injectable, inject } from '@angular/core'
import { Action, State, StateContext, StateToken } from '@ngxs/store'
import { Observable, catchError, map, switchMap } from 'rxjs'
import { EntitiesStateWithPage } from '../../../state/entities.state'
import { AlertApiService } from '../alert.service'
import {
  AlertsChangePage,
  AlertsDeleteById,
  AlertsFetchAll,
} from './alert.action'

export type AlertsStateModel = EntitiesStateModelWithPage<Alert>

export const ALERTS_STATE_TOKEN = new StateToken<AlertsStateModel>('alerts')

@State<AlertsStateModel>({
  name: ALERTS_STATE_TOKEN,
  defaults: {
    entities: [],
    totalCount: 0,
    page: {
      index: environment.entity.defaultPageIndex,
      size: environment.entity.defaultPageSize,
    },
    isLoading: false,
  },
})
@Injectable()
export class AlertsState extends EntitiesStateWithPage {
  private api = inject(AlertApiService)

  @Action(AlertsFetchAll, { cancelUncompleted: true })
  fetchAll(ctx: StateContext<AlertsStateModel>): Observable<ListResult<Alert>> {
    const state = ctx.getState()
    ctx.patchState({
      isLoading: true,
    })

    return this.api.get({ page: state.page.index, size: state.page.size }).pipe(
      map((res) => {
        const state = ctx.getState()
        ctx.patchState({
          ...state,
          entities: res.listResult,
          totalCount: res.totalResultCount,
          isLoading: false,
        })
        return res
      }),
    )
  }

  @Action(AlertsChangePage)
  changePage(
    ctx: StateContext<AlertsStateModel>,
    { page }: AlertsChangePage,
  ): void {
    const { page: currPage } = ctx.getState()

    ctx.patchState({
      page: {
        ...currPage,
        ...page,
      },
    })

    ctx.dispatch(new AlertsFetchAll())
  }

  @Action(AlertsDeleteById)
  deleteAlertById(
    ctx: StateContext<AlertsDeleteById>,
    { id }: AlertsDeleteById,
  ): Observable<void> {
    return this.api.deleteAlert(id).pipe(
      catchError((err) => {
        throw err
      }),
      switchMap(() => {
        return ctx.dispatch(new AlertsFetchAll())
      }),
    )
  }
}
