import { HighlightFacade } from '@/codices/app/features/results/services/highlight.facade'
import { Injectable } from '@angular/core'

import { Store } from '@ngxs/store'
import { DocumentHighlight } from '../../services/documents.service'
import { FullTextStateModel } from '../state/fulltext.state'

@Injectable()
export class FulltextService implements DocumentHighlight {
  constructor(private store: Store, private highlightFacade: HighlightFacade) {}

  highlight(queryWords: string[]): any {
    const fulltextState = this.store.snapshot()
      .fullTextState as FullTextStateModel
    let htmlTextCopy: string = JSON.parse(
      JSON.stringify(fulltextState.htmlText),
    )

    this.highlightFacade.cleanHighlightIds()
    this.highlightFacade.ids = []

    htmlTextCopy = this.highlightFacade.highlightText(queryWords, htmlTextCopy)
    this.highlightFacade.setHighlightIds(this.highlightFacade.ids)
    return htmlTextCopy
  }
}
