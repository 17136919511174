import { CodicesTree } from '@/codices/app/data/models/codices-tree.model'
import { TreesApiService } from '@/codices/app/features/shared/tree/services/trees-api.service'
import { TreeNode } from '@/core/types/tree-node.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import {
  GetSearchTree,
  SetSearchTreeArray,
} from './advanced-search-tree.action'

export class AdvancedSearchTreeStateModel {
  treeNodes: TreeNode[]
  searchTreeArr: string[]
}

@State({
  name: 'advancedSearchTreeState',
  defaults: {
    treeNodes: [],
    searchTreeArr: [],
  },
})
@Injectable()
export class AdvancedSearchTreeState {
  constructor(private service: TreesApiService) {}
  @Selector()
  static selectSearchTreeNodes(
    state: AdvancedSearchTreeStateModel,
  ): TreeNode[] {
    return state.treeNodes
  }
  @Selector()
  static selectSearchTreeArr(state: AdvancedSearchTreeStateModel): string[] {
    return state.searchTreeArr
  }

  @Action(GetSearchTree)
  getSearchTree(
    searchTreeStateContext: StateContext<AdvancedSearchTreeStateModel>,
  ): Observable<CodicesTree> {
    return this.service.getCodicesTree(null, null, null).pipe(
      tap((results: CodicesTree) => {
        const state = searchTreeStateContext.getState()
        const searchTree = results as CodicesTree
        const nodesArray = searchTree.toArrayNoIntro()
        searchTreeStateContext.patchState({
          ...state,
          treeNodes: nodesArray,
        })
      }),
    )
  }

  @Action(SetSearchTreeArray)
  setSearchTreeArray(
    searchTreeStateContext: StateContext<AdvancedSearchTreeStateModel>,
    { searchTreeArr }: SetSearchTreeArray,
  ): void {
    const state = searchTreeStateContext.getState()
    searchTreeStateContext.patchState({
      ...state,
      searchTreeArr,
    })
  }
}
