import { LiaisonOfficers } from '@/core/types/liaison-officers.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable, tap } from 'rxjs'
import { LiaisonAgentsApiService } from '../services/liaison-agents-api.service'
import { GetLiaisonAgents } from './liaison-agents.action'

export class LiaisonAgentsStateModel {
  liaisonAgents: LiaisonOfficers[]
}

@State({
  name: 'liaisonAgentsState',
  defaults: {
    liaisonAgents: [],
  },
})
@Injectable()
export class LiaisonAgentsState {
  constructor(private service: LiaisonAgentsApiService) {}

  @Selector()
  static selectLiaisonAgents(
    state: LiaisonAgentsStateModel,
  ): LiaisonOfficers[] {
    return state.liaisonAgents
  }

  @Action(GetLiaisonAgents)
  getLiaisonAgents(
    liaisonAgentsStateContext: StateContext<LiaisonAgentsStateModel>,
    { language }: GetLiaisonAgents,
  ): Observable<LiaisonOfficers[]> {
    return this.service.getLiaisonAgents().pipe(
      tap((liasonAgents: LiaisonOfficers[]) => {
        const state = liaisonAgentsStateContext.getState()
        liaisonAgentsStateContext.patchState({
          ...state,
          liaisonAgents: liasonAgents,
        })
      }),
    )
  }
}
