import { AlphaIndexDto } from '@/codices/app/data/models/alpha-index.model'
import { Result } from '@/codices/app/data/models/result.model'
import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import { ThesaurusTranslation } from '@/codices/app/data/models/thesaurus-translation.model'
import { ThesaurusTree } from '@/codices/app/data/models/thesaurus-tree.model'
import { ThesaurusDto } from '@/codices/app/data/models/thesaurus.model'
import { SearchModel } from '@/core/types/search.model'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { parse, stringify } from 'flatted'
import { Observable, catchError, map, tap, withLatestFrom } from 'rxjs'
import { HighlightFacade } from '../../results/services/highlight.facade'
import { ResultsFacade } from '../../results/services/results.facade'
import { HighlightAdvancedSearchService } from '../services/highlight-advanced-search.service'
import { SearchApiService } from '../services/search-api.service'
import {
  AdvancedSearch,
  GetAlphaIndexes,
  GetAlphaIndexesViewModel,
  GetSearchThesaurus,
  SearchAlphaIndex,
  SearchPrecisLinkedToArticle,
  ThesaurusLanguage,
} from './search.action'

export class SearchStateModel {
  thesaurus: ThesaurusTree[]
  alphaIndexes: AlphaIndexDto[]
  alphaIndexViewModel: AlphaIndexViewModel
  isLoading: boolean
}

export class AlphaIndexViewModel {
  alphaIndexTaxonsArray: TaxonDto[][]
  thesaurusReferences: ThesaurusDto[][]
  alphaIndexReferences: TaxonDto[][]
}

@State({
  name: 'searchState',
  defaults: {
    thesaurus: [],
    alphaIndexes: [],
    alphaIndexViewModel: null,
    isLoading: false,
  },
})
@Injectable()
export class SearchState {
  constructor(
    private resultFacade: ResultsFacade,
    private highlightFacade: HighlightFacade,
    private service: SearchApiService,
    private highlighAdvancedSearchService: HighlightAdvancedSearchService,
  ) {}

  @Selector()
  static selectSearchThesaurusStateDatas(
    state: SearchStateModel,
  ): ThesaurusTree[] {
    return state.thesaurus
  }

  @Selector()
  static selectAlphaIndexesViewModel(
    state: SearchStateModel,
  ): AlphaIndexViewModel {
    return state.alphaIndexViewModel
  }

  @Selector()
  static selectAlphaIndexes(state: SearchStateModel): AlphaIndexDto[] {
    return state.alphaIndexes
  }

  @Selector()
  static selectIsLoading(state: SearchStateModel): boolean {
    return state.isLoading
  }

  @Action(GetSearchThesaurus)
  getSearchThesaurus(
    thesaurusStateContext: StateContext<SearchStateModel>,
    { selectedLanguage }: GetSearchThesaurus,
  ): Observable<ThesaurusTree> {
    return this.service.getThesaurus().pipe(
      tap((results: any) => {
        const state = thesaurusStateContext.getState()
        const thesaurusTree = this.getRecursiveTranslations(
          selectedLanguage,
          results,
        )
        thesaurusTree.forEach((thesaurusTreeChild) => {
          this.getParents(thesaurusTreeChild)
        })
        thesaurusStateContext.setState({
          ...state,
          thesaurus: thesaurusTree,
        })
      }),
    )
  }

  @Action(ThesaurusLanguage)
  changeThesaurusLanguage(
    thesaurusStateContext: StateContext<SearchStateModel>,
    { selectedLanguage }: ThesaurusLanguage,
  ): void {
    const state = thesaurusStateContext.getState()
    const thesaurusCopy: ThesaurusTree[] = parse(stringify(state.thesaurus))
    const thesaurusTree = this.getRecursiveTranslations(
      selectedLanguage,
      thesaurusCopy,
    )
    thesaurusStateContext.setState({
      ...state,
      thesaurus: thesaurusTree,
    })
  }

  @Action(GetAlphaIndexes)
  getAlphaIndexes(
    alphaIndexStateContext: StateContext<SearchStateModel>,
    { alphaIndex, selectedLanguage }: GetAlphaIndexes,
  ): Observable<AlphaIndexDto> {
    return this.service.getAlphaIndexes(alphaIndex, selectedLanguage).pipe(
      tap((results: any) => {
        const state = alphaIndexStateContext.getState()
        alphaIndexStateContext.setState({
          ...state,
          alphaIndexes: results,
        })
      }),
    )
  }

  @Action(GetAlphaIndexesViewModel)
  GetAlphaIndexesViewModel(
    alphaIndexStateContext: StateContext<SearchStateModel>,
    { selectedLanguage }: GetAlphaIndexesViewModel,
  ): any {
    const state = alphaIndexStateContext.getState()

    let taxonLists: TaxonDto[][] = []
    const thesaurusReferences: ThesaurusDto[][] = []
    let taxonReferences: TaxonDto[][] = []

    if (state.alphaIndexes) {
      const alphaIndexVM = new AlphaIndexViewModel()
      state.alphaIndexes.forEach((alphaIndex: AlphaIndexDto) => {
        taxonLists = this.setTaxonsToViewModel(
          alphaIndex,
          selectedLanguage,
          taxonLists,
        )
        //There is always just one alpha index
        taxonReferences = this.setTaxonsToViewModel(
          alphaIndex?.alphaIndexReferences[0],
          selectedLanguage,
          taxonReferences,
        )
        thesaurusReferences.push(alphaIndex.thesaursusReferences)
      })

      alphaIndexVM.alphaIndexTaxonsArray = taxonLists
      alphaIndexVM.thesaurusReferences = thesaurusReferences
      alphaIndexVM.alphaIndexReferences = taxonReferences
      alphaIndexStateContext.setState({
        ...state,
        alphaIndexViewModel: alphaIndexVM,
      })
    }
  }

  @Action(SearchAlphaIndex)
  searchAlpha(
    resultsStateContext: StateContext<SearchStateModel>,
    { alphaIndexId, taxonId, page, size }: SearchAlphaIndex,
  ): Observable<Result> {
    const state = resultsStateContext.getState()
    resultsStateContext.setState({
      ...state,
      isLoading: true,
    })
    this.highlightFacade.getTaxon(taxonId)
    return this.service
      .searchAlphaIndex(alphaIndexId, taxonId, page, size)
      .pipe(
        withLatestFrom(this.highlightFacade.taxon$),
        map(([results, taxon]) => {
          const resultsCopy: Result = results
          resultsCopy.id = this.createAlphaResultId(alphaIndexId, taxonId)
          this.resultFacade.setAlphaResults(
            resultsCopy,
            alphaIndexId,
            taxonId,
            page,
            size,
            SearchTypes.ALPHA,
          )
          this.highlightFacade.setQueryWords([taxon.word], SearchTypes.ALPHA)
          this.resultFacade.verifyBookmarkResult(resultsCopy)
          resultsStateContext.setState({
            ...state,
            isLoading: false,
          })
          return resultsCopy
        }),
        catchError((err) => {
          resultsStateContext.setState({
            ...state,
            isLoading: false,
          })
          throw err
        }),
      )
  }

  @Action(SearchPrecisLinkedToArticle)
  searchPrecisLinkedToArticle(
    resultsStateContext: StateContext<SearchStateModel>,
    { articleId, page, size }: SearchPrecisLinkedToArticle,
  ): Observable<Result> {
    const state = resultsStateContext.getState()
    resultsStateContext.setState({
      ...state,
      isLoading: true,
    })
    return this.service.searchPrecisLinkedToArticle(articleId).pipe(
      map((results) => {
        const resultsCopy: Result = results
        resultsCopy.id = this.createArticleResultId(articleId)
        this.resultFacade.verifyBookmarkResult(resultsCopy)
        this.resultFacade.setArticleResults(
          resultsCopy,
          articleId,
          page,
          size,
          SearchTypes.ARTICLE,
        )
        resultsStateContext.setState({
          ...state,
          isLoading: false,
        })
        return resultsCopy
      }),
      catchError((err) => {
        resultsStateContext.setState({
          ...state,
          isLoading: false,
        })
        throw err
      }),
    )
  }

  @Action(AdvancedSearch)
  advancedSearch(
    resultsStateContext: StateContext<SearchStateModel>,
    { searchModel }: AdvancedSearch,
  ): Observable<Result> {
    const state = resultsStateContext.getState()
    resultsStateContext.setState({
      ...state,
      isLoading: true,
    })
    return this.service.advancedSearch(searchModel).pipe(
      map((results) => {
        const resultsCopy: Result = results
        resultsCopy.id = this.createAdvancedResultId(searchModel)
        this.resultFacade.setAdvancedResults(
          resultsCopy,
          searchModel,
          SearchTypes.ADVANCED,
        )
        this.resultFacade.verifyBookmarkResult(resultsCopy)
        this.resultFacade.loadResultsTree(resultsCopy.tree)
        let queryWords: string[] = []
        const properties = [
          'ThesaurusIndexNumber',
          'AlphaIndexText',
          'DecisionNumber',
          'EndDate',
          'StartDate',
          'ThesaurusText',
          'Title',
        ]
        const noBooleanText =
          this.highlighAdvancedSearchService.booleanTextSearch(searchModel.Text)

        if (searchModel.Text !== '') {
          queryWords =
            this.highlighAdvancedSearchService.highlightTextAdvancedSearch(
              noBooleanText,
              searchModel.Text,
              queryWords,
            )
        }

        properties.forEach((prop) => {
          if (
            searchModel[prop as keyof SearchModel] !== '' &&
            typeof searchModel[prop as keyof SearchModel] === 'string'
          ) {
            const property = searchModel[prop as keyof SearchModel]
            if (typeof property === 'string') {
              queryWords.push(property)
            }
          }
        })

        let filteredQueryWords: string[] = queryWords.filter(
          (queryWord) => queryWord !== '',
        )
        filteredQueryWords = filteredQueryWords.map((queryWord) => {
          if (queryWord.includes('^')) {
            queryWord = queryWord.substring(1)
          }
          return queryWord
        })

        this.highlightFacade.setQueryWords(
          filteredQueryWords,
          SearchTypes.ADVANCED,
        )
        resultsStateContext.setState({
          ...state,
          isLoading: false,
        })
        return resultsCopy
      }),
      catchError((err) => {
        resultsStateContext.setState({
          ...state,
          isLoading: false,
        })
        throw err
      }),
    )
  }

  getRecursiveTranslations(
    selectedLanguage: string | null,
    thesaurusTreeArray: ThesaurusTree[],
  ): ThesaurusTree[] {
    return thesaurusTreeArray.map((thesaurusTree) => {
      const copy = thesaurusTree
      let thesaurusTranslated: ThesaurusTranslation | undefined = undefined
      const thesaurusTranslations = new Map<string, ThesaurusTranslation>(
        Object.entries(thesaurusTree.thesaurusTranslations),
      )
      if (selectedLanguage) {
        if (thesaurusTranslations.get(selectedLanguage)) {
          thesaurusTranslated = thesaurusTranslations.get(selectedLanguage)
          if (thesaurusTranslated) {
            copy.selectedTranslation = thesaurusTranslated
          }
          thesaurusTree = copy
          if (thesaurusTree.children) {
            this.getRecursiveTranslations(
              selectedLanguage,
              thesaurusTree.children,
            )
          }
        }
      }

      return thesaurusTree
    })
  }

  getParents(thesaurusTree: ThesaurusTree): void {
    if (thesaurusTree?.children.length > 0) {
      thesaurusTree.children.forEach((thesaurusChild) => {
        thesaurusChild.parent = thesaurusTree
        this.getParents(thesaurusChild)
      })
    }
  }

  createAdvancedResultId(searchModel: SearchModel): string {
    let id = ''
    const searchModelCopy = SearchModel.createCopy(searchModel)
    id = searchModelCopy.toSearchString()
    return id
  }

  createAlphaResultId(alphaId: string, taxonId: string): string {
    let id = ''
    id = alphaId + '-' + taxonId
    return id
  }

  createArticleResultId(articleId: string): string {
    let id = ''
    id = articleId
    return id
  }

  setTaxonsToViewModel(
    alphaIndex: AlphaIndexDto,
    selectedLanguage: string | undefined,
    taxonLists: TaxonDto[][],
  ): TaxonDto[][] {
    let taxonList: TaxonDto[] = []
    const taxonWords = alphaIndex?.taxon?.filter(
      (taxon) => taxon?.languageCode === selectedLanguage,
    )
    taxonWords?.forEach((taxon) => {
      const taxonCopy = Object.assign({}, taxon)
      taxonCopy.alphaIndexId = alphaIndex?.id
      taxonList.push(taxonCopy)
    })
    taxonLists.push(taxonList)
    taxonList = []
    return taxonLists
  }
}
