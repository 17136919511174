export class OpenMenuForSmallScreens {
  static readonly type = '[CoreGlobal] Open Menu For Small Screens'
  constructor(public isMenuOpen: boolean) {}
}

export class OpenTreeForSmallScreens {
  static readonly type = '[CoreGlobal] Open Tree For Small Screens'
  constructor(public isTreeOpen: boolean) {}
}

export class OpenFilterDrawer {
  static readonly type = '[CoreGlobal] Open The Filters Drawer'
  constructor(public isFilterDrawerOpen: boolean) {}
}

export class OpenLoader {
  static readonly type = '[CoreGlobal] Open the loader'
  constructor(public isLoading: boolean) {}
}
