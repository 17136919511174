import { MeApiService } from '@/core/features/me/services/me-api.service'
import { UserRole } from '@/core/types/user-role.enum'
import { User } from '@/core/types/user.model'
import { Injectable, inject } from '@angular/core'
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store'
import { Observable, tap } from 'rxjs'
import { GetMe, SetAccessToken, SetIsFromUnsubscribe, SetMe } from './me.action'

export class MeStateModel {
  name: string
  accessToken: string
  me: User | null
  authorities: UserRole[]
  isFromUnsubscribe: boolean
  idUnsubscribe: string
}

export const ME_STATE_TOKEN = new StateToken<MeStateModel>('me')

@State<MeStateModel>({
  name: ME_STATE_TOKEN,
  defaults: {
    name: '',
    accessToken: '',
    me: null,
    authorities: [],
    isFromUnsubscribe: false,
    idUnsubscribe: '',
  },
})
@Injectable()
export class MeState {
  private api = inject(MeApiService)

  @Selector()
  static me(state: MeStateModel): User | null {
    return state.me
  }
  @Selector()
  static accessToken(state: MeStateModel): string {
    return state.accessToken
  }
  @Selector()
  static meName(state: MeStateModel): string {
    return state.name
  }
  @Selector()
  static isLoggedIn(state: MeStateModel): boolean {
    return state.accessToken !== ''
  }
  @Selector()
  static userRoles(state: MeStateModel): UserRole[] {
    return state.authorities
  }
  @Selector()
  static isFromUnsubscribe(state: MeStateModel): boolean {
    return state.isFromUnsubscribe
  }
  @Selector()
  static idUnsubscribe(state: MeStateModel): string {
    return state.idUnsubscribe
  }

  @Action(SetAccessToken)
  setAccessToken(
    ctx: StateContext<MeStateModel>,
    { accessToken }: MeStateModel,
  ): void {
    const state = ctx.getState()

    ctx.patchState({
      ...state,
      accessToken,
      authorities: accessToken === '' ? [] : state.authorities,
      me: accessToken === '' ? null : state.me,
    })
  }

  @Action(GetMe)
  getMe(ctx: StateContext<MeStateModel>): Observable<User> {
    return this.api.getAccount().pipe(
      tap((account) => {
        const state = ctx.getState()
        ctx.patchState({
          ...state,
          me: account,
          authorities: account.authorities,
        })
      }),
    )
  }

  @Action(SetMe)
  setMe(ctx: StateContext<MeStateModel>, { user }: SetMe): void {
    const state = ctx.getState()
    ctx.patchState({
      ...state,
      me: user,
    })
  }

  @Action(SetIsFromUnsubscribe)
  setIsFromUnsubscribe(
    ctx: StateContext<MeStateModel>,
    { isFromUnsubscribe, idUnsubscribe }: SetIsFromUnsubscribe,
  ): void {
    const state = ctx.getState()
    ctx.patchState({
      ...state,
      isFromUnsubscribe,
      idUnsubscribe,
    })
  }
}
