import { environment } from '@/codices/env/environment'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as klaro from 'klaro'
import { take } from 'rxjs'
import { klaroConfig } from './klaro-config'

@Injectable()
export class ConsentService {
  config: any
  constructor(private translate: TranslateService) {}
  initialize(): any {
    this.translate
      .get(['consent.description', 'consent.ok', 'consent.link'])
      .pipe(take(1))
      .subscribe((str) => {
        this.config = {
          ...klaroConfig,
          translations: {
            en: {
              consentNotice: {
                description: `<span>${str['consent.description']}
                <a href="${environment.cookiesDisclaimerUrl}">
                ${str['consent.link']}</a><span>`,
              },
              ok: str['consent.ok'],
            },
          },
        }
        klaro.setup(this.config)
      })
  }
}
