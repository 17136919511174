import {
  CountryFilter,
  Result,
  SearchResult,
  ThesaurusFilter,
} from '@/codices/app/data/models/result.model'
import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { DocType } from '@/core/types/doc-type.enum'
import { SearchModel } from '@/core/types/search.model'

export class SetAdvancedResults {
  static readonly type = '[Results] Set advanced search results '
  constructor(
    public results: Result,
    public searchModel: SearchModel,
    public searchType: SearchTypes,
  ) {}
}

export class SetAlphaResults {
  static readonly type = '[Results] Set alpha index search results '
  constructor(
    public results: Result,
    public alphaId: string,
    public taxonId: string,
    public page: number,
    public size: number,
    public searchType: SearchTypes,
  ) {}
}

export class SetArticleResults {
  static readonly type = '[Results] Set alpha index search results '
  constructor(
    public results: Result,
    public articleId: string,
    public page: number,
    public size: number,
    public searchType: SearchTypes,
  ) {}
}

export class SetCurrentDisplayedResultType {
  static readonly type = '[Results] Set current diplayed result type'
  constructor(public resultType: DocType | undefined) {}
}

export class ResetCurrentDisplayedResultType {
  static readonly type = '[Results] Reset current diplayed result type'
}

export class BookmarkResults {
  static readonly type = '[Results] Bookmark Results'
  constructor(
    public id: string,
    public results: Result,
    public name: string,
    public isBookmarked: boolean,
  ) {}
}

export class VerifyBookmarkResult {
  static readonly type = '[Results] Verify a result is bookmarked'
  constructor(public results: Result) {}
}

export class CheckResultCountry {
  static readonly type = '[Results] Check Or Uncheck A Country '
  constructor(public countryFilter: CountryFilter) {}
}

export class CheckThesaurus {
  static readonly type = '[Results] Check Or Uncheck A Thesaurus'
  constructor(public thesaurusFilter: ThesaurusFilter) {}
}

export class GoNextDocument {
  static readonly type = '[Results] Go to next document'
}

export class GoPreviousDocument {
  static readonly type = '[Results] Go to previous document'
}

export class SetCurrentDisplayedResult {
  static readonly type = '[Results] Set current displayed result'
  constructor(public id: string) {}
}

export class NavigateToResult {
  static readonly type = '[Results] Navigate to result'
  constructor(public searchResult: SearchResult) {}
}

export class NavigateToResultInTree {
  static readonly type = '[Results] Navigate to result in tree'
  constructor(public resultType: DocType) {}
}

export class ResetIntent {
  static readonly type = '[Results] Reset Intent'
}

export class ReturnToResultList {
  static readonly type = '[Results] Return to result list'
}
