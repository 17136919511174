import { environment } from '@/core/environment'
import { EntitiesStateModelWithPage } from '@/core/types/entities-state-model'
import { ListResult } from '@/core/types/list-result.model'
import { User } from '@/core/types/user.model'
import { Injectable, inject } from '@angular/core'
import { Action, State, StateContext, StateToken } from '@ngxs/store'
import { Observable, catchError, of, tap } from 'rxjs'
import { EntitiesStateWithPage } from '../../shared/state/entities.state'
import { UsersApiService } from '../services/users-api.service'
import { UsersChangePage, UsersFetchAll, UsersGetAll } from './users.action'

export type UsersStateModel = EntitiesStateModelWithPage<User>

export const USERS_STATE_TOKEN = new StateToken<UsersStateModel>('users')

@State<UsersStateModel>({
  name: USERS_STATE_TOKEN,
  defaults: {
    entities: [],
    totalCount: 0,
    isLoading: false,
    page: {
      index: environment.entity.defaultPageIndex,
      size: environment.entity.defaultPageSize,
    },
  },
})
@Injectable()
export class UsersState extends EntitiesStateWithPage {
  private api = inject(UsersApiService)

  @Action(UsersFetchAll, { cancelUncompleted: true })
  fetchAll(ctx: StateContext<UsersStateModel>): Observable<ListResult<User>> {
    ctx.patchState({
      isLoading: true,
    })

    const { page } = ctx.getState()

    return this.api.get({ page: page.index, size: page.size }).pipe(
      tap((res) => {
        ctx.patchState({
          entities: res.listResult,
          totalCount: res.listResult.length,
          isLoading: false,
        })
      }),
      catchError(() => {
        ctx.patchState({
          isLoading: false,
        })
        return []
      }),
    )
  }

  @Action(UsersChangePage)
  changePage(
    ctx: StateContext<UsersStateModel>,
    { page }: UsersChangePage,
  ): void {
    const { page: currPage } = ctx.getState()

    ctx.patchState({
      page: {
        ...currPage,
        ...page,
      },
    })

    ctx.dispatch(new UsersFetchAll())
  }

  @Action(UsersGetAll, { cancelUncompleted: true })
  getCountries(
    ctx: StateContext<UsersStateModel>,
    { revalidate }: UsersGetAll,
  ): Observable<void> {
    const { entities } = ctx.getState()

    if (!revalidate && entities.length > 0) {
      // Cache is not empty, no need to fetch
      return of()
    }

    return ctx.dispatch(new UsersFetchAll())
  }
}
