import { Lang } from '@/core/types/lang'
import { ThesaurusTranslation } from '@/core/types/thesaurus-translation'
import { Thesaurus, ThesaurusWithFullname } from '@/core/types/thesaurus.model'
import { AbstractControl } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'

export function truncateString(value: string): string {
  return value.length > 10 ? `${value.substring(0, 10)}...` : value
}

export function getThesaurusFullName(
  thesaurus: ThesaurusWithFullname,
  lang: Lang,
): string {
  return thesaurus.thesaurusTranslations[lang].fullName
}

export function getThesaurusName(thesaurus: Thesaurus, lang: Lang): string {
  return `${thesaurus.indexNumber} ${thesaurus.thesaurusTranslations[lang].text}`
}

export function createThesaurus(
  thesaurus: Thesaurus,
  thesaurusStructureFormControl: { [key: string]: AbstractControl },
): Thesaurus {
  const newThesaurus = {} as Thesaurus
  const newThesaurusTranslations: Record<string, ThesaurusTranslation> = {}
  const languages = ['fra', 'eng', 'spa', 'por']
  languages.forEach((language) => {
    newThesaurusTranslations[language] = setThesaurusTranslations(
      language,
      thesaurusStructureFormControl,
    )
  })
  newThesaurus.thesaurusTranslations = newThesaurusTranslations
  newThesaurus.level = thesaurus.level + 1
  newThesaurus.isNewTreeNode = true
  newThesaurus.index = thesaurus.children.length + 1
  newThesaurus.tempId = crypto.randomUUID()
  if (thesaurus.id) {
    newThesaurus.parentId = thesaurus.id
  }
  newThesaurus.children = []
  return newThesaurus
}

export function setThesaurusTranslations(
  thesaurusLanguage: string,
  thesaurusFormControl: { [key: string]: AbstractControl },
): ThesaurusTranslation {
  const translation = {
    languageCode: thesaurusLanguage,
    text: !!!thesaurusFormControl[`${thesaurusLanguage}Text`].value
      ? ''
      : thesaurusFormControl[`${thesaurusLanguage}Text`].value,
    note: thesaurusFormControl[`${thesaurusLanguage}Note`].value,
  } as ThesaurusTranslation
  return translation
}

export function orderThesaurusTree(thesauruses: Thesaurus[]): Thesaurus[] {
  let orderedThesauruses: Thesaurus[] = []
  const order = new BehaviorSubject<number>(0)
  orderedThesauruses = travelIntoThesaurusTree(thesauruses, [], order)
  return orderedThesauruses
}

export function editThesaurus(
  lang: string,
  thesaurusRecord: Record<string, ThesaurusTranslation>,
  thesaurusControl: { [key: string]: AbstractControl },
): void {
  if (!!!thesaurusRecord[lang]) {
    thesaurusRecord[lang] = {} as ThesaurusTranslation
    thesaurusRecord[lang].languageCode = lang
  }
  thesaurusRecord[lang].text = thesaurusControl?.[`${lang}Text`]
    ?.value as string
  thesaurusRecord[lang].note = thesaurusControl?.[`${lang}Note`]
    ?.value as string
}

function travelIntoThesaurusTree(
  thesauruses: Thesaurus[],
  parentIndexes: number[] = [],
  order: BehaviorSubject<number>,
): Thesaurus[] {
  thesauruses.forEach((thesaurus, index) => {
    const currentValue = order.getValue()
    const newValue = currentValue + 1
    thesaurus.parentIndexes = [...parentIndexes, index + 1]
    thesaurus.indexNumber = thesaurus.parentIndexes.join('.')
    thesaurus.index = newValue
    order.next(newValue)
    travelIntoThesaurusTree(thesaurus.children, thesaurus.parentIndexes, order)
  })
  return thesauruses
}
