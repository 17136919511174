export const environment = {
  production: false,
  name: 'preprod',
  appName: 'codices',
  mockoonUrl:
    (window as { [key: string]: any })['env']['MOCKOON_URL'] ||
    'http://localhost:3000/codices',
  apiUrl:
    (window as { [key: string]: any })['env']['API_URL'] ||
    'http://localhost:3000/codices',
  cookiesDisclaimerUrl: 'https://www.coe.int/web/portal/cookies-disclaimer',
  MIN1_SIZE: 10,
  MIN2_SIZE: 25,
  MID_SIZE: 50,
  MAX_SIZE: 100,
  FULLTEXT_DATE: '01/01/2022',
  precis: {
    proofreadingDelay: 21, // days
  },
  navBar: {
    WCCJ_UR: 'https://www.venice.coe.int/WebForms/pages/?p=02_WCCJ',
    BULLETINS_URL:
      'https://www.venice.coe.int/WebForms/pages/?p=02_02_Bulletins',
    VENICE_URL: 'www.Venice.Coe.int',
  },
  helpUrlFra: 'https://www.venice.coe.int/CODICES/CODICES_Help-FR.PDF',
  helpUrlEng: 'https://www.venice.coe.int/CODICES/CODICES_Help.PDF',
  disconnectCasUrl: 'https://cas.coe.int/cas/logout',
}
