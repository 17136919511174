export {}

declare global {
  interface String {
    changeToDateStringFormat(): string
    returnToOriginalDateStringFormat(): string
  }
}

String.prototype.changeToDateStringFormat = function (): string {
  return this.replace(/\//g, '.')
}

String.prototype.returnToOriginalDateStringFormat = function (): string {
  return this.replace(/\./g, '/')
}
